import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { Alert, Autocomplete, Box, Snackbar, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall } from "../../Api/UseFeitch";
import { useEffect } from "react";
// import "../globle.css"

const BusinessReview = ({ data, setData, setGroupPerentstep, setShowmenu }) => {
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [btnNameSubmit, setBtnNameSubmit] = useState("SUBMIT");

    const [review_outcome, setReview_outcome] = useState([])

    const [bodyData, setBodyData] = useState({
        reviewNotes: "",
    });

    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value })
    }
    const handleselect = (event, review_outcome) => {
        setReview_outcome(review_outcome);
    }
    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)

    }
    function alertFunc() {
        setGroupPerentstep(0);
        setShowmenu(1)
        navigate("/panel2/" + "?token=0&step=0&substep=0");
    }
    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);
    async function handleclick(e) {

        e.preventDefault();
        setBtnNameSubmit( <Spinner animation="border" variant="danger" style={{height:"22px",width:"22px"}}/>);

        let resultt = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "NonIGA",
                    nonIGA: {
                        "passport": "31348675222",
                        "gender": "M",
                        "DOB": "10/10/2020",
                        "nationality": "PAK",
                        ...bodyData, reviewOutcome: review_outcome
                    }
                },
                consentMode: ""
            }
        })
        // console.log("resultt-----------", resultt)
        if (resultt.messageStatus == "OK") {
            setOpen2(true);
            setMessage(resultt.errorDescription)
            timeout = setTimeout(alertFunc, 1000);
            setBtnNameSubmit("SUBMIT");
        } else {
            setOpen(true);
            setMessage(resultt.errorDescription);
            setBtnNameSubmit("SUBMIT");

        }

    }



    const top100Films = [
        { title: 'Option 1', year: 1994 },
        { title: 'Option 2', year: 1972 },
        { title: 'Option 3', year: 1974 },
        { title: 'Option 4', year: 2008 }

    ];

    const thdata = [
        {
            id: "1",
            thname: "Task Name  "
        },
        {
            id: "2",
            thname: "Stage"
        },
        {
            id: "3",
            thname: "Activity Name"
        },
        {
            id: "4",
            thname: "Case Name "
        },
        {
            id: "5",
            thname: "Assigned Team"
        },
        {
            id: "6",
            thname: "Assigned User"
        },
        {
            id: "7",
            thname: " Due "
        },
        {
            id: "8",
            thname: "Task Duration"
        },
        {
            id: "9",
            thname: "Status"
        },
        {
            id: "10",
            thname: " Last Updated By"
        },

    ]

    const tddata = [
        {
            id: "1",
            tdname: "Business Review And Sign-Off"
        },
        {
            id: "2",
            tdname: "Review And Aprovel"
        },
        {
            id: "3",
            tdname: "Managment Reviw And Approvel"
        },
        {
            id: "4",
            tdname: "Cleint Onboarding"
        },
        {
            id: "5",
            tdname: "Onboarding"
        },
        {
            id: "6",
            tdname: "Ahmad Al Mdani"
        },
        {
            id: "7",
            tdname: "02/17/2020"
        },
        {
            id: "8",
            tdname: "1 min"
        },
        {
            id: "8",
            tdname: "In Progress"
        },
        {
            id: "9",
            tdname: "System users"
        },

    ]
    const selectstyle = {
        backgroundColor: 'white',
        borderRadius: '0px'
    };






    const fieldsLists = [
        {
            title: "Reviewer ",
            value: "Benifitcloud\Rafidain0096504589",
        },
        {
            title: "Review Date ",
            value: "02/17/2020",
        }, {
            title: <p>Review Outcome <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box >
                <Autocomplete
                    name="reviewOutcome"
                    onChange={handleselect}
                    style={selectstyle}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={top100Films}
                    getOptionLabel={(option) => option.title}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small" {...params} placeholder="Select" />
                    )}
                />
            </Box>,
        }, {
            title: "Review Notes",
            value: <Box >
                <Form.Control style={{ borderRadius: "0px" }} onChange={handlechange} name="reviewNotes" value={bodyData.reviewNotes} as="textarea" rows={3} />
            </Box>
            ,
        }
    ];






    return (
        <>
            <form onSubmit={handleclick}>
                <Container fluid id="scroleble_main_content">


                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>



                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open2} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-success text-light" severity="success" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>



                    <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="h3 mt-4" as="div" flex='1' textAlign='left'>
                                    Business Review And Sign-Off
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>
                             
                            <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Manager Review
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>
                                {
                                    fieldsLists.map((field) =>
                                        <Row className="mt-3">
                                            <Col sm={3} xs={12} className="text-md-end text-start text-danger">{field?.title} </Col>
                                            <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                                {field?.value}
                                            </Col>
                                            <Col xs={0} sm={2} xl={3}></Col>
                                        </Row>
                                    )
                                }




                                <Row className=" mt-5  mb-5">
                                    <Col md={4} lg={5} xl={6} xs={0} className=""></Col>
                                    <Col md={8} lg={7} xl={6} xs={12} className=" ">
                                        <Container>
                                            <Row>
                                                <Col md={3}></Col>
                                                <Col md={5} sm={6} className=" text-end">
                                                    <Button className=" ps-4 pe-4 btn-secondary buttonstyle" >SAVE FOR LATER</Button>
                                                </Col>
                                                <Col md={4} sm={6} className=" text-end mt-sm-0 mt-2">  <Button
                                                    type="submit"
                                                    className="ps-4 pe-4 btn-secondary buttonstyle">
                                                    { btnNameSubmit  }
                                                </Button></Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>


                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                    <Accordion className="mt-5 mb-" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Tasks
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>

                                <Table responsive className="mt-2 table table-striped">
                                    <thead>
                                        <tr>
                                            {
                                                thdata.map((th) => {
                                                    return (
                                                        <>
                                                            <th className="Fontsize13">{th.thname}</th>

                                                        </>
                                                    )
                                                })
                                            }

                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            {
                                                tddata.map((td) => {
                                                    return (
                                                        <>
                                                            <td className="Fontsize13">{td.tdname}</td>

                                                        </>
                                                    )
                                                })
                                            }
                                        </tr>

                                    </tbody>
                                </Table>

                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>




                   



                </Container>
            </form>
        </>
    )
}

export default BusinessReview;