import React from "react";
import EnterEntityDetail from "./EnterEntityDetail";
import SearchForDuplicates from "./SearchForDuplicates";
import Complete from "./Complete";
import Header from "../HeaderComponent/Header";

const NewRequestMap = ({adminData, setAdminData, data, setData, newRequestStep, setNewRequestStep}) => {

    return (
        <>
            { <EnterEntityDetail
                setAdminData={setAdminData} adminData={adminData}
                data={data} setData={setData}
                newRequestStep={newRequestStep} setNewRequestStep={setNewRequestStep}
            />}
            {newRequestStep === 1 && <SearchForDuplicates
                setAdminData={setAdminData} adminData={adminData}
                data={data} setData={setData}
                newRequestStep={newRequestStep} setNewRequestStep={setNewRequestStep}
            />}
            {newRequestStep === 2 && <Complete
                setAdminData={setAdminData} adminData={adminData}
                data={data} setData={setData}
                newRequestStep={newRequestStep} setNewRequestStep={setNewRequestStep}
            />}
        </>
    )
}
export default NewRequestMap;