import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { BsPencil } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall } from "../../Api/UseFeitch";
import { Alert, Autocomplete, Box, Snackbar, TextField } from "@mui/material";
import { useEffect } from "react";

const CompleteRiskAssessments = ({ data, setData, setGroupPerentstep, setShowmenu }) => {
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [btnNameSubmit, setBtnNameSubmit] = useState("SAVE AND COMPLETE");

    const [bodyData, setBodyData] = useState({
        notes1: "",
        notes2: "",
        notes3: "",

    });
    const [mandatorydata, setMandatory] = useState({

    })



    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value })

        // console.log("-------------", bodyData)

    }
    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)

    }
    function alertFunc() {
        setGroupPerentstep(6);
        navigate("/panel2/2" + "?token=8&step=6&substep=0");
        setShowmenu(1)
    }
    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);
    async function handleclick(e) {

        e.preventDefault();
        setBtnNameSubmit(<Spinner animation="border" variant="danger" style={{ height: "22px", width: "22px" }} />);

        let resultt = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "NonIGA",
                    nonIGA: {
                        "passport": "31348675222",
                        "gender": "M",
                        "DOB": "10/10/2020",
                        "nationality": "PAK",
                        ...bodyData
                    }
                },
                consentMode: ""
            }
        })

        // console.log("resultt-----------", resultt)
        if (resultt.messageStatus == "OK") {
            setOpen2(true);
            setMessage(resultt.errorDescription)
            timeout = setTimeout(alertFunc, 1000);
            setBtnNameSubmit("SAVE AND COMPLETE");

        } else {
            setOpen(true);
            setMessage(resultt.errorDescription);
            setBtnNameSubmit("SAVE AND COMPLETE");

        }


    }

    const clickEdit = () => {
        alert("yes ! clicked");

    }

    return (
        <>
            <form onSubmit={handleclick}>
                <Container fluid id="scroleble_main_content">

                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>



                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open2} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-success text-light" severity="success" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>


                    <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="h3 mt-4" as="div" flex='1' textAlign='left'>
                                        Complete Risk Assessments
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>
                                <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Legal Entity Details
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>


                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Country Risk
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>
                                            <Row className="mt-3">
                                                <Col xs={12} sm={4} className="text-sm-end ">Nationality: </Col>
                                                <Col xs={12} sm={8} className="fw-bold ">
                                                    {data?.nonIGAFields?.primary?.nationality || "-"}
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col xs={12} sm={4} className="text-sm-end text-danger">Risk Category:
                                                </Col>
                                                <Col xs={6} sm={2} className="fw-bold ">
                                                    Low
                                                </Col>
                                                <Col xs={6}><BsPencil onClick={clickEdit} style={{ cursor: "pointer" }} size={20} /></Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col xs={12} sm={4} className="text-sm-end text-danger" >Residential Address: </Col>
                                                <Col xs={12} sm={8} className="fw-bold ">
                                                    Bahrain
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col xs={12} sm={4} className="text-sm-end text-danger">Risk Category:
                                                </Col>
                                                <Col xs={6} sm={2} className="fw-bold ">
                                                    Low
                                                </Col>
                                                <Col xs={6}><BsPencil onClick={clickEdit} style={{ cursor: "pointer" }} size={20} /></Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Notes</Col>
                                                <Col sm={8} xl={5} xs={12} className="fw-bold ">
                                                    <Box >
                                                        <Form.Control style={{ borderRadius: "0px" }} onChange={handlechange} name="notes1" value={bodyData.notes1} as="textarea" rows={3} />
                                                    </Box>
                                                </Col>
                                                <Col xs={0} sm={2} xl={3}></Col>
                                            </Row>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Entity Risk
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>
                                            <Row className="mt-3">
                                                <Col xs={12} sm={4} className="text-sm-end text-danger">Legal Entity Type: </Col>
                                                <Col xs={12} sm={8} className="fw-bold ">
                                                    Individual
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col xs={12} sm={4} className="text-sm-end text-danger"> Risk Category:
                                                </Col>
                                                <Col xs={6} sm={2} className="fw-bold ">
                                                    Low
                                                </Col>
                                                <Col xs={6}><BsPencil onClick={clickEdit} style={{ cursor: "pointer" }} size={20} /></Col>
                                            </Row>

                                            <Row className="mt-3">
                                                <Col xs={12} sm={4} className="text-sm-end text-danger">ALM Assessments Risk: </Col>
                                                <Col xs={12} sm={8} className="fw-bold ">
                                                    Low
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col xs={12} sm={4} className="text-sm-end text-danger"> Risk Category:
                                                </Col>
                                                <Col xs={6} sm={2} className="fw-bold ">
                                                    Low
                                                </Col>
                                                <Col xs={6}><BsPencil onClick={clickEdit} style={{ cursor: "pointer" }} size={20} /></Col>
                                            </Row>


                                            <Row className="mt-3">
                                                <Col xs={12} sm={4} className="text-sm-end text-danger">Length Of Relationship: </Col>
                                                <Col xs={12} sm={8} className="fw-bold ">
                                                    0 Years 0 Month 0 Day
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col xs={12} sm={4} className="text-sm-end text-danger"> Risk Category:
                                                </Col>
                                                <Col xs={6} sm={2} className="fw-bold ">
                                                    High
                                                </Col>
                                                <Col xs={6}><BsPencil onClick={clickEdit} style={{ cursor: "pointer" }} size={20} /></Col>
                                            </Row>

                                            <Row className="mt-3">
                                                <Col sm={4} xs={12} className="text-sm-end text-start text-danger">PEPs/Sanctions: </Col>
                                                <Col sm={8} xs={12} className="fw-bold ">
                                                    No.
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col sm={4} xs={12} className="text-sm-end text-start text-danger"> Risk Category:
                                                </Col>
                                                <Col sm={8} xs={12} className=" ">
                                                    -
                                                </Col>
                                            </Row>

                                            <Row className="mt-3">
                                                <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Notes</Col>
                                                <Col sm={8} xl={5} xs={12} className="fw-bold ">
                                                    <Box >
                                                        <Form.Control style={{ borderRadius: "0px" }} onChange={handlechange} name="notes2" value={bodyData.notes2} as="textarea" rows={3} />
                                                    </Box>
                                                </Col>
                                                <Col xs={0} sm={2} xl={3}></Col>
                                            </Row>

                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Country Risk
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>
                                            <Row className="mt-3">
                                                <Col sm={4} xs={12} className="text-sm-end text-start text-danger"> Risk Category:
                                                </Col>
                                                <Col sm={8} xs={12} className=" ">
                                                    -
                                                </Col>
                                            </Row>


                                            <Row className="mt-3">
                                                <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Notes</Col>
                                                <Col sm={8} xl={5} xs={12} className="fw-bold ">
                                                    <Box >
                                                        <Form.Control style={{ borderRadius: "0px" }} onChange={handlechange} name="notes3" value={bodyData.notes3} as="textarea" rows={3} />
                                                    </Box>
                                                </Col>
                                                <Col xs={0} sm={2} xl={3}></Col>
                                            </Row>

                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                                <Row className=" mt-5  mb-5">
                                    <Col md={4} lg={5} xl={6} xs={0} className=""></Col>
                                    <Col md={8} lg={7} xl={6} xs={12} className=" ">
                                        <Container>
                                            <Row>
                                                <Col md={1}></Col>
                                                <Col md={5} sm={6} className=" text-end">
                                                    <Button className="ps-4 pe-4 btn-secondary buttonstyle ">SAVE FOR LATER</Button>
                                                </Col>
                                                <Col md={6} sm={6} className=" text-end mt-sm-0 mt-2"> <Button
                                                    type="submit"
                                                    className="ps-4 pe-4 btn-secondary buttonstyle">
                                                    {btnNameSubmit}
                                                </Button></Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>

                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>






                </Container>
            </form>
        </>
    )
}
export default CompleteRiskAssessments;