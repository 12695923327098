import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap"
import { Alert, Autocomplete, Box, Snackbar, TextField } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall, LogTime, getCountryCodeByGccId } from "../../Api/UseFeitch";
import { getSalaryInput, inputStyle } from "../helpingFunction/helpingInputData";

const CaptureEmploymentDetails = ({ setBodyEmploymentDetails }) => {
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [getCurrentData, setGetCurrentData] = useState({});
    const [cardCountry, setCardCountry] = useState("BHR");
    const [searchParams] = useSearchParams();
    const [gccId, setGccId] = useState(searchParams.get("gccid") || "");
    const [bodyDataEmploymentDetails, setBodyDataEmploymentDetails] = useState({ });





    async function fetchMyAPI(gccIdTemp) {
        let cardCountryTemp = await getCountryCodeByGccId(gccIdTemp);
        if (!cardCountryTemp) {
            return;
        }
        setCardCountry(cardCountryTemp);
        setGccId(gccIdTemp);
        let getdata = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "GCC",
                    GCC: {
                        "cardCountry": cardCountryTemp || "BHR",
                        "nationalID": gccIdTemp || "",
                    }
                },
            },
        })
        setGetCurrentData(getdata);
        if (getdata) {
            setBodyDataEmploymentDetails({
                occupation:getdata?.nonIGAFields?.overridden?.occupation || "",
                natureOfBusinessActivity:getdata?.nonIGAFields?.additional?.natureOfBusinessActivity ||"",
                employmentStatus:"",
                workPermitValid:"",
                salary:"",
                sourceOfFunds:""
            })
        }

    }

    useEffect((e) => {
        if (searchParams.get("gccid")) fetchMyAPI(searchParams.get("gccid"))
        if (searchParams.get("gccId")) fetchMyAPI(searchParams.get("gccId"))
    }, [searchParams.get("gccid"), searchParams.get("gccId")])
   



    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);

    

    const handlechangeEmploymentDetails = (e) => {
        const { name, value } = e.target;
        setBodyDataEmploymentDetails({ ...bodyDataEmploymentDetails, [name]: value })
    }


    const EmploymentstatusEmploymentDetails = (event, employmentStatus) => {
        setBodyDataEmploymentDetails({ ...bodyDataEmploymentDetails, employmentStatus: employmentStatus.lable });
    }

    const WorkPermitVailidEmploymentDetails = (event, workPermitVailid) => {
        setBodyDataEmploymentDetails({ ...bodyDataEmploymentDetails, workPermitValid: workPermitVailid.value });



    }

    const MonthlyIncomeEmploymentDetails = (event, salary) => {
        setBodyDataEmploymentDetails({ ...bodyDataEmploymentDetails, salary: salary.lable });

    }
    const SourseoffoundsEmploymentDetails = (event, sourse_of_founds) => {
        setBodyDataEmploymentDetails({ ...bodyDataEmploymentDetails, sourceOfFunds: sourse_of_founds.lable });
    }

    setBodyEmploymentDetails(bodyDataEmploymentDetails);


    

    const SaleryEmploymentDetails = getSalaryInput();
    const EmploymentStatusEmploymentDetails = [
        { lable: 'LESS THAN 15 YEARS', id: 1 },
        { lable: 'WORKING', id: 2 },
        { lable: 'HOME-MAKER', id: 2 },
        { lable: 'SELF EMPLOYED', id: 2 },
        { lable: 'STUDENT', id: 2 },
        { lable: 'UNENMPLOYED & WORKED BEFORE', id: 2 },
        { lable: 'UNEMPLOYED&NEVER WORKED BEFORE', id: 2 },
        { lable: 'UNABLE TO WORK', id: 2 },
        { lable: 'SELF RECEIPTIAN', id: 2 },
        { lable: 'RETIRED', id: 2 },
        { lable: 'NOT WORKING', id: 2 },


    ];
    const SourseOfFoundsemploymentDetails = [
        { lable: 'Description', id: 1 },
        { lable: 'Salary', id: 2 },
        { lable: 'Inheritance', id: 3 },
        { lable: 'Business Ownership', id: 4 },
        { lable: 'Investments', id: 5 },
        { lable: 'Others', id: 6 },
    ];
    const yesNoEmploymentDetails = [
        { lable: 'Yes', value: 'Y', id: 1 },
        { lable: 'No',  value: 'N', id: 2 },
    ];
    const fieldsListsEmploymentDetails = [
        {
            title: "Employment Status",
            value: <Box>
                <Autocomplete
                    name="employmentStatus"
                    onChange={EmploymentstatusEmploymentDetails}
                    size="small"
                    id="tags-outlined"
                    disableClearable={true}
                    options={EmploymentStatusEmploymentDetails}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Employment Status" />
                    )}
                />
            </Box>,
        }, {
            title: "Employment Status (IGA)",
            value: getCurrentData?.IGAFields?.employmentStatus || "-" ,
        }, {
            title: "Work Permit Vailid ?",
            value: <Box>
                <Autocomplete
                    name="workPermitValid"
                    onChange={WorkPermitVailidEmploymentDetails}
                    size="small"
                    id="tags-outlined"
                    disableClearable={true}
                    options={yesNoEmploymentDetails}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Work Permit Vailid ?" />
                    )}
                />
            </Box>
            ,
        }
        , {
            title: "Resident Permit No. ",
            value: getCurrentData?.IGAFields?.residentPermitNo || "-",
        }, {
            title: "Resident Expiry Date",
            value: getCurrentData?.IGAFields?.residentPermitExpiry || "-",
        }, {
            title: "Occupation",
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Occupation"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="occupation"
                    value={bodyDataEmploymentDetails?.occupation}
                    onChange={handlechangeEmploymentDetails}
                    type="text" />
            </Box>
            ,
        }, {
            title: "Occupation (IGA)",
            value: getCurrentData?.IGAFields?.occupation || "-"
            ,
        }, {
            title: "Name Of Employer",
            value: getCurrentData?.IGAFields?.nameOfEmployer || "-",
        }, {
            title: <p>Salary (Monthly Income) <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <Autocomplete
                    name="salary"
                    onChange={MonthlyIncomeEmploymentDetails}
                    disableClearable={true}
                    size="small"
                    // multiple
                    id="tags-outlined"
                    options={SaleryEmploymentDetails}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success"  {...params} label="Salary (Monthly Income)" />
                    )}
                />
            </Box>,
        }, {
            title: "Sourse Of Founds",
            value: <Box>
                <Autocomplete
                    size="small"
                    name="sourceOfFunds"
                    disableClearable={true}
                    onChange={SourseoffoundsEmploymentDetails}
                    id="tags-outlinedfhsfdhfd"
                    options={SourseOfFoundsemploymentDetails}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Sourse Of Founds" />
                    )}
                />
            </Box>,
        }, {
            title: <p>Nature Of Activity / Business <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Nature Of Activity / Business"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="natureOfBusinessActivity"
                    value={bodyDataEmploymentDetails?.natureOfBusinessActivity}
                    onChange={handlechangeEmploymentDetails}
                    type="text" />
            </Box>,
        },

    ];

    return (
        <>


            <Container fluid >
                <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <Row className="border-bottom border-dark">
                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                    Customer Details
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </Row>
                        <AccordionPanel pb={4}>
                            {
                                fieldsListsEmploymentDetails.map((field) =>
                                    <Row className="mt-3">
                                        <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                            {field?.value}
                                        </Col>
                                        <Col xs={0} sm={2} xl={3}></Col>
                                    </Row>
                                )
                            }
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

            </Container>

        </>
    )
}
export default CaptureEmploymentDetails;
