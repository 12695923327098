import { Autocomplete, Avatar, Box, InputAdornment, TextField, Snackbar, Tooltip } from "@mui/material";
import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Dropdown, InputGroup } from "react-bootstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall, LogTime, UseFeitch, getCountryCodeByGccId } from "../../Api/UseFeitch";
import {
    getBooleanInput,
    getCountryInput,
    getMaritalStatusInput, getSalaryInput, inputStyle
} from "../helpingFunction/helpingInputData";
import Documents from "./Documents";


const CaptureRequestDetails2 = ({ setBodyRequestDetails }) => {



    const navigate = useNavigate();
    const [cardCountry, setCardCountry] = useState("BHR");
    const [openError, setOpenError] = useState(false);
    const [searchParams] = useSearchParams();
    const [getCurrentData, setGetCurrentData] = useState({});
    const [gccId, setGccId] = useState(searchParams.get("gccid") || "");
    const [defaultMetrialStatus, setDefaultMetrialStatus] = useState(null);
    const [bodyDataRequestDetails, setBodyDataRequestDetails] = useState({});


    async function fetchMyAPI(gccIdTemp) {
        let cardCountryTemp = await getCountryCodeByGccId(gccIdTemp);
        if (!cardCountryTemp) {
            return;
        }
        setCardCountry(cardCountryTemp);
        setGccId(gccIdTemp);
        let getdata = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "GCC",
                    GCC: {
                        "cardCountry": cardCountryTemp || "BHR",
                        "nationalID": gccIdTemp || "720033225",
                    }
                },
            },
        })
        setGetCurrentData(getdata);
        if (getdata) {
            setBodyDataRequestDetails({
                middleName4En: getdata?.IGAFields?.primary?.middleName4En || "",
                email: getdata?.nonIGAFields?.overridden?.email || "",
                phoneNo: getdata?.nonIGAFields?.overridden?.phoneNo || "",
                mobile: getdata?.nonIGAFields?.overridden?.mobile || "",
                otherPhoneNo:"",
                USCitizen:"",
                maritalStatus:"",
                PEPId:"",
                FATCADeclarationId:"",
                CRSDeclarationId:"",
                USTaxResident:"",
                bornInUS:"",
            })
        }
    }
    useEffect((e) => {
        if (searchParams.get("gccid")) fetchMyAPI(searchParams.get("gccid"))
        if (searchParams.get("gccId")) fetchMyAPI(searchParams.get("gccId"))
    }, [searchParams.get("gccid"), searchParams.get("gccId")])



    const onChangeMeritalStatus = (event, merital_status) => {
        setBodyDataRequestDetails({ ...bodyDataRequestDetails, maritalStatus: merital_status?.value });

    };

    const PDEDuration = (event, pde_duration) => {
        setBodyDataRequestDetails({ ...bodyDataRequestDetails, PEPId: pde_duration?.value });
    };

    const FATCADeclaration = (event, fatca_declaration) => {
        setBodyDataRequestDetails({ ...bodyDataRequestDetails, FATCADeclarationId: fatca_declaration?.value });
    };

    const CRSDeclaration = (event, crs_declaration) => {
        setBodyDataRequestDetails({ ...bodyDataRequestDetails, CRSDeclarationId: crs_declaration?.value });

    };
    const AreyouUSCitizen = (event, citizen) => {
        setBodyDataRequestDetails({ ...bodyDataRequestDetails, USCitizen: citizen?.value });

    };
    const AreyouUSTaxresident = (event, resident) => {
        setBodyDataRequestDetails({ ...bodyDataRequestDetails, USTaxResident: resident?.value });

    };
    const WereyoubornTheUS = (event, were_you_born_in_the_us) => {
        setBodyDataRequestDetails({ ...bodyDataRequestDetails, bornInUS: were_you_born_in_the_us?.value });

    };
    const gdplCountryOfResidence = (event, GDPRCountryOfResidence) => {
        setBodyDataRequestDetails({ ...bodyDataRequestDetails, GDPRCountryOfResidence: GDPRCountryOfResidence?.value });


    };
    const pdplCountryOfResidence = (event, PDPLCountryOfResidence) => {
        setBodyDataRequestDetails({ ...bodyDataRequestDetails, PDPLCountryOfResidence: PDPLCountryOfResidence?.value });
    };
    const LegalBasisDataProcessing = (event, data_processing) => {
        setBodyDataRequestDetails({ ...bodyDataRequestDetails, dataProcessing: data_processing?.lable });
    };
    const InformationNoticesProvided = (event, information_notices_provided) => {
        setBodyDataRequestDetails({ ...bodyDataRequestDetails, informationNoticeProvided: information_notices_provided?.lable });
    };
    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyDataRequestDetails({ ...bodyDataRequestDetails, [name]: value });
    };
    setBodyRequestDetails(bodyDataRequestDetails);

    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        } ;
    }, [sessionStorage.getItem("tokenSessionStorage")]);
    const Country1 = getCountryInput();
    const Country2 = getCountryInput();

    const yesNo = getBooleanInput();
    const sempleoption = [
        { lable: 'Option 1', year: 1994 },
        { lable: 'Option 2', year: 1972 },
        { lable: 'Option 3', year: 1974 },
        { lable: 'Option 4', year: 2008 }
    ];

    const fieldsLists1 = [
        {
            title: "ID",
            value: getCurrentData?.IGAFields?.idNumber || getCurrentData?.nonIGAFields?.primary?.firstNameEn || "-",
        },
        {
            title: "Legal Entity Type",
            value: " Individual",
        },
        {
            title: "First Name ",
            value: getCurrentData?.IGAFields?.firstNameEn || getCurrentData?.nonIGAFields?.primary?.firstNameEn || "-",
        },
        {
            title: "Middle Name 1",
            value: getCurrentData?.IGAFields?.middleName1En || getCurrentData?.nonIGAFields?.primary?.middleName1En || "-",
        },
        {
            title: "Middle Name 2",
            value: getCurrentData?.IGAFields?.middleName2En || getCurrentData?.nonIGAFields?.primary?.middleName2En || "-",
        },
        {
            title: "Middle Name 3 ",
            value: getCurrentData?.IGAFields?.middleName3En || getCurrentData?.nonIGAFields?.primary?.middleName3En || "-",
        },
        {
            title: "Middle Name 4 ",
            value: <Box>
                {/* <Form.Control
                    className="borderGray"
                    type="text"
                    onChange={handlechange}
                    name="middleName4En"
                    value={bodyDataRequestDetails.middleName4En}
                    aria-describedby="passwordHelpBlock"  /> */}

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Middle Name 4"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    onChange={handlechange}
                    name="middleName4En"
                    value={bodyDataRequestDetails?.middleName4En}
                    type="text" />
            </Box>,
        },
        {
            title: "Last Name",
            value: getCurrentData?.IGAFields?.lastNameEn || getCurrentData?.nonIGAFields?.primary?.lastNameEn || "-",
        },
        {
            title: "CPR Number ",
            value: "-",
        },
        {
            title: "Date Of Birth ",
            value: getCurrentData?.IGAFields?.dateOfBirth || getCurrentData?.nonIGAFields?.primary?.dateOfBirth || "-",
        },
        {
            title: "Place Of Birth ",
            value: getCurrentData?.IGAFields?.placeOfBirth || getCurrentData?.nonIGAFields?.primary?.placeOfBirth || "-",
        },
        {
            title: "Gender ",
            value: getCurrentData?.IGAFields?.gender || getCurrentData?.nonIGAFields?.primary?.gender || "-",
        },
        {
            title: "Merital Status",
            value: <Box>
                <Autocomplete
                    onChange={onChangeMeritalStatus}
                    size="small"
                    id="tags-outlined"
                    name="maritalStatus"
                    options={getMaritalStatusInput()}
                    defaultValue={defaultMetrialStatus}
                    getOptionLabel={(option) => option?.lable}
                    renderInput={(params) => (
                        <TextField color="success"  {...params} label="Merital Status" />
                    )}
                    disableClearable={true}
                />
            </Box>,
        },
        {
            title: " Merital Status (IGA) ",
            value: getCurrentData?.IGAFeilds?.maritalStatus || getCurrentData?.nonIGAFields?.overridden?.maritalStatus || "-",
        },
        {
            title: "Passport Number",
            value: getCurrentData?.IGAFeilds?.passportNumber || getCurrentData?.nonIGAFields?.primary?.passportNumber || "-",
        },
        {
            title: "Passport Expiry ",
            value: getCurrentData?.IGAFields?.passportExpiryDate || getCurrentData?.nonIGAFields?.primary?.passportExpiryDate || "-",
        },
        {
            title: "Nationality ",
            value: getCurrentData?.IGAFields?.nationality || getCurrentData?.nonIGAFields?.primary?.nationality || "-",
        },
        {
            title: "Email",
            value: <Box>
                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="email"
                    value={bodyDataRequestDetails?.email}
                    onChange={handlechange}
                    type="text" />
            </Box>,
        },
        {
            title: "Email(IGA) ",
            value: getCurrentData?.IGAFields?.email ||"-",
        },
        {
            title: "Phone Number ",
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="phoneNo"
                    value={bodyDataRequestDetails?.phoneNo || getCurrentData?.nonIGAFields?.overridden?.phoneNo || ""}
                    onChange={handlechange}
                    type="text" />
            </Box>,
        },
        {
            title: "Phone Number(IGA) ",
            value: getCurrentData?.IGAFields?.telephoneNumber || getCurrentData?.nonIGAFields?.primary?.telephoneNumber || "-",
        },
        {
            title: "Mobile ",
            value: <Box>
                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Mobile"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="mobile"
                    value={bodyDataRequestDetails?.mobile}
                    onChange={handlechange}
                    type="text" />
            </Box>,
        },
        {
            title: "Other Contect No. (tel) ",
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Other Contect No."
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="otherPhoneNo"
                    value={bodyDataRequestDetails?.otherPhoneNo}
                    onChange={handlechange}
                    type="text" />
            </Box>,
        },
        {
            title: <p>PDE Duration <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <Autocomplete
                    name="PEPId"
                    // value={mandatorydata.pde_duration}
                    onChange={PDEDuration}
                    size="small"
                    disableClearable={true}
                    id="tags-outlined"
                    defaultValue={bodyDataRequestDetails?.PEPId}
                    options={yesNo}
                    getOptionLabel={(option) => option?.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="PDE Duration" />
                    )}
                />
            </Box>,
        },
        {
            title: <p>FATCA Declaration <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <Autocomplete
                    size="small"
                    name="FATCADeclarationId"
                    defaultValue={bodyDataRequestDetails?.FATCADeclarationId}
                    onChange={FATCADeclaration}
                    disableClearable={true}
                    id="tags-outlined"
                    options={yesNo}
                    getOptionLabel={(option) => option?.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="FATCA Declaration" />
                    )}
                />
            </Box>,
        },
        {
            title: <p>CRS Declaration <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <Autocomplete
                    size="small"
                    disableClearable={true}
                    name="CRSDeclarationId"
                    defaultValue={bodyDataRequestDetails?.CRSDeclarationId}
                    onChange={CRSDeclaration}
                    id="tags-outlined"
                    options={yesNo}
                    getOptionLabel={(option) => option?.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="CRS Declaration" />
                    )}
                />
            </Box>,
        },
        {
            title: <p>Are you a US Citizen? <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <Autocomplete
                    size="small"
                    disableClearable={true}
                    id="tags-outlined"
                    name="USCitizen"
                    defaultValue={bodyDataRequestDetails?.USCitizen}
                    onChange={AreyouUSCitizen}
                    options={yesNo}
                    getOptionLabel={(option) => option?.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Are you a US Citizen?" />
                    )}
                />
            </Box>,
        },
        {
            title: <p>Are you a US tax resident (c.g. green card holder)?<span
                className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <Autocomplete
                    size="small"
                    id="tags-outlined"
                    name="USTaxResident"
                    disableClearable={true}
                    defaultValue={bodyDataRequestDetails?.USTaxResident}
                    onChange={AreyouUSTaxresident}
                    options={yesNo}
                    getOptionLabel={(option) => option?.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success"  {...params} label="Are you a US tax resident" />
                    )}
                />
            </Box>,
        },
        {
            title: <p>Were you born in the US <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <Autocomplete
                    size="small"
                    id="tags-outlined"
                    name="bornInUS"
                    defaultValue={bodyDataRequestDetails?.bornInUS}
                    disableClearable={true}
                    onChange={WereyoubornTheUS}
                    options={yesNo}
                    getOptionLabel={(option) => option?.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Were you born in the US" />
                    )}
                />
            </Box>,
        },


    ];
    const fieldsLists2 = [
        {
            title: "Country Of Residence ",
            value: <Box>
                <Autocomplete
                    size="small"
                    id="tags-outlined"
                    name="GDPRCountryOfResidence"
                    // value={bodyData.country_of_residence}
                    onChange={gdplCountryOfResidence}
                    disableClearable={true}
                    options={Country1}
                    getOptionLabel={(option) => option?.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Country Of Residence" />
                    )}
                />
            </Box>,
        },
    ];
    const fieldsLists3 = [
        {
            title: "Country Of Residence ",
            value: <Box>
                <Autocomplete
                    size="small"
                    id="tags-outlined"
                    name="PDPLCountryOfResidence"
                    onChange={pdplCountryOfResidence}
                    options={Country2}
                    getOptionLabel={(option) => option?.lable}
                    filterSelectedOptions
                    disableClearable={true}
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Country Of Residence" />
                    )}
                />
            </Box>,
        }, {
            title: <p className="text-danger">Legal Basis for data processing </p>,
            value: <Box>
                <Autocomplete
                    size="small"
                    id="tags-outlined"
                    name="dataProcessing"
                    // value={bodyData.data_processing}
                    onChange={LegalBasisDataProcessing}
                    options={sempleoption}
                    disableClearable={true}
                    getOptionLabel={(option) => option?.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Legal Basis for data processing" />
                    )}
                />
            </Box>,
        }, {
            title: <p className="text-danger">Information Notices Provided </p>,
            value: <Box>
                <Autocomplete
                    size="small"
                    disableClearable={true}
                    id="tags-outlined"
                    name="noticesProvided"
                    // value={bodyData.information_notices_provided}
                    onChange={InformationNoticesProvided}
                    options={sempleoption}
                    getOptionLabel={(option) => option?.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Information Notices Provided" />
                    )}
                />
            </Box>,
        }
    ];

    return (
        <>
            <Container fluid >

                <Accordion className="mt-4" defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <Row className="border-bottom border-dark">
                            <AccordionButton
                                style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                    Customer Details
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </Row>
                        <AccordionPanel pb={4}>


                            {
                                fieldsLists1.map((field) =>
                                    <Row className="mt-3">
                                        <Col sm={3} xs={12}
                                            className="text-md-end text-start">{field?.title} </Col>
                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                            {field?.value}
                                        </Col>
                                        <Col xs={0} sm={2} xl={3}></Col>
                                    </Row>
                                )
                            }


                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Container>

        </>
    )
}
export default CaptureRequestDetails2;