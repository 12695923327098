import { Alert, Autocomplete, Box, CircularProgress, Snackbar, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap"
import { BiAlbum } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall, LogTime } from "../../Api/UseFeitch";
import { Result } from "antd";
// import "../globle.css"

const CaptureCleintConsent = ({ data, setData, groupstep3, setGroupstep3, groupPerentstep, setGroupPerentstep, setShowTable8, setShowmenu }) => {
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [btnNameSubmit, setBtnNameSubmit] = useState("SAVE AND COMPLETE");

    const [cleint_consent, setCleint_consent] = useState([])

    const [bodyData, setBodyData] = useState({
        device_type: "android",
        ajtoken: "AJABCDEFGH",
        username: "8888888888",

    });

    const handleselect = (event, cleint_consent) => {
        setCleint_consent(cleint_consent);
    }

    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)

    }
    function alertFunc() {
        setGroupPerentstep(3);
        navigate("/panel2/2" + "?token=8&step=3&substep=0");
        setShowmenu(1)
    }

    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);

    let logintime = LogTime();

    // console.log("logintime", logintime);
    useEffect(() => {
        if (logintime == false) {
            navigate("/");
            sessionStorage.removeItem("tokenSessionStorage");
        }
    }, []);


    async function handleclick(e) {

        e.preventDefault();
        setBtnNameSubmit(<Spinner animation="border" variant="danger" style={{ height: "22px", width: "22px" }} />);

        let resultt = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "NonIGA",
                    nonIGA: {
                        "passport": "31348675222",
                        "gender": "M",
                        "DOB": "10/10/2020",
                        "nationality": "PAK",
                        ...bodyData, cleint_consent: cleint_consent
                    }
                },
                consentMode: ""
            }
        })




        setData(resultt)



        // console.log("resultt-----------", resultt)
        if (resultt.messageStatus == "OK") {
            setData(resultt);
            setOpen2(true);
            setMessage(resultt.errorDescription)
            timeout = setTimeout(alertFunc, 2000);
            setBtnNameSubmit("SAVE AND COMPLETE");



        } else {
            setOpen(true);
            setMessage(resultt.errorDescription)
            setBtnNameSubmit("SAVE AND COMPLETE");

        }

    }

    const sempleoption = [
        { title: 'Option 1', year: 1994 },
        { title: 'Option 2', year: 1972 },
        { title: 'Option 3', year: 1974 },
        { title: 'Option 4', year: 2008 }
    ];

    const links = <Link className="text-decoration-none text-dark Fontsize13" style={{ cursor: "pointer" }}>Edit</Link>;


    const thdata = [
        {
            id: "1",
            thname: "ID"
        },
        {
            id: "2",
            thname: "Building Type"
        },
        {
            id: "3",
            thname: "Building No."
        },
        {
            id: "4",
            thname: "Flat No."
        },
        {
            id: "5",
            thname: "Building Alpha"
        },
        {
            id: "6",
            thname: "Road No."
        },
        {
            id: "7",
            thname: "Block No."
        },
        {
            id: "8",
            thname: "Country "
        },
        {
            id: "9",
            thname: "Type"
        },
        {
            id: "10",
            thname: "Action"
        },
    ]
    const tddata1 = [
        {
            id: "1",
            thname: "5229"
        },
        {
            id: "2",
            thname: "Villa"
        },
        {
            id: "3",
            thname: "1282"
        },
        {
            id: "4",
            thname: "0"
        },
        {
            id: "5",
            thname: ""
        },
        {
            id: "6",
            thname: "8464"
        },
        {
            id: "7",
            thname: "986"
        },
        {
            id: "8",
            thname: "Bahrain"
        },
        {
            id: "9",
            thname: "Residential"
        },
        {
            id: "10",
            thname: <Tooltip title={links} arrow><BsThreeDots />  </Tooltip>
        },
    ]
    const tddata2 = [
        {
            id: "1",
            thname: "5230"
        },
        {
            id: "2",
            thname: "Office"
        },
        {
            id: "3",
            thname: "1283"
        },
        {
            id: "4",
            thname: "4"
        },
        {
            id: "5",
            thname: ""
        },
        {
            id: "6",
            thname: "8465"
        },
        {
            id: "7",
            thname: "987"
        },
        {
            id: "8",
            thname: "Bahrain"
        },
        {
            id: "9",
            thname: "Residential"
        },
        {
            id: "10",
            thname: <Tooltip title={links} arrow><BsThreeDots />  </Tooltip>
        },
    ]
    const selectstyle = {
        backgroundColor: 'white',
        borderRadius: '0px'
    };

    const fieldsLists1 = [
        {
            title: "ID",
            value: "1738",
        },
        {
            title: "Legel Entity Type ",
            value: "  Individual",
        },
        {
            title: "First Name",
            value: data?.nonIGAFields?.primary?.firstNameEn || "-",
        },
        {
            title: "Middle Name 1",
            value: data?.nonIGAFields?.primary?.middleName1En || "-",
        },
        {
            title: "Middle Name 2 ",
            value: data?.nonIGAFields?.primary?.middleName2En || "-",
        },
        {
            title: "Middle Name 3",
            value: data?.nonIGAFields?.primary?.middleName2En || "-",
        },
        {
            title: "Middle Name 4",
            value: data?.nonIGAFields?.primary?.middleName4En || "-",
        },
        {
            title: "Last Name",
            value: data?.nonIGAFields?.primary?.lastNameEn || "-",
        },
        {
            title: "CPR Number ",
            value: data?.IGAFields?.idNumber || "-",
        },
        {
            title: "Date Of Birth ",
            value: data?.nonIGAFields?.primary?.dateOfBirth || "-",
        },
        {
            title: "Place Of Birth ",
            value: data?.nonIGAFields?.primary?.placeOfBirth || "-",
        },
        {
            title: "Gender",
            value: data?.nonIGAFields?.primary?.gender || "-",
        },
        {
            title: "Merital Status (IGA) ",
            value: data?.nonIGAFields?.overridden?.maritalStatus || "-",
        },
        {
            title: "Pasport Number ",
            value: data?.nonIGAFields?.primary?.passportNumber || "-",
        },
        {
            title: "Nationality ",
            value: data?.nonIGAFields?.primary?.nationality || "-",
        },
        {
            title: "Email(IGA) ",
            value: data?.nonIGAFields?.overridden?.email || "-",
        },
        {
            title: "Phone Number (IGA) ",
            value: data?.nonIGAFields?.primary?.telephoneNumber || "-",
        },
        {
            title: "Mobile ",
            value: data?.nonIGAFields?.overridden?.mobile || "-",
        },
        {
            title: "Other Contect No. (tel) ",
            value: data?.nonIGAFields?.additional?.otherPhoneNo || "-",
        },
        {
            title: "PDE Duration ",
            value: data?.nonIGAFields?.additional?.PEPId || "-",
        },
        {
            title: "FATCA Declaration ",
            value: data?.nonIGAFields?.additional?.FATCADeclarationId || "-",
        },
        {
            title: "CRS Declaration ",
            value: data?.nonIGAFields?.additional?.CRSDeclarationId || "-",
        },
        {
            title: "Are you a US sitizen? ",
            value: data?.nonIGAFields?.additional?.USCitizen || "-",
        },
        {
            title: "Are you a US tax resident (c.g. green card holder)? ",
            value: data?.nonIGAFields?.additional?.USTaxResident || "-",
        },
        {
            title: "Were you born in the Us ",
            value: data?.nonIGAFields?.additional?.bornInUS || "-",
        }, {
            title: "Are You a Tax Resident in any Country/jurisdiction ",
            value: data?.nonIGAFields?.additional?.USTaxResident || "-",
        }, {
            title: "Other Than The Kingdom Of Bahrain ?",
            value: data?.nonIGAFields?.additional?.bornInUS || "-",
        },
    ];


    const fieldsLists2 = [
        {
            title: "Employment Status (IGA)",
            value: data?.nonIGAFields?.overridden?.employmentStatus || "-",
        },
        {
            title: "Work Permit Valid ",
            value: data?.nonIGAFields?.additional?.workPermitValid || "-",
        },
        {
            title: "Resident Permit No.",
            value: data?.nonIGAFields?.primary?.residentPermitNo || "-",
        },
        {
            title: "Occupation (IGA)",
            value: data?.nonIGAFields?.overridden?.occupation || "-",
        },
        {
            title: "Name Of Employer",
            value: data?.nonIGAFields?.primary?.nameOfEmployer || "-",
        },

        {
            title: "Salary (Monthly Income) ",
            value: data?.nonIGAFields?.additional?.salary || "-",
        },
        {
            title: "Sourso Of Found ",
            value: data?.nonIGAFields?.additional?.sourceOfFunds || "-",
        },
        {
            title: "Nature Of Activity Business",
            value: data?.nonIGAFields?.additional?.natureOfBusinessActivity || "-",
        },

    ];

    const fieldsLists3 = [
        {
            title: "Type Of Account",
            value: data?.nonIGAFields?.additional?.typeOfAccount || "-",
        },
        {
            title: "Nature And Lavel Of Business Relationship",
            value: data?.nonIGAFields?.additional?.natureAndLevelOfBusinessActivity || "-",
        },
        {
            title: "Reasion For Opening Account In Bahrain",
            value: data?.nonIGAFields?.additional?.reasonForOpeningAccount || "-",
        },
        {
            title: "Monthly Turnover (BD Per Month)",
            value: data?.nonIGAFields?.additional?.monthlyTurnOver || "-",
        },


    ];

    const fieldsLists4 = [
        {
            title: <p className="fw-bold" style={{ lineHeight: "38px" }}>1, the undersigned, hereby with my full<span className="ms-3 pt-1 fw-bold text-danger">*</span> legal capacity
                and of my own free will acknowledge disclosing,
                processing, hosting, storing, and transferring of all my
                personal information retained by Government
                Authorities and Financial Industry to BENEFIT, its
                members, and other entities within and outside the
                Kingdom of Bahrain. I further agree to share, host,
                store, process, and transfer such information within
                and outside the Kingdom of Bahrain for 'Know Your
                Customer - KYC purposes by using "WATHIQ' services operated by THE BENEFIT Company and its members at any time and periodically. The information is therefore, released without any liability or responsibility on the
                part of the BENEFIT Company. I am aware that upon
                request, I may view the measures and policies in this
                regard at any time. I am hereby give my explicit
                consent to all the above.</p>,
            value: <Box >
                <Autocomplete
                    style={selectstyle}
                    name="cleint_consent"
                    onChange={handleselect}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.title}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small"  {...params} placeholder="Select" />
                    )}
                />
            </Box>,
        },



    ];
    return (
        <>
            <form onSubmit={handleclick}>
                <Container fluid id="scroleble_main_content">



                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>



                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open2} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-success text-light" severity="success" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>





                    <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="h3 mt-4" as="div" flex='1' textAlign='left'>
                                        Review Data And Capture Cleint Consent
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>

                                <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Customer Details
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>
                                            {
                                                fieldsLists1.map((field) =>
                                                    <Row className="mt-3">
                                                        <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                                            {field?.value}
                                                        </Col>
                                                        <Col xs={0} sm={2} xl={3}></Col>
                                                    </Row>
                                                )
                                            }
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Employment Details
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>

                                            {
                                                fieldsLists2.map((field) =>
                                                    <Row className="mt-3">
                                                        <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                                            {field?.value}
                                                        </Col>
                                                        <Col xs={0} sm={2} xl={3}></Col>
                                                    </Row>
                                                )
                                            }
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Account Details
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>
                                            {
                                                fieldsLists3.map((field) =>
                                                    <Row className="mt-3">
                                                        <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                                            {field?.value}
                                                        </Col>
                                                        <Col xs={0} sm={2} xl={3}></Col>
                                                    </Row>
                                                )
                                            }


                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Client Consent
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>
                                            {
                                                fieldsLists4.map((field) =>
                                                    <Row className="mt-3">
                                                        <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                                            {field?.value}
                                                        </Col>
                                                        <Col xs={0} sm={2} xl={3}></Col>
                                                    </Row>
                                                )
                                            }

                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Addresses
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>


                                            <Row className="mt-4">
                                                <Col>
                                                    <Table className="table table-striped" responsive>
                                                        <thead>
                                                            <tr>
                                                                {
                                                                    thdata.map((thdata) => {
                                                                        return (
                                                                            <>
                                                                                <th scope="col">{thdata.thname}</th>
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                {
                                                                    tddata1.map((tddata) => {
                                                                        return (
                                                                            <>
                                                                                <td scope="col">{tddata.thname}</td>
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </tr>
                                                            <tr>
                                                                {
                                                                    tddata2.map((tddata) => {
                                                                        return (
                                                                            <>
                                                                                <td scope="col">{tddata.thname}</td>
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </tr>
                                                            <tr>
                                                                {
                                                                    tddata1.map((tddata) => {
                                                                        return (
                                                                            <>
                                                                                <td scope="col">{tddata.thname}</td>
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </tr>

                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Row className="h5 mt-5 text-end mb-5">
                                    <Col className="me-4">
                                        <Button className="me-3 ps-4 pe-4 btn-secondary buttonstyle ">SAVE FOR LATER</Button>
                                        <Button
                                            type="submit"
                                            className="ps-4 mt-sm-0 mt-2 pe-4 btn-secondary buttonstyle ">
                                            {btnNameSubmit}
                                        </Button>

                                    </Col>
                                </Row>

                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>








                </Container>
            </form>
        </>
    )
}
export default CaptureCleintConsent;