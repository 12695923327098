import { Alert, Autocomplete, Box, Snackbar, TextField } from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall, LogTime } from "../../Api/UseFeitch";
import { useEffect } from "react";

const ValidateIDV = ({ groupstep5, setGroupstep5, setShowmenu }) => {
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [btnNameSubmit, setBtnNameSubmit] = useState("SUBMIT");

    const [review_outcome, setReview_outcome] = useState([]);
    const [refer_to_stage, setRefer_to_stage] = useState([]);
    const [refer_to_reasion, setRefer_to_reasion] = useState([]);
    const [bodyData, setBodyData] = useState({
        reviewNotes: "",
        referToReasionComments: "",

    });
    const [mandatorydata, setMandatory] = useState({
        review_outcome: "",
        refer_to_stage: "",
        refer_to_reasion: "",
    })

    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value })
        setMandatory({ ...mandatorydata, [name]: value })
    }

    const handleselect1 = (event, review_outcome) => {
        setReview_outcome(review_outcome);
    }

    const handleselect2 = (event, refer_to_stage) => {
        setRefer_to_stage(refer_to_stage);
    }
    const handleselect3 = (event, refer_to_reasion) => {
        setRefer_to_reasion(refer_to_reasion);
    }

    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)

    }
    function alertFunc() {
        navigate("/panel2/2" + "?token=8&step=4&substep=2");
        setShowmenu(1)
    }
    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);

    let logintime = LogTime();

    // console.log("logintime", logintime);
    useEffect(() => {
        if (logintime == false) {
            navigate("/");
            sessionStorage.removeItem("tokenSessionStorage");
        }
    }, []);


    async function handleclick(e) {

        e.preventDefault();
        setBtnNameSubmit(<Spinner animation="border" variant="danger" style={{ height: "22px", width: "22px" }} />);

        let resultt = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "NonIGA",
                    nonIGA: {
                        "passport": "31348675222",
                        "gender": "M",
                        "DOB": "10/10/2020",
                        "nationality": "PAK",
                        ...bodyData, reviewOutcome: review_outcome, referToStage: refer_to_stage, referToReasion: refer_to_reasion
                    }
                },
                consentMode: ""
            }
        })

        // console.log("resultt-----------", resultt)
        if (resultt.messageStatus == "OK") {
            setOpen2(true);
            setMessage(resultt.errorDescription);
            timeout = setTimeout(alertFunc, 1000);
            setBtnNameSubmit("SUBMIT");

        } else {
            setOpen(true);
            setMessage(resultt.errorDescription);
            setBtnNameSubmit("SUBMIT");

        }



    }




    const sempleoption = [
        { title: 'Option 1', year: 1994 },
        { title: 'Option 2', year: 1972 },
        { title: 'Option 3', year: 1974 },
        { title: 'Option 4', year: 2008 }
    ];
   const selectstyle = {
        backgroundColor: 'white',
        borderRadius: '0px'
    };

    const fieldsLists = [
        {
            title: "Reviewer",
            value: " Benefitcloud/Rafidain904877535",
        },
        {
            title: "Review Date",
            value: " 02/17/2020",
        },
        {
            title: <p>Review Outcome <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box >
                <Autocomplete
                    name="reviewOutcome"
                    multiple
                    style={selectstyle}
                    onChange={handleselect1}
                    size="small"
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.title}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small"  {...params} placeholder="Select" />
                    )}
                />
            </Box>,
        }, {
            title: "Review Notes ",
            value: <Box >
                <Form.Control style={{ borderRadius: "0px" }} name="reviewNotes"
                    value={bodyData.reviewNotes} onChange={handlechange} as="textarea" rows={3} />
            </Box>
            ,
        }, {
            title: <p>Refer to Stage <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box >
                <Autocomplete
                    name="referToStage "
                    onChange={handleselect2}
                    style={selectstyle}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.title}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small"  {...params} placeholder="Select" />
                    )}
                />
            </Box>
            ,
        }
        , {
            title: <p>Refer to Reason   <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box >
                <Autocomplete
                    style={selectstyle}
                    name="referToReasion"
                    onChange={handleselect3}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.title}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small"  {...params} placeholder="Select" />
                    )}
                />
            </Box>
            ,
        }, {
            title: "Refer to Reason Comments",
            value: <Box >
                <Form.Control style={{ borderRadius: "0px" }} name="referToReasionComments" value={bodyData.referToReasionComments} onChange={handlechange} as="textarea" rows={3} />
            </Box>
            ,
        }


    ];



    return (
        <>
            <form onSubmit={handleclick}>
                <Container fluid id="scroleble_main_content">



                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>



                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open2} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-success text-light" severity="success" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>


                    <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Validate ID&V
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>
                                <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Manage Review
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>
                                            {
                                                fieldsLists.map((field) =>
                                                    <Row className="mt-3 ">
                                                        <Col sm={3} xs={12} className="text-md-end text-start text-danger">{field?.title} </Col>
                                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                                            {field?.value}
                                                        </Col>
                                                        <Col xs={0} sm={2} xl={3}></Col>
                                                    </Row>
                                                )
                                            }

                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                                <Row className=" mt-5  mb-5">
                                    <Col md={4} lg={5} xl={6} xs={0} className=""></Col>
                                    <Col md={8} lg={7} xl={6} xs={12} className=" ">
                                        <Container>
                                            <Row>

                                                <Col md={8} sm={6} className=" text-end">
                                                    <Button
                                                        className="ps-4 pe-4 btn-secondary buttonstyle ">
                                                        SAVE FOR LATER
                                                    </Button>
                                                </Col>
                                                <Col md={4} sm={6} className=" text-end mt-sm-0 mt-2">
                                                    <Button
                                                        type="submit"
                                                        className="ps-4 pe-4 btn-secondary buttonstyle ">
                                                        {btnNameSubmit}
                                                    </Button></Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>

                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>


                </Container>
            </form>
        </>
    )
}
export default ValidateIDV;