import { Autocomplete, Avatar, Box, InputAdornment, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, Form, InputGroup, Row, Collapse } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { BsArrowDownShort, BsChevronDown, BsChevronRight, BsFillCaretLeftFill, BsThreeDots } from "react-icons/bs";
import { FaSort } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Apicall, LogTime } from "../../Api/UseFeitch";
import { Alert, Snackbar } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, useDisclosure } from "@chakra-ui/react";
import Documents from "./Documents";



const KycRequirements = ({ documentContentdata3, documentContentdata2, setDocumentContentdata3, setDocumentContentdata2, documentContent, setDocumentContent }) => {
    const navigate = useNavigate();
    const [icon, setIcon] = useState(false);
    const [message, setMessage] = useState();
    const [filelessHideDocument1,setFilelessHideDocument1]=useState(false);
    const [filelessHideDocument2,setFilelessHideDocument2]=useState(false);
    const [filelessHideDocument3,setFilelessHideDocument3]=useState(false);

    const [bulk_action, setBulk_action] = useState([]);


    const sempleoption = [
        { title: 'Option 1', year: 1994 },
        { title: 'Option 2', year: 1972 },
        { title: 'Option 3', year: 1974 },
        { title: 'Option 4', year: 2008 }

    ];

    const handleselect = (event, bulk_action) => {
        setBulk_action(bulk_action);
    }

    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)
    }
   
    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);

    let logintime = LogTime();

    useEffect(() => {
        if (logintime == false) {
            navigate("/");
            sessionStorage.removeItem("tokenSessionStorage");
        }
    }, []);



    const links = <Link className="text-decoration-none text-dark Fontsize13" style={{ cursor: "pointer" }}>Edit</Link>;
    const KYCRequirementstableth = [
        {
            id: "1",
            td: "Requirement "
        },
        {
            id: "2",
            td: "Document"
        }, {
            id: "3",
            td: "Status"
        }, {
            id: "4",
            td: "Due"
        }, {
            id: "5",
            td: "Cash"
        },
    ]
    const KYCRequirementstabledata1 = [
        {
            id: "1",
            td: <p className="text-danger">e KYC Customer Consent </p>
        },
        {
            id: "2",
            td: <b>0 suggested 0 likes</b>
        }, {
            id: "3",
            td: <b>Pending</b>
        }, {
            id: "4",
            td: <b>02/17/2023</b>
        }, {
            id: "5",
            td: <b>Cleint Onboarding</b>
        },
    ]
    const KYCRequirementstabledata2 = [
        {
            id: "1",
            td: <p className="text-danger">FATCA & CRS Self-Certification </p>
        },
        {
            id: "2",
            td: <b>0 suggested 0 likes</b>
        }, {
            id: "3",
            td: <b>Pending</b>
        }, {
            id: "4",
            td: <b>02/17/2023</b>
        }, {
            id: "5",
            td: <b>Cleint Onboarding</b>
        },
    ]
    const KYCRequirementstabledata3 = [
        {
            id: "1",
            td: <p className="text-danger">Data Sharing Consent Form </p>
        },
        {
            id: "2",
            td: <b>0 suggested 0 likes</b>
        }, {
            id: "3",
            td: <b>Pending</b>
        }, {
            id: "4",
            td: <b>02/17/2023</b>
        }, {
            id: "5",
            td: <b>Cleint Onboarding</b>
        },
    ]

    

    const [show1, setShow1] = useState(false);
    const [iconchange1, setIconchange1] = useState(false);
    const IconChangeToggle1 = () => {
        setIconchange1(!iconchange1);
        setShow1(!show1);
    }
    const [show2, setShow2] = useState(false);
    const [iconchange2, setIconchange2] = useState(false);
    const IconChangeToggle2 = () => {
        setIconchange2(!iconchange2);
        setShow2(!show2);
    }

    const [show3, setShow3] = useState(false);
    const [iconchange3, setIconchange3] = useState(false);
    const IconChangeToggle3 = () => {
        setIconchange3(!iconchange3);
        setShow3(!show3);
    }

    return (
        <>
            <Container fluid  >
                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open2} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-success text-light" severity="success" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
                <Row className="mt-4">
                    <Col xs={12} md={4} className="text-md-end text-start fw-bold mt-2 pt-1"> Bulk Action  </Col>
                    <Col xs={6} md={4} className="fw-bold text-center">
                        <Box className="textbox " sx={{ width: 400, maxWidth: '100%' }}>
                            <Autocomplete
                                onChange={handleselect}
                                name="bulkAction"
                                size="small"
                                multiple
                                id="tags-outlined"
                                options={sempleoption}F
                                getOptionLabel={(option) => option.title}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField color="success" {...params} label="Bulk Action" />
                                )}
                            />
                        </Box>

                    </Col>
                    <Col xs={6} md={4} className="text-sm-start text-center">
                        <Button className="ps-5  pe-5 bg-light text-dark border-dark">Submit</Button>
                    </Col>
                </Row>

                <Row className=" mt-4 ms-md-2 ms-0 me-md-1 me-0">
                    <InputGroup>
                        <InputGroup.Text id="basic-addon"><BiSearch size={20} color="var(--primary)" /></InputGroup.Text>
                        <Form.Control
                            placeholder="Username"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                        <InputGroup.Text id="basic-addon1" ><FaSort size={20} color="var(--primary)" />sort by due<BsArrowDownShort size={20} /></InputGroup.Text>
                    </InputGroup>
                </Row>
                {/* <Row className=" mt-2 ms-md-4 ms-0 me-md-3 me-0" style={{ backgroundColor: 'white' }}>
                    <Col sm={1} xs={2} className="p-0 m-0 d-flex align-items-center  justify-content-center">
                    {iconchange1 ? <BsChevronDown size={20} onClick={IconChangeToggle1} style={{ cursor: 'pointer' }} /> : < BsChevronRight size={20} onClick={IconChangeToggle1} style={{ cursor: 'pointer' }} />}

                    </Col>
                    <Col xs={8} sm={10} className="p-0">
                        <Container className="p-0 m-0" fluid>
                            <Row className="">
                                <TableContainer component={Paper} sx={{ boxShadow: 'none', backgroundColor: 'white' }}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    KYCRequirementstabledata1.map((tabledata) => {
                                                        return (
                                                            <>
                                                                <TableCell sx={{ padding: 0 }} >{tabledata.td}</TableCell>

                                                            </>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}   >
                                                {
                                                    KYCRequirementstabledata2.map((tabledata) => {
                                                        return (
                                                            <>
                                                                <TableCell sx={{ padding: 0 }}>{tabledata.td}</TableCell>


                                                            </>
                                                        )
                                                    })
                                                }
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>



                            </Row>


                        </Container>

                    </Col>
                    <Col xs={2} sm={1} className="d-flex align-items-center  justify-content-center ">
                        <Dropdown>
                            <Avatar sx={{ bgcolor: "white", width: "30px", height: "30px" }} >
                                <Dropdown.Toggle
                                    variant="secondery" style={{ color: "white", marginRight: "32px" }} >
                                    <BsThreeDots size={20} color="black" id="fabell" style={{ position: "relative", left: "16px" }} />
                                </Dropdown.Toggle>
                            </Avatar>
                            <div className="" >
                                <Dropdown.Menu className="kyc-dropdown"  >
                                    <Dropdown.Item className="" >

                                        <Container className="" >
                                            <Row className={"text-center pt-1 pb-2"}>
                                                <Col>
                                                    <Link className=" text-dark " to="/panel2/documents">
                                                        Attach Document <br />Request Via Email <br />View Request Details
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>




                    </Col>
                </Row>
                <Row className=" bg-light ms-md-4 ms-0 me-md-3 me-0">
                    <Collapse in={show1} >
                        
                        <Documents onToggle={onToggle} documentContent={documentContent}
                            setDocumentContent={setDocumentContent}  />
                        <Box >
                            This document and ancillary supporting information is the propriety of The
                            BENEFIT Company of Bahrain and should not be copied, used or related to any
                            third party without the written permission of The BENEFIT Company or the Central
                            Bank of Bahrain1. Specified the fields for mandatory, nullable, conditional and
                            optional in each API: getCurrentKYCData,
                            recordConsentAndFetchSourceData, screenCase, resolveCase,
                            publishSignedCase, Upload API, Login API
                            2. Removing the statement “It will require a separate call for
                            fetching the token using the login API from the document server” as
                            there is one consolidate login API.
                            3. Changed Upload API file field to accept base64 format.
                            4. Timestamp is now specified to the standard UTC Format.
                            5. Specified the fields for mandatory, nullable, conditional and
                            optional for the Documents.
                            12th August 2020 0.7 1. Sample request and response of the APIs are corrected to display
                            the description instead of the code.
                            2. BAH code is corrected to BHR. Refer to 6.13 GCC Country Codes
                            Lookup.
                            3. monthlyTurnOver, documentSource, documentDirection,
                            documentType fields are corrected to String type.
                            4. residentPermitExpiry field is corrected from long to DateTime
                            type.
                            5. Specified method to be used for Upload API
                            6. Specified file size and supported file extensions for Upload API
                            7. Removed policies field from the Upload API request
                            This document and ancillary supporting information is the propriety of The
                            BENEFIT Company of Bahrain and should not be copied, used or related to any
                            third party without the written permission of The BENEFIT Company or the Central
                            Bank of Bahrain1. Specified the fields for mandatory, nullable, conditional and
                            optional in each API: getCurrentKYCData,
                            recordConsentAndFetchSourceData, screenCase, resolveCase,
                            publishSignedCase, Upload API, Login API
                            2. Removing the statement “It will require a separate call for
                            fetching the token using the login API from the document server” as
                            there is one consolidate login API.
                            3. Changed Upload API file field to accept base64 format.
                            4. Timestamp is now specified to the standard UTC Format.
                            5. Specified the fields for mandatory, nullable, conditional and
                            optional for the Documents.
                            12th August 2020 0.7 1. Sample request and response of the APIs are corrected to display
                            the description instead of the code.
                            2. BAH code is corrected to BHR. Refer to 6.13 GCC Country Codes
                            Lookup.
                            3. monthlyTurnOver, documentSource, documentDirection,
                            documentType fields are corrected to String type.
                            4. residentPermitExpiry field is corrected from long to DateTime
                            type.
                            5. Specified method to be used for Upload API
                            6. Specified file size and supported file extensions for Upload API
                            7. Removed policies field from the Upload API request
                        </Box>
                    </Collapse>
                </Row> */}

                <Row className="mt-2 bg-light ms-md-4 ms-0 me-md-3 me-0">
                    <Col sm={1} xs={2} className="p-0 m-0 d-flex align-items-center  justify-content-center">
                        {iconchange1 ? <BsChevronDown  size={20} onClick={IconChangeToggle1} style={{ cursor: 'pointer' }} /> : < BsChevronRight size={20} onClick={IconChangeToggle1} style={{ cursor: 'pointer' }} />}
                    </Col>
                    <Col xs={8} sm={10} className="p-0">
                        <Container className="p-0 m-0" fluid>
                            <Row className="">
                                <TableContainer component={Paper} sx={{ boxShadow: 'none', backgroundColor: 'white' }}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    KYCRequirementstableth.map((tabledata) => {
                                                        return (
                                                            <>
                                                                <TableCell sx={{ padding: 0 }} >{tabledata.td}</TableCell>

                                                            </>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}   >
                                                {
                                                    KYCRequirementstabledata1.map((tabledata) => {
                                                        return (
                                                            <>
                                                                <TableCell sx={{ padding: 0 }}>{tabledata.td}</TableCell>


                                                            </>
                                                        )
                                                    })
                                                }
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>



                            </Row>


                        </Container>

                    </Col>
                    <Col xs={2} sm={1} className="d-flex align-items-center  justify-content-center ">
                        <Dropdown>
                            <Avatar sx={{ bgcolor: "white", width: "30px", height: "30px" }} >
                                <Dropdown.Toggle
                                    variant="secondery" style={{ color: "white", marginRight: "32px" }} >
                                    <BsThreeDots size={20} color="black" id="fabell" style={{ position: "relative", left: "16px" }} />
                                </Dropdown.Toggle>
                            </Avatar>
                            <div className="" >
                                <Dropdown.Menu className="kyc-dropdown"  >
                                    <Dropdown.Item className="" >

                                        <Container className="" >
                                            <Row className={"text-center pt-1 pb-2"}>
                                                <Col>
                                                    <span className=" text-dark " onClick={IconChangeToggle1} style={{ cursor: 'pointer' }}>
                                                        Attach Document <br />Request Via Email <br />View Request Details
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>




                    </Col>
                    <Collapse  in={show1}>
                        <div id="example-collapse-text">
                            <Documents title='doc1' errortype={true} nextIcon={iconchange2} setNextIcon={setIconchange2} setNextDocument={setShow2} nextDocument={show2} filelesscheck={true} filelessHideDocument={filelessHideDocument1} setFilelessHideDocument={setFilelessHideDocument1} iconchange={iconchange1} setIconchange={setIconchange1} setShow={setShow1} show={show1} documentContent={documentContent}
                                setDocumentContent={setDocumentContent} />
                        </div>
                    </Collapse>
                </Row>

                <Row className="border border-top bg-light ms-md-4 ms-0 me-md-3 me-0">
                    <Col sm={1} xs={2} className="p-0 m-0 d-flex align-items-center  justify-content-center">
                        {iconchange2 ? <BsChevronDown size={20} onClick={IconChangeToggle2} style={{ cursor: 'pointer' }} /> : < BsChevronRight size={20} onClick={IconChangeToggle2} style={{ cursor: 'pointer' }} />}
                    </Col>
                    <Col xs={8} sm={10} className="p-0">
                        <Container className="p-0 m-0" fluid>
                            <Row className="">
                                <TableContainer component={Paper} sx={{ boxShadow: 'none', backgroundColor: 'white' }}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    KYCRequirementstableth.map((tabledata) => {
                                                        return (
                                                            <>
                                                                <TableCell sx={{ padding: 0 }} >{tabledata.td}</TableCell>

                                                            </>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}   >
                                                {
                                                    KYCRequirementstabledata2.map((tabledata) => {
                                                        return (
                                                            <>
                                                                <TableCell sx={{ padding: 0 }}>{tabledata.td}</TableCell>


                                                            </>
                                                        )
                                                    })
                                                }
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>



                            </Row>


                        </Container>

                    </Col>
                    <Col xs={2} sm={1} className="d-flex align-items-center  justify-content-center ">
                        <Dropdown>
                            <Avatar sx={{ bgcolor: "white", width: "30px", height: "30px" }} >
                                <Dropdown.Toggle
                                    variant="secondery" style={{ color: "white", marginRight: "32px" }} >
                                    <BsThreeDots size={20} color="black" id="fabell" style={{ position: "relative", left: "16px" }} />
                                </Dropdown.Toggle>
                            </Avatar>
                            <div className="" >
                                <Dropdown.Menu className="kyc-dropdown"  >
                                    <Dropdown.Item className="" >

                                        <Container className="" >
                                            <Row className={"text-center pt-1 pb-2"}>
                                                <Col>
                                                    <span className=" text-dark " onClick={IconChangeToggle2} style={{ cursor: 'pointer' }}>
                                                        Attach Document <br />Request Via Email <br />View Request Details
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>




                    </Col>
                    <Collapse in={show2}>
                        <div id="example-collapse-text">
                            <Documents title='doc2' errortype={false} nextIcon={iconchange3} setNextIcon={setIconchange3} setNextDocument={setShow3} nextDocument={show3} filelesscheck={false} filelessHideDocument={filelessHideDocument2} setFilelessHideDocument={setFilelessHideDocument2} iconchange={iconchange2} setIconchange={setIconchange2} setShow={setShow2} show={show2} documentContent={documentContentdata2} setDocumentContent={setDocumentContentdata2} />
                        </div>

                    </Collapse>
                </Row>

                <Row className="border border-top bg-light ms-md-4 ms-0 me-md-3 me-0">
                    <Col sm={1} xs={2} className="p-0 m-0 d-flex align-items-center  justify-content-center">
                        {iconchange3 ? <BsChevronDown size={20} onClick={IconChangeToggle3} style={{ cursor: 'pointer' }} /> : < BsChevronRight size={20} onClick={IconChangeToggle3} style={{ cursor: 'pointer' }} />}
                    </Col>
                    <Col xs={8} sm={10} className="p-0">
                        <Container className="p-0 m-0" fluid>
                            <Row className="">
                                <TableContainer component={Paper} sx={{ boxShadow: 'none', backgroundColor: 'white' }}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    KYCRequirementstableth.map((tabledata) => {
                                                        return (
                                                            <>
                                                                <TableCell sx={{ padding: 0 }} >{tabledata.td}</TableCell>

                                                            </>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}   >
                                                {
                                                    KYCRequirementstabledata3.map((tabledata) => {
                                                        return (
                                                            <>
                                                                <TableCell sx={{ padding: 0 }}>{tabledata.td}</TableCell>


                                                            </>
                                                        )
                                                    })
                                                }
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>



                            </Row>


                        </Container>

                    </Col>
                    <Col xs={2} sm={1} className="d-flex align-items-center  justify-content-center ">
                        <Dropdown>
                            <Avatar sx={{ bgcolor: "white", width: "30px", height: "30px" }} >
                                <Dropdown.Toggle
                                    variant="secondery" style={{ color: "white", marginRight: "32px" }} >
                                    <BsThreeDots size={20} color="black" id="fabell" style={{ position: "relative", left: "16px" }} />
                                </Dropdown.Toggle>
                            </Avatar>
                            <div className="" >
                                <Dropdown.Menu className="kyc-dropdown"  >
                                    <Dropdown.Item className="" >

                                        <Container className="" >
                                            <Row className={"text-center pt-1 pb-2"}>
                                                <Col>
                                                    <span className=" text-dark " onClick={IconChangeToggle3} style={{ cursor: 'pointer' }}>
                                                        Attach Document <br />Request Via Email <br />View Request Details
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>

                    </Col>
                    <Collapse in={show3}>
                        <div id="example-collapse-text">
                            <Documents title='doc3' errortype={false} filelesscheck={false} nextIcon={iconchange3} setNextIcon={setIconchange3} setNextDocument={setShow3} nextDocument={show3}  filelessHideDocument={filelessHideDocument3} setFilelessHideDocument={setFilelessHideDocument3} iconchange={iconchange3} setIconchange={setIconchange3} setShow={setShow3} show={show3} documentContent={documentContentdata3} setDocumentContent={setDocumentContentdata3} />
                        </div>
                    </Collapse>
                </Row>










            </Container>
        </>
    )
}
export default KycRequirements;