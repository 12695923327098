import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap"
import { Alert, Autocomplete, Box, Snackbar, TextField } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall, LogTime, getCountryCodeByGccId } from "../../Api/UseFeitch";
import { inputStyle } from "../helpingFunction/helpingInputData";

const CaptureAccountDetails = ({ setBoAccountDetail }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [gccId, setGccId] = useState(searchParams.get("gccid") || "");
    const [getCurrentData, setGetCurrentData] = useState({});
    const [bodyDataAccountDetails, setBodyDataAccountDetails] = useState({});
    const [cardCountry, setCardCountry] = useState("BHR");


    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);


    async function fetchMyAPI(gccIdTemp) {
        let cardCountryTemp = await getCountryCodeByGccId(gccIdTemp);
        if (!cardCountryTemp) {
            return;
        }
        setCardCountry(cardCountryTemp);
        setGccId(gccIdTemp);
        let getdata = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "GCC",
                    GCC: {
                        "cardCountry": cardCountryTemp || "BHR",
                        "nationalID": gccIdTemp || "720033225",
                    }
                },
            },
        })
        setGetCurrentData(getdata);
        if (getdata) {
            setBodyDataAccountDetails({
                natureAndLevelOfBusinessActivity: getdata?.nonIGAFields?.additional?.natureAndLevelOfBusinessActivity ||"",
                reasonForOpeningAccount: getdata?.nonIGAFields?.additional?.reasonForOpeningAccount ||"",
                typeOfAccount:"",
                monthlyTurnOver:""
            })
        }

    }

    useEffect((e) => {
        if (searchParams.get("gccid")) fetchMyAPI(searchParams.get("gccid"))
        if (searchParams.get("gccId")) fetchMyAPI(searchParams.get("gccId"))
    }, [searchParams.get("gccid"), searchParams.get("gccId")])
   

    const handlechangeAccountDetails = (e) => {
        const { name, value } = e.target;
        setBodyDataAccountDetails({ ...bodyDataAccountDetails, [name]: value })
    }


    const typeofAccountAccountDetails = (event, type_of_account) => {
        setBodyDataAccountDetails({ ...bodyDataAccountDetails, typeOfAccount: type_of_account.lable });
    }

    const monthlyTurnoverAccountDetails = (event, monthly_turnover) => {
        setBodyDataAccountDetails({ ...bodyDataAccountDetails, monthlyTurnOver: monthly_turnover.lable });

    }
    setBoAccountDetail(bodyDataAccountDetails)
   


    const AccountAccountDetails = [
        { lable: 'Current', id: 2 },
        { lable: 'Saving', id: 3 },
        { lable: 'Business', id: 4 },
        { lable: 'Investments', id: 5 },
        { lable: 'Credit Facility', id: 6 },
        { lable: 'Insurance', id: 7 },
        { lable: 'Remittance', id: 8 },
    ];

    const MonthlyTurnoverAccountDetails = [
        { lable: '0-500', id: 1 },
        { lable: '500-1000', id: 2 },
        { lable: '1000-2000', id: 3 },
        { lable: '2000-4000', id: 4 },
        { lable: '4000+', id: 5 },
    ];
    const fieldsListsAccountDetails = [
        {
            title: "Type Of Account",
            value: <Box>
                <Autocomplete
                    name="typeOfAccount"
                    onChange={typeofAccountAccountDetails}
                    size="small"
                    disableClearable={true}
                    id="tags-outlined"
                    options={AccountAccountDetails}
                    getOptionLabel={(option) => option.lable}
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Type Of Account" />
                    )}
                />
            </Box>,
        }, {
            title: "Nature And Lavel Of Business Relationship",
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Nature And Lavel Of Business Relationship"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    onChange={handlechangeAccountDetails}
                    name="natureAndLevelOfBusinessActivity"
                    value={bodyDataAccountDetails?.natureAndLevelOfBusinessActivity}
                    type="text" />
            </Box>
            ,
        }, {
            title: "Reasion For Opening Account In Bahrain",
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Reasion For Opening Account In Bahrain"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    onChange={handlechangeAccountDetails}
                    name="reasonForOpeningAccount"
                    value={bodyDataAccountDetails?.reasonForOpeningAccount}
                    type="text" />
            </Box>
            ,
        }
        , {
            title: "Monthly Turnover (BD Per Month) ",
            value: <Box>
                <Autocomplete
                    name="monthlyTurnOver"
                    disableClearable={true}
                    onChange={monthlyTurnoverAccountDetails}
                    size="small"
                    id="tags-outlined"
                    options={MonthlyTurnoverAccountDetails}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Monthly Turnover" />
                    )}
                />
            </Box>
            ,
        }
    ];

    return (
        <>

            <Container fluid >

                <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <Row className="border-bottom border-dark">
                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                    Account Details
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </Row>
                        <AccordionPanel pb={4}>
                            {
                                fieldsListsAccountDetails.map((field) =>
                                    <Row className="mt-3">
                                        <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                            {field?.value}
                                        </Col>
                                        <Col xs={0} sm={2} xl={3}></Col>
                                    </Row>
                                )
                            }

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

            </Container>

        </>
    )
}

export default CaptureAccountDetails;