import React, { Component, useRef, useState } from "react";
import { Button, Col, Container, Form, Overlay, OverlayTrigger, Row, Spinner, Table } from "react-bootstrap";
import { Alert, Autocomplete, Box, Snackbar, TextField, Tooltip } from "@mui/material";
import { BiMenu } from "react-icons/bi";
import { BsDownload, BsFillHexagonFill, BsThreeDots } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall, LogTime } from "../../Api/UseFeitch";
import { useEffect } from "react";
import { Popover, Whisper } from "rsuite";
// import "../globle.css"

const ComplateALM = ({ groupstep5, setGroupstep5, groupPerentstep, setGroupPerentstep, setShowmenu }) => {
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [risk_catogery, setRisk_catogery] = useState([])
    const [btnNameSubmit, setBtnNameSubmit] = useState("SAVE AND COMPLETE");

    const [bodyData, setBodyData] = useState({
        complianceDesicion: "",
    });
    const [mandatorydata, setMandatory] = useState({

    })





    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value })
        setMandatory({ ...mandatorydata, [name]: value })
    }

    const handleselect = (event, risk_catogery) => {
        setRisk_catogery(risk_catogery);
    }

    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)

    }
    function alertFunc() {
        navigate("/panel2/2" + "?token=8&step=5&substep=0");
        setShowmenu(1)
        setGroupPerentstep(5);

    }

    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);



    let logintime = LogTime();

    // console.log("logintime", logintime);
    useEffect(() => {
        if (logintime == false) {
            navigate("/");
            sessionStorage.removeItem("tokenSessionStorage");
        }
    }, []);
    async function handleclick(e) {

        e.preventDefault();
        setBtnNameSubmit(<Spinner animation="border" variant="danger" style={{ height: "22px", width: "22px" }} />);

        let resultt = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "NonIGA",
                    nonIGA: {
                        "passport": "31348675222",
                        "gender": "M",
                        "DOB": "10/10/2020",
                        "nationality": "PAK",
                        ...bodyData, riskCatogery: risk_catogery
                    }
                },
                consentMode: ""
            }
        })
        // console.log("resultt-----------", resultt)
        if (resultt.messageStatus == "OK") {
            setOpen2(true);
            setMessage(resultt.errorDescription)
            timeout = setTimeout(alertFunc, 2000);
            setBtnNameSubmit("SAVE AND COMPLETE");



        } else {
            setOpen(true);
            setMessage(resultt.errorDescription)
            setBtnNameSubmit("SAVE AND COMPLETE");

        }


    }








    const sempleoption = [
        { lable: 'Option 1', year: 1994 },
        { lable: 'Option 2', year: 1972 },
        { lable: 'Option 3', year: 1974 },
        { lable: 'Option 4', year: 2008 }
    ];

    const top100Films = [
        { title: 'Option 1', year: 1994 },
        { title: 'Option 2', year: 1972 },
        { title: 'Option 3', year: 1974 },
        { title: 'Option 4', year: 2008 }

    ];


    const thdata = [
        {
            id: "1",
            thname: "ID "
        },
        {
            id: "2",
            thname: "LE Name"
        },
        {
            id: "3",
            thname: "Hits"
        },
        {
            id: "4",
            thname: "Sourse"
        },
        {
            id: "5",
            thname: "Catogery"
        },
        {
            id: "6",
            thname: "Risk"
        },
        {
            id: "7",
            thname: "Assessments Status"
        },
        {
            id: "8",
            thname: "Last Updated Date"
        },
        {
            id: "9",
            thname: "Last Updated By"
        },

        ,
        {
            id: "10",
            thname: "Action"
        },
    ]

    const links = <Link className="text-decoration-none text-dark Fontsize13" style={{ cursor: "pointer" }} to="/panel2/assessments">Edit</Link>;


    const tddata = [
        {
            id: "1",
            tdname: "Capture residential address"
        },
        {
            id: "2",
            tdname: "New request"
        },
        {
            id: "3",
            tdname: "New request"
        },
        {
            id: "4",
            tdname: "Portal user"
        },
        {
            id: "5",
            tdname: "Ahmad al mdani"
        },
        {
            id: "6",
            tdname: "02/17/2020"
        },
        {
            id: "7",
            tdname: "1 min"
        },
        {
            id: "8",
            tdname: "in progress"
        },
        {
            id: "9",
            tdname: "System users"
        },

        {
            id: "10",
            tdname: <Tooltip style={{ cursor: "pointer" }} title={links} arrow><BsThreeDots />  </Tooltip>
        },

    ]
    const selectstyle = {
        backgroundColor: 'white',
        borderRadius: '0px'
    };




    const fieldsLists1 = [
        {
            title: "Sanction ",
            value: "0",
        }, {
            title: "PED ",
            value: "0",
        }, {
            title: "Adverse Media ",
            value: "0",
        }, {
            title: "Low Enforcements ",
            value: "0",
        }, {
            title: "Regulatory  Enforcements ",
            value: "0",
        }, {
            title: "State Owned/Linked ",
            value: "0",
        }, {
            title: "Other ",
            value: "0",
        },


    ];


    const fieldsLists2 = [
        {
            title: "Assessments Date ",
            value: "-",
        }, {
            title: "Risk Category",
            value: <Box >
                <Autocomplete
                    name="riskCatogery"
                    onChange={handleselect}
                    style={selectstyle}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={top100Films}
                    getOptionLabel={(option) => option.title}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small" {...params} placeholder="Select" />
                    )}
                />
            </Box>,
        }, {
            title: "Compliance Decision",
            value: <Box >
                <Form.Control style={{ borderRadius: "0px" }} name="complianceDesicion" value={bodyData.complianceDesicion} onChange={handlechange} as="textarea" rows={3} />
            </Box>
            ,
        }
    ];


    return (
        <>
            <form onSubmit={handleclick}>
                <Container fluid id="scroleble_main_content">








                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>



                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open2} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-success text-light" severity="success" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>

                    <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                        <AccordionItem >
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="h3 mt-4" as="div" flex='1' textAlign='left'>
                                        Complete ALM
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>

                                <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem >
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Hierarchy
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>

                                            <Row className="mt-2">
                                                <Col>
                                                    <Container className="border border-dark bg-light">
                                                        <Row className="p-1">
                                                            <Col sm={10} className="">
                                                                <Container className="" style={{ backgroundColor: "#d2d4d6" }}>
                                                                    <Row className="d-flex mt-1 pt-2">
                                                                        <Col md={3} sm={6} xs={12} className="mt-lg-0 mt-2">
                                                                            <Box >
                                                                                <Autocomplete
                                                                                    style={selectstyle}
                                                                                    size="small"
                                                                                    id="tags-outlined"
                                                                                    name="country_of_residence"
                                                                                    disableClearable={true}
                                                                                    options={sempleoption}
                                                                                    getOptionLabel={(option) => option.lable}
                                                                                    filterSelectedOptions
                                                                                    renderInput={(params) => (
                                                                                        <TextField size="small" {...params} placeholder="Select" />
                                                                                    )}
                                                                                />
                                                                            </Box>
                                                                        </Col>
                                                                        <Col md={3} sm={6} xs={12} className="mt-lg-0 mt-2">
                                                                            <Box >
                                                                                <Autocomplete
                                                                                    style={selectstyle}
                                                                                    size="small"
                                                                                    id="tags-outlined"
                                                                                    name="country_of_residence"
                                                                                    disableClearable={true}
                                                                                    options={sempleoption}
                                                                                    getOptionLabel={(option) => option.lable}
                                                                                    filterSelectedOptions
                                                                                    renderInput={(params) => (
                                                                                        <TextField size="small" {...params} placeholder="Select" />
                                                                                    )}
                                                                                />
                                                                            </Box>
                                                                        </Col>
                                                                        <Col md={3} sm={6} xs={12} className="mt-lg-0 mt-2">
                                                                            <Box >
                                                                                <Autocomplete
                                                                                    style={selectstyle}
                                                                                    size="small"
                                                                                    id="tags-outlined"
                                                                                    name="country_of_residence"
                                                                                    disableClearable={true}
                                                                                    options={sempleoption}
                                                                                    getOptionLabel={(option) => option.lable}
                                                                                    filterSelectedOptions
                                                                                    renderInput={(params) => (
                                                                                        <TextField size="small" {...params} placeholder="Select" />
                                                                                    )}
                                                                                />
                                                                            </Box>
                                                                        </Col>
                                                                        <Col md={3} sm={6} xs={12} className="mt-lg-0 mt-2">
                                                                            <Box >
                                                                                <Autocomplete
                                                                                    style={selectstyle}
                                                                                    size="small"
                                                                                    id="tags-outlined"
                                                                                    name="country_of_residence"
                                                                                    disableClearable={true}
                                                                                    options={sempleoption}
                                                                                    getOptionLabel={(option) => option.lable}
                                                                                    filterSelectedOptions
                                                                                    renderInput={(params) => (
                                                                                        <TextField size="small" {...params} placeholder="Select" />
                                                                                    )}
                                                                                />
                                                                            </Box>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mt-2 mb-1 pb-2">
                                                                        <Col className=""><Button className="bg-light text-dark border-secondary me-2" style={{ borderRadius: "1px" }}>REP</Button>
                                                                            <Button className="bg-light text-dark border-secondary" style={{ borderRadius: "1px" }}>High Risk</Button></Col>
                                                                    </Row>
                                                                </Container>
                                                            </Col>
                                                            <Col sm={2} className=" d-flex align-items-center text-center">
                                                                <Container className=" ">
                                                                    <Row className=" ">
                                                                        <Col xs={3} sm={12} md={6} lg={3} ><BiMenu size={20} /></Col>
                                                                        <Col xs={3} sm={12} md={6} lg={3}><BiMenu size={20} /></Col>
                                                                        <Col xs={3} sm={12} md={6} lg={3}><BiMenu size={20} /></Col>
                                                                        <Col xs={3} sm={12} md={6} lg={3}><BiMenu size={20} /></Col>
                                                                    </Row>
                                                                </Container>
                                                            </Col>

                                                        </Row>
                                                        <Row className="border-top border-dark" style={{ height: "100vh", overflowY: "scroll" }}>
                                                            <Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><BsFillHexagonFill color="green" /></Col>
                                                        </Row>
                                                    </Container>
                                                </Col>
                                            </Row>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Assessments
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>

                                            <Row className="mt-2">
                                                <Col className=" ">
                                                    <Table responsive className="table table-striped border  border-secondary">
                                                        <thead className="">
                                                            <tr>
                                                                {
                                                                    thdata.map((th) => {
                                                                        return (
                                                                            <>
                                                                                <th className="Fontsize13">{th.thname}</th>

                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr>
                                                                {
                                                                    tddata.map((td) => {
                                                                        return (
                                                                            <>
                                                                                <td>{td.tdname}</td>

                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>

                                                        </tbody>
                                                    </Table>

                                                </Col>
                                            </Row>

                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Documents
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>


                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Comments
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>


                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Confirm Matches
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>

                                            {
                                                fieldsLists1.map((field) =>
                                                    <Row className="mt-3">
                                                        <Col sm={3} xs={12} className="text-md-end text-start text-danger">{field?.title} </Col>
                                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                                            {field?.value}
                                                        </Col>
                                                        <Col xs={0} sm={2} xl={3}></Col>
                                                    </Row>
                                                )
                                            }
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                                    <AccordionItem>
                                        <Row className="border-bottom border-dark">
                                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                    Assessments Disicion
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Row>
                                        <AccordionPanel pb={4}>
                                            {
                                                fieldsLists2.map((field) =>
                                                    <Row className="mt-3">
                                                        <Col sm={3} xs={12} className="text-md-end text-start text-danger">{field?.title} </Col>
                                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                                            {field?.value}
                                                        </Col>
                                                        <Col xs={0} sm={2} xl={3}></Col>
                                                    </Row>
                                                )
                                            }

                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                <Row className=" mt-5  mb-5">
                                    <Col md={4} lg={5} xl={6} xs={0} className=""></Col>
                                    <Col md={8} lg={7} xl={6} xs={12} className=" ">
                                        <Container>
                                            <Row>
                                                <Col md={1}></Col>
                                                <Col md={5} sm={6} className=" text-end">
                                                    <Button className="ps-4 pe-4 btn-secondary buttonstyle">SAVE FOR LATER</Button>
                                                </Col>
                                                <Col md={6} sm={6} className=" text-end mt-sm-0 mt-2"> <Button
                                                    type="submit"
                                                    className="ps-4 pe-4 btn-secondary buttonstyle">
                                                    {btnNameSubmit}
                                                </Button></Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>

                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>






                </Container>
            </form>
        </>
    )
}
export default ComplateALM;