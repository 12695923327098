import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Dropdown, Row, Spinner } from "react-bootstrap";
import { BsChevronDown, BsChevronLeft, BsPencil } from "react-icons/bs";
import { Alert, Autocomplete, Box, Snackbar, TextField, Avatar, Tooltip } from "@mui/material";
import { BsChevronRight, BsThreeDots } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { MDBPagination, MDBPaginationItem, MDBPaginationLink } from "mdb-react-ui-kit";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, useDisclosure, Collapse } from "@chakra-ui/react";
import PropTypes from 'prop-types';
import { BiPencil } from "react-icons/bi";
import { Apicall, LogTime } from "../../Api/UseFeitch";
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';












const WorldCheckOne = ({ data, setData }) => {
    const links = <Link className="text-decoration-none text-dark Fontsize13" style={{ cursor: "pointer" }} >Edit</Link>;
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [screening_risk, setScreening_risk] = useState([])
    const [btnNameSubmit, setBtnNameSubmit] = useState("SAVE AND COMPLETE");
    const [showToggleIcon, setShowToggleIcon] = React.useState(false);
    const [icon, setIcon] = useState(false);
    const { isOpen, onToggle } = useDisclosure();

    const [bodyData, setBodyData] = useState({
        screeningNotes: "",

    });


    const iconclick = () => {
        setIcon(0);
    }


    const handleToggleIcon = () => {
        onToggle();
        setIcon(!icon);
        setShowToggleIcon(!showToggleIcon);
    }

    useEffect(() => {
        // console.log(showToggleIcon)
    });


    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value })
    }
    const handleselect = (event, screening_risk) => {
        setScreening_risk(screening_risk);
    }



    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)

    }
    function alertFunc() {
        navigate("/panel2/assessments");

    }

    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);
    let logintime = LogTime();

    // console.log("logintime", logintime);
    useEffect(() => {
        if (logintime === false) {
            navigate("/");
            sessionStorage.removeItem("tokenSessionStorage");
        }
    }, []);

    async function handleclick(e) {

        e.preventDefault();
        setBtnNameSubmit(<Spinner animation="border" variant="danger" style={{ height: "22px", width: "22px" }} />);

        let resultt = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "NonIGA",
                    nonIGA: {
                        "passport": "31348675222",
                        "gender": "M",
                        "DOB": "10/10/2020",
                        "nationality": "PAK",
                        ...bodyData, screeningRisk: screening_risk
                    }
                },
                consentMode: ""
            }
        })

        // console.log("resultt-----------", resultt)
        if (resultt.messageStatus == "OK") {
            setOpen2(true);
            setMessage(resultt.errorDescription)
            timeout = setTimeout(alertFunc, 1000);
            setBtnNameSubmit("SAVE AND COMPLETE");

        } else {
            setOpen(true);
            setMessage(resultt.errorDescription);
            setBtnNameSubmit("SAVE AND COMPLETE");

        }

    }

















    const Screening = [
        { lable: 'POLITICAL - INDIVIDUAL', id: 1 },
        { lable: 'INDIVIDUAL, DIPLOMAT', id: 2 },
        { lable: 'TERRORISM, CRIME - NARCOTICS', id: 3 },
        { lable: 'CRIME - ORGANIZED', id: 4 },
        { lable: 'CRIME - OTHER', id: 5 },
        { lable: 'CRIME - NARCOTICS', id: 6 },
        { lable: 'CRIME - FINANCIAL', id: 7 },

    ];
    const clickEdit = () => {
        alert("yes ! clicked")

    }

    const taskth = [
        {
            id: "1",
            thname: "Task ID "
        },
        {
            id: "2",
            thname: "Task Name"
        },
        {
            id: "3",
            thname: "Screening Id"
        },
        {
            id: "4",
            thname: "Screening Provider"
        },
        {
            id: "5",
            thname: "Assigned Team"
        },
        {
            id: "6",
            thname: "Assigned User"
        },
        {
            id: "7",
            thname: " Task Duration"
        },
        {
            id: "8",
            thname: "Status"
        },
        {
            id: "9",
            thname: "Last Updated By"
        },
        {
            id: "10",
            thname: " Ready To Complete"
        },
    ]

    const tasktd = [
        {
            id: "1",
            thname: "4417 "
        },
        {
            id: "2",
            thname: "Match Aprover World Check One Screening ped"
        },
        {
            id: "3",
            thname: "World Check One Screening"
        },
        {
            id: "4",
            thname: "Compliance"
        },
        {
            id: "5",
            thname: "3 min"
        },
        {
            id: "6",
            thname: "Compliance"
        },
        {
            id: "7",
            thname: " 3 min"
        },
        {
            id: "8",
            thname: "Status"
        },
        {
            id: "9",
            thname: "Faisal Ali"
        },
        {
            id: "10",
            thname: " Ready To Complete"
        },
    ]

    const tabledata1 = [
        {
            id: "1",
            td: <p className="mt-1">id </p>
        },
        {
            id: "2",
            td: "Match Name"
        }, {
            id: "3",
            td: "Match"
        }, {
            id: "4",
            td: "Match"
        }, {
            id: "5",
            td: "Match Status"
        }, {
            id: "5",
            td: "Resolution"
        }, {
            id: "5",
            td: "Resolution"
        }, {
            id: "5",
            td: "Resolution"
        }, {
            id: "5",
            td: "Material"
        },

    ]
    const tabledata2 = [
        {
            id: "1",
            td: "Catogery"
        },
        {
            id: "2",
            td: "Reason "
        }, {
            id: "3",
            td: "Catogery ped"
        }, {
            id: "4",
            td: "Strength"
        }, {
            id: "5",
            td: "Resolve"
        }, {
            id: "5",
            td: "Status"
        }, {
            id: "5",
            td: "Reason"
        }, {
            id: "5",
            td: "Risk "
        }, {
            id: "5",
            td: "Match"
        },
    ]


    const selectstyle = {
        backgroundColor: 'white',
        borderRadius: '0px'
    };

    return (
        <>
            <form onSubmit={handleclick}>
                <Container fluid id="scroleble_main_content">


                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>



                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open2} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-success text-light" severity="success" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>

                    <Row className="h3 fw-light mt-4"><Col>World Check One</Col> </Row>

                    <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Entity Under Assessments
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>


                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>



                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        World-Check-One Screening Summary
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>

                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Status </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        In progress
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">LE Name
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        MAHA ALSAEI
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Match Count
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        21
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Screening Date
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        02/17/2020 10:37 AM
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">External System Id
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        518v5uxt52191eertmoa9japf
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Case Id </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        518v5uxt52191eertmoa9japf                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Case Screening State
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        ONGOING
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">On-Going Screening

                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        Active
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Case Screening State
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        ONGOING
                                    </Col>
                                </Row>

                                <Row className="h5 mt-2 ms-md-3 ms-0"><Col>Sanctions</Col> </Row>



                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        -
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Confirm Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        -
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} sm={4} className="text-sm-end text-start text-danger">Sanctions Status
                                    </Col>
                                    <Col xs={6} sm={2} className="fw-bold ">
                                        -
                                    </Col>
                                    <Col xs={6} sm={6}><BsPencil onClick={clickEdit} style={{ cursor: "pointer" }} size={20} /></Col>
                                </Row>





                                <Row className="h5 mt-2 ms-0 ms-md-3"><Col>PED</Col> </Row>



                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        21
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Confirm Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        -
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} sm={4} className="text-sm-end text-start text-danger">PED Status
                                    </Col>
                                    <Col xs={6} sm={2} className="fw-bold ">
                                        No Match
                                    </Col>
                                    <Col xs={6} ><BsPencil onClick={clickEdit} style={{ cursor: "pointer" }} size={20} /></Col>
                                </Row>




                                <Row className="h5 mt-2 ms-0 ms-md-3"><Col>Adverse Media</Col> </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        21
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Confirm Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        -
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} sm={4} className="text-sm-end text-start text-danger">Adverse Media Status
                                    </Col>
                                    <Col xs={6} sm={2} className="fw-bold ">
                                        -
                                    </Col>
                                    <Col xs={6}><BsPencil onClick={clickEdit} style={{ cursor: "pointer" }} size={20} /></Col>
                                </Row>





                                <Row className="h5 mt-2 ms-0 ms-md-3"><Col>Low Inforcements</Col> </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        21

                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Confirm Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        -

                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} sm={4} className="text-sm-end text-start text-danger">Low Inforcements Status
                                    </Col>
                                    <Col xs={6} sm={2} className="fw-bold ">
                                        -
                                    </Col>
                                    <Col xs={6}><BsPencil onClick={clickEdit} style={{ cursor: "pointer" }} size={20} /></Col>
                                </Row>






                                <Row className="h5 mt-2 ms-0 ms-md-3"><Col>Regulatory Inforcements</Col> </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        21

                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Confirm Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        -

                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} sm={4} className="text-sm-end text-start text-danger">Regulatory Enforcements  Status
                                    </Col>
                                    <Col xs={6} sm={2} className="fw-bold ">
                                        -
                                    </Col>
                                    <Col xs={6}><BsPencil onClick={clickEdit} style={{ cursor: "pointer" }} size={20} /></Col>
                                </Row>



                                <Row className="h5 mt-2 ms-0 ms-md-3"><Col>State Owned/Linked</Col> </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        21

                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Confirm Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        -

                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} sm={4} className="text-sm-end text-start text-danger">State Owned/Linked Status
                                    </Col>
                                    <Col xs={6} sm={2} className="fw-bold ">
                                        -
                                    </Col>
                                    <Col xs={6}><BsPencil onClick={clickEdit} style={{ cursor: "pointer" }} size={20} /></Col>
                                </Row>




                                <Row className="h5 mt-2 ms-0 ms-md-3"><Col>Other</Col> </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        21

                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={4} xs={12} className="text-sm-end text-start text-danger">Confirm Matches
                                    </Col>
                                    <Col sm={8} xs={12} className="fw-bold ">
                                        -

                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} sm={4} className="text-sm-end text-start text-danger">Other Status
                                    </Col>
                                    <Col xs={6} sm={2} className="fw-bold ">
                                        -
                                    </Col>
                                    <Col xs={6}><BsPencil onClick={clickEdit} style={{ cursor: "pointer" }} size={20} /></Col>
                                </Row>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>


                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Screening Desicion
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>
                                <Row className="mt-3">
                                    <Col sm={3} xs={12} className="text-sm-end text-start text-danger">Screening Risk<span className=" pt-1 fw-bold text-danger "> &nbsp;*</span> </Col>
                                    <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                        <Box >
                                            <Autocomplete
                                                name="screeningRisk"
                                                onChange={handleselect}
                                                style={selectstyle}
                                                size="small"
                                                multiple
                                                id="tags-outlined"
                                                options={Screening}
                                                getOptionLabel={(option) => option.lable}
                                                filterSelectedOptions
                                                renderInput={(params) => (
                                                    <TextField size="small" {...params} placeholder="Select" />
                                                )}
                                            />
                                        </Box>
                                    </Col>
                                    <Col xs={0} sm={2} xl={3}></Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={3} xs={12} className="text-sm-end text-start text-danger">Screening Notes </Col>
                                    <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                        <Box >
                                            <Form.Control style={{ borderRadius: "0px" }} name="screeningNotes" value={bodyData.screeningNotes} onChange={handlechange} as="textarea" rows={3} />
                                        </Box>
                                    </Col>
                                    <Col xs={0} sm={2} xl={3}></Col>
                                </Row>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                    {/* 
                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Screening Matches
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel > */}
                    {/* <Row className=" bg-light ms-4 me-3" style={{ border: "1px solid black", borderTop: "none" }}>
                                    <Col xs={1} className=" d-flex align-items-center  justify-content-center"><BsChevronRight size={20} /></Col>
                                    <Col xs={10} >
                                        <Container className="p-0">
                                            <Row >
                                                <Table className="p-0" responsive>

                                                    <tbody>
                                                        <tr >
                                                            {
                                                                tabledata1.map((tabledata) => {
                                                                    return (
                                                                        <>
                                                                            <td className="p-0">{tabledata.td}</td>

                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </tr>
                                                        <tr >
                                                            {
                                                                tabledata2.map((tabledata) => {
                                                                    return (
                                                                        <>
                                                                            <td className="p-0">{tabledata.td}</td>

                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Row>
                                        </Container>

                                    </Col>
                                    <Col xs={1} className="d-flex align-items-center  justify-content-center"><Tooltip title={links} arrow><BsThreeDots />  </Tooltip></Col>
                                </Row> */}

                    <Box className="border-bottom border-dark fw-bold" as="div" flex='1' textAlign='left'>
                        Screening Matches
                    </Box>
                    <Row className=" mt-2 ms-md-4 ms-0 me-md-3 me-0 " style={{ backgroundColor: 'white' }}>
                        <Col sm={1} xs={2} className="p-0 m-0 d-flex align-items-center  justify-content-center" onClick={iconclick}>
                            {showToggleIcon ? <BsChevronDown size={20} onClick={handleToggleIcon} style={{ cursor: 'pointer' }} /> : < BsChevronRight size={20} onClick={handleToggleIcon} style={{ cursor: 'pointer' }} />}

                        </Col>
                        <Col xs={8} sm={10} className="p-0">
                            <Container className="p-0 m-0" fluid>
                                <Row className="">
                                    <TableContainer component={Paper} sx={{ boxShadow: 'none', backgroundColor: 'white' }}>
                                        <Table size="small" border aria-label="a dense table">
                                            <TableHead>
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { padding: 0 } }}>
                                                    {
                                                        tabledata1.map((tabledata) => {
                                                            return (
                                                                <>
                                                                    <TableCell sx={{ padding: 0 }} >{tabledata.td}</TableCell>

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                <TableRow sx={{ '&:last-child td, &:last-child th': { padding: 0 } }}   >
                                                    {
                                                        tabledata2.map((tabledata) => {
                                                            return (
                                                                <>
                                                                    <TableCell sx={{ padding: 0 }}>{tabledata.td}</TableCell>


                                                                </>
                                                            )
                                                        })
                                                    }
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Row>


                            </Container>

                        </Col>
                        <Col xs={2} sm={1} className=" d-flex align-items-center  justify-content-center ">
                            <Dropdown>
                                <Avatar sx={{ bgcolor: "white", width: "30px", height: "30px" }} >
                                    <Dropdown.Toggle
                                        variant="secondery" style={{ color: "white", marginRight: "32px" }} >
                                        <BsThreeDots size={20} color="black" id="fabell" style={{ position: "relative", left: "16px" }} />
                                    </Dropdown.Toggle>
                                </Avatar>

                                <Dropdown.Menu className="word-check-one-dropdown">
                                    <Dropdown.Item className="" >

                                        <Container className="" >
                                            <Row className="text-center pt-1 pb-2">
                                                <Col>
                                                    <Link className=" text-dark " to="/panel2/documents">
                                                        Attach Document <br />Request Via Email <br />View Request Details
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Dropdown.Item>
                                </Dropdown.Menu>

                            </Dropdown>




                        </Col>
                    </Row>
                    <Row className=" bg-light ms-md-4 ms-0 me-md-3 me-0">
                        <Collapse in={isOpen} animateOpacity>
                            <Box >
                                This document and ancillary supporting information is the propriety of The
                                BENEFIT Company of Bahrain and should not be copied, used or related to any
                                third party without the written permission of The BENEFIT Company or the Central
                                Bank of Bahrain1. Specified the fields for mandatory, nullable, conditional and
                                optional in each API: getCurrentKYCData,
                                recordConsentAndFetchSourceData, screenCase, resolveCase,
                                publishSignedCase, Upload API, Login API
                                2. Removing the statement “It will require a separate call for
                                fetching the token using the login API from the document server” as
                                there is one consolidate login API.
                                3. Changed Upload API file field to accept base64 format.
                                4. Timestamp is now specified to the standard UTC Format.
                                5. Specified the fields for mandatory, nullable, conditional and
                                optional for the Documents.
                                12th August 2020 0.7 1. Sample request and response of the APIs are corrected to display
                                the description instead of the code.
                                2. BAH code is corrected to BHR. Refer to 6.13 GCC Country Codes
                                Lookup.
                                3. monthlyTurnOver, documentSource, documentDirection,
                                documentType fields are corrected to String type.
                                4. residentPermitExpiry field is corrected from long to DateTime
                                type.
                                5. Specified method to be used for Upload API
                                6. Specified file size and supported file extensions for Upload API
                                7. Removed policies field from the Upload API request

                                This document and ancillary supporting information is the propriety of The
                                BENEFIT Company of Bahrain and should not be copied, used or related to any
                                third party without the written permission of The BENEFIT Company or the Central
                                Bank of Bahrain1. Specified the fields for mandatory, nullable, conditional and
                                optional in each API: getCurrentKYCData,
                                recordConsentAndFetchSourceData, screenCase, resolveCase,
                                publishSignedCase, Upload API, Login API
                                2. Removing the statement “It will require a separate call for
                                fetching the token using the login API from the document server” as
                                there is one consolidate login API.
                                3. Changed Upload API file field to accept base64 format.
                                4. Timestamp is now specified to the standard UTC Format.
                                5. Specified the fields for mandatory, nullable, conditional and
                                optional for the Documents.
                                12th August 2020 0.7 1. Sample request and response of the APIs are corrected to display
                                the description instead of the code.
                                2. BAH code is corrected to BHR. Refer to 6.13 GCC Country Codes
                                Lookup.
                                3. monthlyTurnOver, documentSource, documentDirection,
                                documentType fields are corrected to String type.
                                4. residentPermitExpiry field is corrected from long to DateTime
                                type.
                                5. Specified method to be used for Upload API
                                6. Specified file size and supported file extensions for Upload API
                                7. Removed policies field from the Upload API request
                            </Box>
                        </Collapse>
                    </Row>



                    <Row className="mt-3">
                        <Col>

                            <nav aria-label='Page navigation example'>
                                <MDBPagination center className='mb-0'>
                                    <MDBPaginationItem >
                                        <MDBPaginationLink id="paginationPrevious" href='#' aria-label='Previous'>
                                            <span aria-hidden='true'><BsChevronLeft /> Previous</span>
                                        </MDBPaginationLink>
                                    </MDBPaginationItem>
                                    <MDBPaginationItem id="paginationItem">
                                        <MDBPaginationLink href='#'>1</MDBPaginationLink>
                                    </MDBPaginationItem>
                                    <MDBPaginationItem>
                                        <MDBPaginationLink href='#'>2</MDBPaginationLink>
                                    </MDBPaginationItem>
                                    <MDBPaginationItem>
                                        <MDBPaginationLink href='#'>3</MDBPaginationLink>
                                    </MDBPaginationItem>
                                    <MDBPaginationItem>
                                        <MDBPaginationLink id="paginationPrevious" href='#' aria-label='Next'>
                                            <span aria-hidden='true'>Next<BsChevronRight /></span>
                                        </MDBPaginationLink>
                                    </MDBPaginationItem>
                                </MDBPagination>
                            </nav>

                        </Col>
                    </Row>


                    {/* </AccordionPanel>
                        </AccordionItem>
                    </Accordion> */}

















                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Screening Tasks
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>

                                <Row className="ms-2 me-1 mt-2">
                                    <Col className="table-responsive">
                                        <table class="table table-striped border-dark border border-start border-end" responsive>
                                            <thead>
                                         <tr>{taskth.map((th) => { return (<> <th className="Fontsize13">{th.thname}</th> </>)})}</tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {
                                                        tasktd.map((th) => {
                                                            return (
                                                                <>
                                                                    <td className="Fontsize13">{th.thname}</td>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    {
                                                        tasktd.map((th) => {
                                                            return (
                                                                <>
                                                                    <td className="Fontsize13">{th.thname}</td>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </tr>


                                            </tbody>
                                        </table>

                                    </Col>
                                </Row>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>



                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Document
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>


                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>



                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Comments
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>


                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>


                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        History
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>


                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>



                    <Row className=" mt-5  mb-5">
                        <Col xs={0} sm={0} md={2} lg={3} xl={5} className=""></Col>
                        <Col xs={12} sm={12} md={10} lg={9} xl={7} className=" ">
                            <Container>
                                <Row>
                                    <Col md={2} sm={3} className="text-end"><Button className="me-3 ps-4 pe-4 btn-secondary buttonstyle">CANCEL</Button></Col>
                                    <Col md={5} sm={4} className=" text-end mt-sm-0 mt-2">
                                        <Button className="me-3 ps-4 pe-4 btn-secondary buttonstyle">SAVE FOR LATER</Button>
                                    </Col>
                                    <Col md={5} sm={5} className=" text-end mt-sm-0 mt-2">
                                        <Button
                                            type="submit"
                                            className="ps-md-4 pe-md-4 ps-2 pe-2 btn-secondary buttonstyle ">

                                            {btnNameSubmit}

                                        </Button></Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </form>
        </>
    )
}
export default WorldCheckOne;