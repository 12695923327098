import { Autocomplete, Avatar, Box, InputAdornment, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { BsArrowDownShort, BsChevronDown, BsChevronRight, BsThreeDots } from "react-icons/bs";
import { FaSort } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Apicall, LogTime } from "../../Api/UseFeitch";
import { Alert, Snackbar } from "@mui/material";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Collapse, useDisclosure } from "@chakra-ui/react";
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';



const KycDocumentRequirement = ({ groupstep5, setGroupstep5, setShowmenu }) => {
    const navigate = useNavigate();
    const [icon, setIcon] = useState(false);

    const [message, setMessage] = useState();
    const [bulk_action, setBulk_action] = useState([])
    const [btnNameSubmit, setBtnNameSubmit] = useState("SAVE AND COMPLETE");
    const { isOpen, onToggle } = useDisclosure();
    const [show, setShow] = React.useState(false);
    const [showToggleIcon, setShowToggleIcon] = React.useState(false);


    const iconclick = () => {
        setIcon(0);
    }


    const handleToggleIcon = () => {
        onToggle();
        setIcon(!icon);
        setShowToggleIcon(!showToggleIcon);
    }

    useEffect(() => {
        // console.log(showToggleIcon)
    });

    const handleselect = (event, bulk_action) => {
        setBulk_action(bulk_action);
    }


    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)

    }
    function alertFunc() {
        navigate("/panel2/2" + "?token=8&step=4&substep=1");
        setShowmenu(1)
    }

    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);

    let logintime = LogTime();

    // console.log("logintime", logintime);
    useEffect(() => {
        if (logintime == false) {
            navigate("/");
            sessionStorage.removeItem("tokenSessionStorage");
        }
    }, []);


    async function handleclick(e) {

        e.preventDefault();
        setBtnNameSubmit(<Spinner animation="border" variant="danger" style={{ height: "22px", width: "22px" }} />);

        let resultt = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "NonIGA",
                    nonIGA: {
                        "passport": "31348675222",
                        "gender": "M",
                        "DOB": "10/10/2020",
                        "nationality": "PAK",
                        bulkAction: bulk_action
                    }
                },
                consentMode: ""
            }
        })

        // console.log("resultt-----------", resultt)
        if (resultt.messageStatus == "OK") {
            setOpen2(true);
            setMessage(resultt.errorDescription)
            timeout = setTimeout(alertFunc, 1000);
            setBtnNameSubmit("SAVE AND COMPLETE");

        } else {
            setOpen(true);
            setMessage(resultt.errorDescription);
            setBtnNameSubmit("SAVE AND COMPLETE");

        }

    }


    const links = <Link className=" text-dark " to="/panel2/documents">
        Attach Document Request Via Email View Request Details
    </Link>



 
    const top100Films = [
        { title: 'Option 1', year: 1994 },
        { title: 'Option 2', year: 1972 },
        { title: 'Option 3', year: 1974 },
        { title: 'Option 4', year: 2008 }
    ];
    
    const tabledata1 = [
        {
            id: "1",
            td: "Requirement"
        },
        {
            id: "2",
            td: "Document"
        }, {
            id: "3",
            td: "Status"
        }, {
            id: "4",
            td: "Due"
        }, {
            id: "5",
            td: "Cash"
        },
    ]
    const tabledata2 = [
        {
            id: "1",
            td: <p className="text-danger pb-0">e KYC Customer Consent </p>
        },
        {
            id: "2",
            td: <b>0 suggested 0 likes</b>
        }, {
            id: "3",
            td: <b>Pending</b>
        }, {
            id: "4",
            td: <b>02/17/2023</b>
        }, {
            id: "5",
            td: <b>Cleint Onboarding</b>
        },
    ]


    const selectstyle = {
        backgroundColor: 'white',
        borderRadius: '0px'
    };

    return (
        <>
            <Container fluid id="scroleble_main_content">

                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>



                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open2} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-success text-light" severity="success" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
                <Row className="h3 fw-light mt-4 ms-3"><Col>KYC Documents Requirements</Col> </Row>


                <Row className="mt-4">
                    <Col xs={12} sm={4} className="text-sm-end  fw-bold pt-1"> Bulk Action  </Col>
                    <Col xs={6} sm={4} className="fw-bold ">
                        <Box >
                            <Autocomplete
                                style={selectstyle}
                                name="bulkAction"
                                onChange={handleselect}
                                size="small"
                                multiple
                                id="tags-outlined"
                                options={top100Films}
                                getOptionLabel={(option) => option.title}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField size="small" {...params} placeholder="Select" />
                                )}
                            />
                        </Box>

                    </Col>
                    <Col sm={4} xs={6} >
                        <Button className="ps-5  pe-5 bg-light text-dark border-dark">Submit</Button>
                    </Col>
                </Row>
                <Row className="ms-md-2 ms-0  me-md-1 me-0 mt-4">
                    <InputGroup>
                        <InputGroup.Text id="basic-addon"><BiSearch size={25} color="red" /></InputGroup.Text>
                        <Form.Control                                                                           
                            placeholder="Username"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                        <InputGroup.Text id="basic-addon1" ><FaSort size={20} color="red" />sort by due  <BsArrowDownShort size={25} /></InputGroup.Text>
                    </InputGroup>
                </Row>
                <Row className=" mt-2 ms-md-4 ms-0 me-md-3 me-0" style={{ backgroundColor: 'white' }}>
                    <Col sm={1} xs={2} className="p-0 m-0 d-flex align-items-center  justify-content-center" onClick={iconclick}>
                        {showToggleIcon ? <BsChevronDown size={20} onClick={handleToggleIcon} style={{ cursor: 'pointer' }} /> : < BsChevronRight size={20} onClick={handleToggleIcon} style={{ cursor: 'pointer' }} />}

                    </Col>
                    <Col xs={8} sm={10} className="p-0">
                        <Container className="p-0 m-0" fluid>
                            <Row className="">
                                <TableContainer component={Paper} sx={{ boxShadow: 'none', backgroundColor: 'white' }}>
                                    <Table size="small" border aria-label="a dense table">
                                        <TableHead>
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { padding: 0 } }}>
                                                {
                                                    tabledata1.map((tabledata) => {
                                                        return (
                                                            <>
                                                                <TableCell sx={{ padding: 0 }} >{tabledata.td}</TableCell>

                                                            </>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow sx={{ '&:last-child td, &:last-child th': { padding: 0 } }}   >
                                                {
                                                    tabledata2.map((tabledata) => {
                                                        return (
                                                            <>
                                                                <TableCell sx={{ padding: 0 }}>{tabledata.td}</TableCell>


                                                            </>
                                                        )
                                                    })
                                                }
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>



                            </Row>


                        </Container>

                    </Col>
                    <Col xs={2} sm={1} className="d-flex align-items-center  justify-content-center ">
                        <Dropdown>
                            <Avatar sx={{ bgcolor: "white", width: "30px", height: "30px" }} >
                                <Dropdown.Toggle
                                    variant="secondery" style={{ color: "white", marginRight: "32px" }} >
                                    <BsThreeDots size={20} color="black" id="fabell" style={{ position: "relative", left: "16px" }} />
                                </Dropdown.Toggle>
                            </Avatar>
                            <div className="" >
                                <Dropdown.Menu className="kyc-dropdown"  >
                                    <Dropdown.Item className="" >

                                        <Container className="" >
                                            <Row className={"text-center pt-1 pb-2"}>
                                                <Col>
                                                    <Link className=" text-dark " to="/panel2/documents">
                                                        Attach Document <br />Request Via Email <br />View Request Details
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>




                    </Col>
                </Row>
                <Row className=" bg-light ms-md-4 ms-0 me-md-3 me-0">
                    <Collapse in={isOpen} animateOpacity>
                        <Box >
                            This document and ancillary supporting information is the propriety of The
                            BENEFIT Company of Bahrain and should not be copied, used or related to any
                            third party without the written permission of The BENEFIT Company or the Central
                            Bank of Bahrain1. Specified the fields for mandatory, nullable, conditional and
                            optional in each API: getCurrentKYCData,
                            recordConsentAndFetchSourceData, screenCase, resolveCase,
                            publishSignedCase, Upload API, Login API
                            2. Removing the statement “It will require a separate call for
                            fetching the token using the login API from the document server” as
                            there is one consolidate login API.
                            3. Changed Upload API file field to accept base64 format.
                            4. Timestamp is now specified to the standard UTC Format.
                            5. Specified the fields for mandatory, nullable, conditional and
                            optional for the Documents.
                            12th August 2020 0.7 1. Sample request and response of the APIs are corrected to display
                            the description instead of the code.
                            2. BAH code is corrected to BHR. Refer to 6.13 GCC Country Codes
                            Lookup.
                            3. monthlyTurnOver, documentSource, documentDirection,
                            documentType fields are corrected to String type.
                            4. residentPermitExpiry field is corrected from long to DateTime
                            type.
                            5. Specified method to be used for Upload API
                            6. Specified file size and supported file extensions for Upload API
                            7. Removed policies field from the Upload API request

                            This document and ancillary supporting information is the propriety of The
                            BENEFIT Company of Bahrain and should not be copied, used or related to any
                            third party without the written permission of The BENEFIT Company or the Central
                            Bank of Bahrain1. Specified the fields for mandatory, nullable, conditional and
                            optional in each API: getCurrentKYCData,
                            recordConsentAndFetchSourceData, screenCase, resolveCase,
                            publishSignedCase, Upload API, Login API
                            2. Removing the statement “It will require a separate call for
                            fetching the token using the login API from the document server” as
                            there is one consolidate login API.
                            3. Changed Upload API file field to accept base64 format.
                            4. Timestamp is now specified to the standard UTC Format.
                            5. Specified the fields for mandatory, nullable, conditional and
                            optional for the Documents.
                            12th August 2020 0.7 1. Sample request and response of the APIs are corrected to display
                            the description instead of the code.
                            2. BAH code is corrected to BHR. Refer to 6.13 GCC Country Codes
                            Lookup.
                            3. monthlyTurnOver, documentSource, documentDirection,
                            documentType fields are corrected to String type.
                            4. residentPermitExpiry field is corrected from long to DateTime
                            type.
                            5. Specified method to be used for Upload API
                            6. Specified file size and supported file extensions for Upload API
                            7. Removed policies field from the Upload API request
                        </Box>
                    </Collapse>
                </Row>
                <Row className="h5 mt-5 text-end mb-5">
                    <Col className="me-4">
                        <Button
                            onClick={handleclick}
                            className="ps-4 pe-4 btn-secondary buttonstyle">
                            {btnNameSubmit}
                        </Button>
                    </Col>
                </Row>



            </Container>
        </>
    )
}
export default KycDocumentRequirement;