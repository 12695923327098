import React, {useEffect, useState} from "react";
import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import logo from "../images/Fwd_/bank.jpg";
import {FcCopyright} from "react-icons/fc";
import {BsThreeDots} from "react-icons/bs";
import {Sidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import {Avatar, Box, Paper, Step, StepContent, StepLabel, Stepper, Typography} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import clientonboarding from "../images/Cleint Onboarding.png";


const NewGroupSidebar = ({menuBorder, showmenu, showlogo}) => {

    const [searchParams] = useSearchParams();
    return (
        <>
            <Container fluid className="text-light">
                <Row style={{borderBottom: "3px solid blue", backgroundColor: "var(--primary)", height: "50px"}}
                     className={showlogo ? 'd-none' : ' d-none d-lg-block'}>
                    <Col>
                        <img className="img-fluid w-50 p-1" src={logo} alt="kyc-logo"/>
                    </Col>
                </Row>

                <Row id="sidebarscroll">
                    <Col>
                        <Container fluid>

                            <Row>
                                {/* <Col className="mb-3"><FcCopyright size={20} /> Cleint Onboarding</Col> */}
                                <Col className="my-3">
                                    <img src={clientonboarding} className="img-fluid" style={{width: '20px'}}/> Client
                                    On-boarding
                                </Col>

                            </Row>
                            <Row className="mb-2">
                                <Col style={{fontSize: "12px"}} className="fw-light ms-2 Fontsize12 ">MAHA ALSAEI</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Container>
                                        <Row className="Fontsize12 Bordertop Borderbottom pt-1 pb-2 ">
                                            {/*<Col>RISK: </Col>*/}
                                            <Col className="d-flex"><b>CASE ID:</b> {searchParams.get('caseid') || ''}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                            {/*<Row className="text-center ps-3 pe-3 mt-3">*/}
                            {/*    <Button id="newGbtn" className="Fontsize12 d-flex">ACTIONS <BsThreeDots*/}
                            {/*        className="ms-auto" color="white" size={20}/></Button>*/}
                            {/*</Row>*/}
                            <Row className={showmenu == 1 ? "d-block" : "d-none"}>
                                <Col className="">

                                    <Menu className="">
                                        <SubMenu defaultOpen label="Case Details">
                                            <MenuItem className="fw-bold"> Case Status </MenuItem>
                                            <MenuItem> Tasks </MenuItem>
                                            <MenuItem> Tasks Assignments</MenuItem>
                                            <MenuItem> Comments </MenuItem>
                                            <MenuItem className="pb-5"> Additional Information </MenuItem>
                                        </SubMenu>
                                        <MenuItem className="Navbg"> Documentat Requirements </MenuItem>

                                        <SubMenu label="Documentat">
                                            <MenuItem> Pie charts </MenuItem>
                                            <MenuItem className="pb-5"> Line charts </MenuItem>
                                        </SubMenu>
                                        <MenuItem className="Navbg"> Propes Changes Summry </MenuItem>


                                    </Menu>
                                </Col>
                            </Row>


                            <Row><Col>
                                <hr className="Bordertop"/>
                            </Col></Row>

                            {/* <Row>
                                <Col className="">


                                    <Box sx={{ maxWidth: 400 }}>



                                        <Stepper



                                            activeStep={groupPerentstep} orientation="vertical" >



                                            <Step





                                            >
                                                <StepLabel >
                                                    <div className="text-light">New Request</div>
                                                </StepLabel>
                                                <StepContent>
                                                    <Typography>
                                                        <Box sx={{ maxWidth: 400 }}>
                                                            <Stepper
                                                                sx={{
                                                                    'css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
                                                                        border: "10px solid white"
                                                                    },
                                                                }}


                                                                activeStep={newstep} orientation="vertical">
                                                                <Step
                                                                    sx={{
                                                                        'css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
                                                                            border: "10px solid white"
                                                                        },
                                                                    }}  >
                                                                    <StepLabel >
                                                                        <div className="text-light" style={{ fontSize: "12px" }}>Capture Request Details</div>
                                                                    </StepLabel>
                                                                    <StepContent>
                                                                        <Typography>
                                                                            <ul style={{ fontSize: "12px" }}>
                                                                                <li>Customer details</li>
                                                                                <li>GDPR</li>
                                                                                <li>PDPL</li>
                                                                            </ul>
                                                                        </Typography>
                                                                        <Box >
                                                                            <div>
                                                                            </div>
                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>


                                                                <Step

                                                                >
                                                                    <StepLabel >
                                                                        <div className="text-light" style={{ fontSize: "12px" }}>Capture Residential Address</div>                                                                </StepLabel>
                                                                    <StepContent>
                                                                        <Box >
                                                                            <div>

                                                                            </div>
                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>



                                                                <Step >
                                                                    <StepLabel >
                                                                        <div className="text-light" style={{ fontSize: "12px" }}>Capture Mailing Address</div></StepLabel>
                                                                    <StepContent>
                                                                        <Box >
                                                                            <div>
                                                                            </div>
                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>

                                                                <Step >
                                                                    <StepLabel >
                                                                        <div className="text-light Fontsize12" >Capture Employment Details</div></StepLabel>
                                                                    <StepContent>
                                                                        <Typography ><div className="Fontsize12">Cuctomer Details</div></Typography>
                                                                        <Box >
                                                                            <div>

                                                                            </div>
                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>


                                                                <Step >
                                                                    <StepLabel >
                                                                        <div className="text-light Fontsize12" >Capture Employment Address</div></StepLabel>
                                                                    <StepContent>
                                                                        <Box >
                                                                            <div>

                                                                            </div>
                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>



                                                                <Step >
                                                                    <StepLabel >
                                                                        <div className="text-light Fontsize12" >Capture Account Details</div></StepLabel>
                                                                    <StepContent>
                                                                        <Box >
                                                                            <div>

                                                                            </div>
                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>



                                                            </Stepper>

                                                        </Box>
                                                    </Typography>
                                                    <Box >
                                                        <div>


                                                        </div>
                                                    </Box>
                                                </StepContent>
                                            </Step>



                                            <Step >
                                                <StepLabel

                                                >
                                                    <div className="text-light">Enrich Cleint Information</div>
                                                </StepLabel>
                                                <StepContent >
                                                    <Typography>
                                                        <Box sx={{ maxWidth: 400 }}>
                                                            <Stepper activeStep={newstep} orientation="vertical">

                                                                <Step >
                                                                    <StepLabel

                                                                    >
                                                                        <div className="text-light Fontsize12">KYC Document Requirement</div>
                                                                    </StepLabel>
                                                                    <StepContent>
                                                                        <Box >
                                                                            <div>


                                                                            </div>
                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>




                                                            </Stepper>

                                                        </Box>
                                                    </Typography>
                                                    <Box >
                                                        <div>


                                                        </div>
                                                    </Box>
                                                </StepContent>
                                            </Step>




                                            <Step >
                                                <StepLabel

                                                >
                                                    <div className="text-light">Client Consent</div>
                                                </StepLabel>
                                                <StepContent >
                                                    <Typography>
                                                        <Box sx={{ maxWidth: 400 }}>
                                                            <Stepper activeStep={newstep} orientation="vertical">

                                                                <Step >
                                                                    <StepLabel >
                                                                        <div className="text-light Fontsize12">Review And Capture Client Consent</div>
                                                                    </StepLabel>
                                                                    <StepContent>
                                                                        <Typography><div className="Fontsize12">Customer Details</div></Typography>
                                                                        <Box >
                                                                            <div>

                                                                            </div>
                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>
                                                            </Stepper>

                                                        </Box>
                                                    </Typography>
                                                    <Box >
                                                        <div>


                                                        </div>
                                                    </Box>
                                                </StepContent>
                                            </Step>


                                            <Step >
                                                <StepLabel

                                                >
                                                    <div className="text-light">ID&V</div>
                                                </StepLabel>
                                                <StepContent >
                                                    <Typography>
                                                        <Box sx={{ maxWidth: 400 }}>
                                                            <Stepper activeStep={newstep} orientation="vertical">

                                                                <Step >
                                                                    <StepLabel >
                                                                        <div className="text-light Fontsize12">
                                                                            Confirm ID&V Method
                                                                        </div>
                                                                    </StepLabel>
                                                                    <StepContent>
                                                                        <Typography>
                                                                            <div className="Fontsize12">Confirm ID&V Method</div>
                                                                        </Typography>
                                                                        <Box >
                                                                            <div>

                                                                            </div>
                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>
                                                                <Step >
                                                                    <StepLabel >
                                                                        <div className="text-light Fontsize12">
                                                                            Submit Application
                                                                        </div>
                                                                    </StepLabel>
                                                                    <StepContent>
                                                                        <Typography>
                                                                            <div className="Fontsize12">Submit Application</div>
                                                                        </Typography>
                                                                        <Box >
                                                                            <div>

                                                                            </div>
                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>

                                                            </Stepper>

                                                        </Box>
                                                    </Typography>
                                                    <Box >
                                                        <div>


                                                        </div>
                                                    </Box>
                                                </StepContent>
                                            </Step>



                                            <Step >
                                                <StepLabel

                                                >
                                                    <div className="text-light">AML</div>
                                                </StepLabel>
                                                <StepContent >
                                                    <Typography>
                                                        <Box sx={{ maxWidth: 400 }}>
                                                            <Stepper activeStep={newstep} orientation="vertical">

                                                                <Step >
                                                                    <StepLabel >
                                                                        <div className="text-light Fontsize12">
                                                                            KYC Document Requirements
                                                                        </div>
                                                                    </StepLabel>
                                                                    <StepContent>
                                                                        <Typography>

                                                                        </Typography>
                                                                        <Box>

                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>
                                                                <Step >
                                                                    <StepLabel >
                                                                        <div className="text-light Fontsize12">
                                                                            Validate ID&V
                                                                        </div>
                                                                    </StepLabel>
                                                                    <StepContent>
                                                                        <Typography>
                                                                            <div className="Fontsize12">
                                                                                Manager Review
                                                                            </div>
                                                                        </Typography>
                                                                        <Box>

                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>
                                                                <Step >
                                                                    <StepLabel >
                                                                        <div className="text-light Fontsize12">
                                                                            Complate AML
                                                                        </div>
                                                                    </StepLabel>
                                                                    <StepContent>
                                                                        <Typography>
                                                                            <div className="Fontsize12">
                                                                                <ul>
                                                                                    <li>Hierarchy</li>
                                                                                    <li>Assessments</li>
                                                                                    <li>Documents</li>
                                                                                    <li>Comments</li>
                                                                                    <li>Confirm Matches</li>
                                                                                    <li>Assessments Decision</li>


                                                                                </ul>
                                                                            </div>
                                                                        </Typography>
                                                                        <Box>

                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>


                                                            </Stepper>

                                                        </Box>
                                                    </Typography>
                                                    <Box >
                                                        <div>


                                                        </div>
                                                    </Box>
                                                </StepContent>
                                            </Step>


                                            <Step >
                                                <StepLabel>
                                                    <div className="text-light">Risk Assessments</div>
                                                </StepLabel>
                                                <StepContent >
                                                    <Typography>
                                                        <Box sx={{ maxWidth: 400 }}>
                                                            <Stepper activeStep={newstep} orientation="vertical">

                                                                <Step >
                                                                    <StepLabel >
                                                                        <div className="text-light Fontsize12">
                                                                            Risk Assessments Individual Model (Default)
                                                                        </div>
                                                                    </StepLabel>
                                                                    <StepContent>
                                                                        <Typography>
                                                                            <ul className="Fontsize12">
                                                                                <li>Country Risk</li>
                                                                                <li>Entity Risk</li>
                                                                                <li>Risk Assessments</li>

                                                                            </ul>
                                                                        </Typography>
                                                                        <Box>

                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>
                                                            </Stepper>

                                                        </Box>
                                                    </Typography>
                                                    <Box >
                                                        <div>


                                                        </div>
                                                    </Box>
                                                </StepContent>
                                            </Step>


                                            <Step >
                                                <StepLabel>
                                                    <div className="text-light">Review And Approval</div>
                                                </StepLabel>
                                                <StepContent >
                                                    <Typography>
                                                        <Box sx={{ maxWidth: 400 }}>
                                                            <Stepper activeStep={newstep} orientation="vertical">
                                                                <Step >
                                                                    <StepLabel >
                                                                        <div className="text-light Fontsize12">
                                                                            Business Review And Sign-Off
                                                                        </div>
                                                                    </StepLabel>
                                                                    <StepContent>
                                                                        <Typography>
                                                                            <ul className="Fontsize12">
                                                                                <li>Manager Review</li>
                                                                                <li>Tasks</li>

                                                                            </ul>
                                                                        </Typography>
                                                                        <Box>

                                                                        </Box>
                                                                    </StepContent>
                                                                </Step>
                                                            </Stepper>

                                                        </Box>
                                                    </Typography>
                                                    <Box>

                                                    </Box>
                                                </StepContent>
                                            </Step>
                                        </Stepper>

                                    </Box>


                                </Col>
                            </Row> */}

                            <Row xs={{cols: 1}}>
                                <Col className={menuBorder == 1 ? "menuborder" : ""} style={{fontSize: '12px'}}>Capture
                                    Request Details</Col>
                                <Col className={menuBorder == 2 ? "menuborder mt-2" : "mt-2"}
                                     style={{fontSize: '12px'}}>Capture Residential Address</Col>
                                <Col className={menuBorder == 3 ? "menuborder mt-2" : "mt-2"}
                                     style={{fontSize: '12px'}}>Capture Mailing Address</Col>
                                <Col className={menuBorder == 4 ? "menuborder mt-2" : "mt-2"}
                                     style={{fontSize: '12px'}}>Capture Employment Details</Col>
                                <Col className={menuBorder == 5 ? "menuborder mt-2" : "mt-2"}
                                     style={{fontSize: '12px'}}>Capture Employment Address</Col>
                                <Col className={menuBorder == 6 ? "menuborder mt-2" : "mt-2"}
                                     style={{fontSize: '12px'}}>Capture Account Details</Col>
                                <Col className={menuBorder == 7 ? "menuborder mt-2" : "mt-2"}
                                     style={{fontSize: '12px'}}>KYC Document Requirement</Col>


                            </Row>


                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default NewGroupSidebar;