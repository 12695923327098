import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Alert, Autocomplete, Box, Snackbar, TextField, Typography, Tabs, Tab } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { Apicall, getCountryCodeByGccId, LogTime, sessionLogout } from "../../Api/UseFeitch";
import { withStyles } from "@material-ui/styles";
import { inputStyle } from "../helpingFunction/helpingInputData";


// const inputStyle = {
//     root: {
//         '& label.Mui-focused': {
//             color: 'var(--primary)',
//         },
//         '& .MuiInput-underline:after': {
//             borderBottomColor: 'yellow',
//         },
//         '& .MuiOutlinedInput-root': {
//             '& fieldset': {
//                 borderColor: 'dark',
//             },
//             '&:hover fieldset': {
//                 //   borderColor: 'dark',
//             },
//             '&.Mui-focused fieldset': {
//                 borderColor: 'var(--primary)',
//             },
//             '&.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {
//                 // backgroundColor:'var(--feild-bg-color)'
//             }
//         },
//     },
// };


function CustomTabPanel(props) {


    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const SearchForDuplicates = ({ adminData, setAdminData, data, setData, newRequestStep, setNewRequestStep }) => {

    const navigate = useNavigate();
    const [btnNameSubmit, setBtnNameSubmit] = useState("Continue");
    const [searchParams] = useSearchParams();
    const [subStepCourse, setSubStepCourse] = useState(searchParams.get('step') || 0)

    const [message, setMessage] = useState();
    const [view_by_sources, setView_by_sources] = useState([]);


    useEffect(() => {
        // // console.log("subStepCourse.................", subStepCourse)
        setNewRequestStep(subStepCourse || 0)
    }, [])


    const [bodyData, setBodyData] = useState({
        firstNameEn: data?.IGAFields?.firstNameEn || "",
        lastNameEn: data?.IGAFields?.lastNameEn || "",
        gccId: adminData?.nationalID || "",
        nationalID: adminData?.nationalID || "",
        cardCountry: adminData?.cardCountry || "",
        viewBySources: ""
    });

    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value })
    }
    const handleselect = (event, view_by_sources) => {
        setBodyData({ ...bodyData, viewBySources: view_by_sources.value });
    }
    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)
    }

    function alertFunc() {
        // setNewRequestStep((newRequestStep || 0) + 1);
        // navigate("/panel1/complete" + "?token=" + (newRequestStep) + "&step=" + (newRequestStep))
        // setLoading(false);
        setBtnNameSubmit("Continue");
        navigate("/panel1/complete" + "?token=2&step=2");

    }

    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
        let loginTime = LogTime();
        if (loginTime === false) {
            navigate("/");
            sessionLogout();
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);

    const errorMessage = (message) => {
        setMessage(message || "An error encounter");
        setOpen(true);
        setBtnNameSubmit("Create Entry");
        // setLoading(false);
    }
    const successMessage = (message) => {
        setMessage(message || "Ok Proceed");
        setOpen2(true);
    }

    async function handleclick(e) {
        e.preventDefault();
        let cardCountry = getCountryCodeByGccId(bodyData.gccId);
        if (!cardCountry) {
            errorMessage("GccId/nationalId is not valid");
            return;
        }

        setBtnNameSubmit(<Spinner animation="border" className="btn-pointer-color" style={{ height: "22px", width: "22px" }} />);
        // let resultt = await Apicall("API/EKYC/getCurrentKYCData", {
        //     "body": {
        //         "Id": {
        //             identityType: "GCC",
        //             GCC: {
        //                 cardCountry: cardCountry,
        //                 nationalID: bodyData.gccId,
        //                 ...bodyData, viewBySources: view_by_sources
        //             }
        //         },
        //     }
        // })
        // console.log("resultt-----------", resultt)
        // if (resultt.messageStatus == "OK") {
        //     setOpen2(true);
        successMessage("Create a new entry")
        timeout = setTimeout(alertFunc, 1000);
        // setBtnNameSubmit("Continue");

        // } else {
        //     setOpen(true);
        //     setMessage(resultt.errorDescription)
        //     setBtnNameSubmit("Continue");
        //
        // }
    }


    const Viewbysources = [
        { lable: 'Salary', id: 1, value: "Salary" },
        { lable: 'Inheritance', id: 2, value: "Inheritance" },
        { lable: 'Business Ownership', id: 3, value: "Business Ownership" },
        { lable: 'Investments', id: 4, value: "Investments" },
        { lable: 'Others', id: 5, value: "Others" },
    ];


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const selectstyle = {
        backgroundColor: 'white',
        borderRadius: '0px'
    };

    const CssTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: 'var(--primary)',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: 'yellow',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'dark',
                },
                '&:hover fieldset': {
                    //   borderColor: 'dark',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'var(--primary)',
                },
                '&.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {
                    // backgroundColor:'var(--feild-bg-color)'
                }
            },
        },
    })(TextField);


    const fieldsLists = [
        {
            title: "First Name ",
            value: <Box>
                {/* <Form.Control
                    onChange={handlechange}
                    name="firstNameEn"
                    value={bodyData.firstNameEn}
                    type="text"
                    aria-describedby="passwordHelpBlock"
                /> */}

                {/* <CssTextField
                key={'input-first-name'}
                    fullWidth 
                    size="small"
                    label="First Name"
                    variant="outlined"
                    onChange={handlechange}
                    name="firstNameEn"
                    autoFocus="autoFocus"
                    value={bodyData?.firstNameEn}
                    /> */}

                <TextField
                    fullWidth
                    className="input-field"
                    color="success"
                    size="small"
                    label="First Name"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    onChange={handlechange}
                    name="firstNameEn"
                    value={bodyData?.firstNameEn}
                    type="text" />
            </Box>,
        },
        {
            title: "Last Name",
            value: <Box>
                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    color="success"
                    inputProps={{ style: inputStyle }}
                    onChange={handlechange}
                    name="lastNameEn"
                    value={bodyData.lastNameEn}
                    type="text" />
            </Box>,
        },
        {
            title: "GCC ID  ",
            value: <Box>
                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    color="success"
                    inputProps={{ style: inputStyle }}
                    onChange={handlechange}
                    name="gccId"
                    value={bodyData.gccId}
                    type="text" />
            </Box>,
        },
        {
            title: "View by sources",
            value: <Box>
                <Autocomplete
                    onChange={handleselect}
                    size="small"
                    id="tags-outlined"
                    options={Viewbysources}
                    disableClearable={true}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success"  {...params} label="View by sources" />
                    )}
                />



            </Box>,
        },


    ];


    return (
        <>
            <Container fluid id="scroleble_main_content">


                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>


                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open2} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-success text-light" severity="success"
                        sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
                <Row className="border-bottom border-dark"><Col className="h3 fw-light mt-4">Search For Duplicates</Col>
                </Row>


                {
                    fieldsLists.map((field) =>
                        <Row className="mt-3">
                            <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                            <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                {field?.value}
                            </Col>
                            <Col xs={0} sm={2} xl={3}></Col>
                        </Row>
                    )
                }

                <Row className="mt-5">
                    <Col className="text-end me-5">
                        <Button
                            onClick={handleclick}
                            className="ps-4 pe-4 btn-secondary buttonstyle"
                        >
                            {btnNameSubmit}
                        </Button>
                    </Col>
                </Row>
                <Row className="ms-2">
                    <Col>

                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Item One" {...a11yProps(0)} />

                                </Tabs>
                            </Box>
                            {/* <CustomTabPanel value={value} index={0}>  Item One </CustomTabPanel> */}
                        </Box>


                    </Col>
                </Row>

                <Row style={{ border: "1px solid #AFAEAF" }}
                    className="mt-4 ms-2 me-2 p-4 d-flex justify-content-center text-center">
                    <Col className="pt-1 pb-1">No Records Found</Col>
                </Row>
            </Container>
        </>
    )
}
export default SearchForDuplicates;