import { Alert, Autocomplete, Box, Snackbar, TextField } from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall, LogTime, getCountryCodeByGccId } from "../../Api/UseFeitch";
import { useEffect } from "react";
import { getAddressBuildingTypeInput, inputStyle } from "../helpingFunction/helpingInputData";
// import "../globle.css"

const CaptureEmploymentAddress = ({setBodyEmploymentAddress }) => {
    const navigate = useNavigate();
    const [cardCountry, setCardCountry] = useState("BHR");
    const [searchParams] = useSearchParams();
    const [gccId, setGccId] = useState(searchParams.get("gccid") || "");
    const [getCurrentData, setGetCurrentData] = useState({});
    const [bodyDataEmploymentAddress, setBodyDataEmploymentAddress] = useState({ });

   
    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);


    let logintime = LogTime();
    useEffect(() => {
        if (logintime == false) {
            navigate("/");
            sessionStorage.removeItem("tokenSessionStorage");
        }
    }, []);

    async function fetchMyAPI(gccIdTemp) {
        let cardCountryTemp = await getCountryCodeByGccId(gccIdTemp);
        if (!cardCountryTemp) {
            return;
        }
        setCardCountry(cardCountryTemp);
        setGccId(gccIdTemp);
        let getdata = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "GCC",
                    GCC: {
                        "cardCountry": cardCountryTemp || "BHR",
                        "nationalID": gccIdTemp || "720033225",
                    }
                },
            },
        })
        setGetCurrentData(getdata);
        if (getdata) {
            setBodyDataEmploymentAddress({
                flatNumber: getdata?.nonIGAFields?.overridden?.addresses?.flatNumber || "",
                buildingAlpha: getdata?.nonIGAFields?.overridden?.addresses?.buildingAlpha || "",
                roadNumber: getdata?.nonIGAFields?.overridden?.addresses?.roadNumber || "",
                blockNumber: getdata?.nonIGAFields?.overridden?.addresses?.blockNumber || "",
                postalCode: "",
                buildingNumber: getdata?.nonIGAFields?.overridden?.addresses?.buildingNumber || "",
                buildingType: getdata?.nonIGAFields?.overridden?.addresses?.buildingType || "",
                country: getdata?.nonIGAFields?.overridden?.addresses?.country || "",
            })
        }

    }

    useEffect((e) => {
        if (searchParams.get("gccid")) fetchMyAPI(searchParams.get("gccid"))
        if (searchParams.get("gccId")) fetchMyAPI(searchParams.get("gccId"))
    }, [searchParams.get("gccid"), searchParams.get("gccId")])
   
    const handlechangeEmploymentAddress = (e) => {
        const { name, value } = e.target;
        setBodyDataEmploymentAddress({ ...bodyDataEmploymentAddress, [name]: value })
    }

    const BuildingtypeEmploymentAddress = (event, building_type) => {
        setBodyDataEmploymentAddress({ ...bodyDataEmploymentAddress, buildingType: building_type.lable });
    }

    const SelectCountryEmploymentAddress = (event, country) => {
        setBodyDataEmploymentAddress({ ...bodyDataEmploymentAddress, country: country.value });
    }

    setBodyEmploymentAddress(bodyDataEmploymentAddress);
   

    const CountryEmploymentAddress = [
        { lable: 'BAHRAIN', id: 1, value: "BHR" },
        { lable: 'SAUDI ARABIA', id: 2, value: "SAU" },
        { lable: 'UAE', id: 3, value: "UAE" },
        { lable: 'KUWAIT', id: 4, value: "KWT" },
        { lable: 'OMAN', id: 5, value: "OMN" },
        { lable: 'QATAR', id: 6, value: "QAT" },
    ];


    const fieldsListsEmploymentAddress = [
        {
            title: "Address Type ",
            value: getCurrentData?.nonIGAFields?.overridden?.addresses?.addressType || "-",
        },
        {
            title: <p>Building Type <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <Autocomplete
                    name="buildingType"
                    onChange={BuildingtypeEmploymentAddress}
                    size="small"
                    disableClearable={true}
                    id="tags-outlined"
                    options={getAddressBuildingTypeInput()}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Building Type" />
                    )}
                />
            </Box>,
        }, {
            title: <p>Building Number <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Building Number"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="buildingNumber"
                    onChange={handlechangeEmploymentAddress}
                    Value={bodyDataEmploymentAddress?.buildingNumber}
                    type="text" />
            </Box>
            ,
        }, {
            title: "Flat",
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Flat"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="flatNumber"
                    Value={bodyDataEmploymentAddress?.flatNumber}
                    onChange={handlechangeEmploymentAddress}
                    type="text" />
            </Box>
            ,
        }
        , {
            title: "Building Alpha ",
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Building Alpha"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="buildingAlpha"
                    Value={bodyDataEmploymentAddress?.buildingAlpha}
                    onChange={handlechangeEmploymentAddress}
                    type="text" />
            </Box>
            ,
        }, {
            title: "Road Number",
            value: <Box>
               
                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Road Number"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="roadNumber"
                    Value={bodyDataEmploymentAddress?.roadNumber}
                    onChange={handlechangeEmploymentAddress}
                    type="text" />
            </Box>
            ,
        }, {
            title: "Block Number",
            value: <Box>
              
                    <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Block Number"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="blockNumber"
                    Value={bodyDataEmploymentAddress?.blockNumber}
                    onChange={handlechangeEmploymentAddress}
                    type="text" />


            </Box>
            ,
        }, {
            title: "Postal code / zip",
            value: <Box>
                
                    <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Postal code / zip"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="postalCode"
                    Value={bodyDataEmploymentAddress?.postalCode}
                    onChange={handlechangeEmploymentAddress}
                    type="text" />
            </Box>
            ,
        }, {
            title: <p>Country<span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <Autocomplete
                    name="country"
                    size="small"
                    disableClearable={true}
                    id="tags-outlined"
                    options={CountryEmploymentAddress}
                    getOptionLabel={(option) => option.lable}
                    onChange={SelectCountryEmploymentAddress}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField  color="success"{...params} label="Country" />
                    )}
                />
            </Box>
            ,
        }


    ];







    return (
        <>

            <Container fluid >



                <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <Row className="border-bottom border-dark">
                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                    Address Information
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </Row>
                        <AccordionPanel pb={4}>
                            {
                                fieldsListsEmploymentAddress.map((field) =>
                                    <Row className="mt-3">
                                        <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                            {field?.value}
                                        </Col>
                                        <Col xs={0} sm={2} xl={3}></Col>
                                    </Row>
                                )
                            }

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

            </Container>

        </>
    )
}
export default CaptureEmploymentAddress;