import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LogTime } from "../../Api/UseFeitch";
// import "../globle.css"

const SubmitApplication = ({ groupstep4, setGroupstep4, groupPerentstep, setGroupPerentstep, setShowmenu }) => {

    const navigate = useNavigate();
    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);
    const stepclick = () => {
        // setGroupstep4((groupstep4 || 0) + 1);
        setGroupPerentstep(4);
        navigate("/panel2/2" + "?token=8&step=4&substep=0");
        setShowmenu(1)
        // navigate("/panel2/kyc-document-requirement" + "?token=" + (groupstep4) + "&step=" + (groupstep4))

    }

    let logintime = LogTime();

    // console.log("logintime", logintime);
    useEffect(() => {
        if (logintime == false) {
            navigate("/");
            sessionStorage.removeItem("tokenSessionStorage");
        }
    }, []);

    return (
        <>
            <Container fluid id="scroleble_main_content">
                <Row className="h3 fw-light mt-4"><Col> Submit Application</Col> </Row>
                <Row className=" border-bottom border-dark"><Col> </Col> </Row>



                <Row className="mt-3">
                    <Col sm={4} xs={12} className="text-md-end text-start text-danger">Portal text </Col>
                    <Col sm={6} xl={5} xs={12} className="fw-bold ">
                        Your KYC Application has now been accepted and is being processed. The bank will contact you when
                        processing is completed.
                    </Col>
                    <Col xs={0} sm={2} xl={3}></Col>
                </Row>

                <Row className=" mt-5  mb-5">
                    <Col md={4} lg={5} xl={6} xs={0} className=""></Col>
                    <Col md={8} lg={7} xl={6} xs={12} className=" ">
                        <Container>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={5} sm={6} className=" text-end">
                                    <Button className=" ps-4 pe-4 btn-secondary buttonstyle" >SAVE FOR LATER</Button>
                                </Col>
                                <Col md={6} sm={6} className=" text-end mt-sm-0 mt-2"> <Button
                                    onClick={stepclick}
                                    className="ps-4 pe-4 btn-secondary buttonstyle">
                                    SAVE AND COMPLETE
                                </Button></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>





            </Container>
        </>
    )
}
export default SubmitApplication;