import React, {useEffect, useState} from "react";
import {Link, Outlet} from "react-router-dom";
import {Button, Col, Container, Dropdown, Form, InputGroup, Modal, Offcanvas, Row} from "react-bootstrap";
import {BiMenu, BiSearch, BiSpreadsheet} from "react-icons/bi";
import HeaderIcons from "./HeaderIcons";
import {Avatar} from "@mui/material";
import NewRequestSidebar from "../Sidebar/NewRequestSidebar";
import {CAvatar} from "@coreui/react";
import logo from "../images/Fwd_/bank.jpg"
import HeaderIcons2 from "./HeaderIcons2";
import {BsPersonCircle} from "react-icons/bs";
// import "../globle.css"


const Header = ({newRequestStep, setNewRequestStep, setGroupPerentstep, setNewstep}) => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [showlogo, setShowlogo] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    const removesesiondata = () => {
        sessionStorage.removeItem("tokenSessionStorage");
        // setNewRequestStep(0);
    }
    return (

        <>

            <Container fluid>
                <Row>
                    <Col md={0} lg={2} className=" " style={{backgroundColor: "rgb(24, 22, 22)"}}>
                        <Row className="d-sm-none d-none d-md-none d-lg-block vh-100">
                            <Col className="p-0">
                                <NewRequestSidebar newRequestStep={newRequestStep}/>
                            </Col>

                        </Row>
                    </Col>
                    <Col md={12} lg={10} className="border-start p-0">
                        <nav className="headertop border-bottom navbar navbar-expand-md navbar-dark bg-light">
                            {/* <!-- Container wrapper --> */}
                            <div className="container-fluid d-flex">
                                {/* <!-- Navbar brand --> */}
                                <div className="d-flex">
                                    <CAvatar shape="rounded-0" className="d-md-block d-sm-block d-lg-none "> <BiMenu
                                        color="rgb(119, 119, 119)" size={30} onClick={handleShow}/></CAvatar>
                                    <Offcanvas className="headeroffcanvas" show={show} onHide={handleClose}>
                                        <Offcanvas.Header style={{backgroundColor: "var(--primary)", height: "50px"}}
                                                          closeButton>
                                            <Offcanvas.Title>
                                                <div className="">
                                                    <img className="img-fluid w-50 ps-2  pb-2 pt-2" src={logo}
                                                         alt="logo"/>
                                                </div>
                                            </Offcanvas.Title>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body className="bg-dark">
                                            <NewRequestSidebar newRequestStep={newRequestStep} showlogo={showlogo}/>
                                        </Offcanvas.Body>
                                    </Offcanvas>
                                    <div className="d-lg-none d-block me-auto">
                                        <img className="img-fluid w-25 " src={logo} alt="logo"/>
                                    </div>
                                </div>

                                {/* <!-- Icons --> */}


                                <div className="ms-auto d-md-none d-block">
                                    <Dropdown>
                                        <Avatar id="nav_icon" sx={{bgcolor: "#e3e4e6", width: "30px", height: "30px"}}
                                                className="m-1">
                                            <Dropdown.Toggle
                                                variant="secondery" style={{color: "white", marginRight: "32px"}}>
                                                <BsPersonCircle size={20} color="black" id="fabell"
                                                                style={{position: "relative", left: "16px"}}/>
                                            </Dropdown.Toggle>
                                        </Avatar>
                                        <div className="">
                                            <Dropdown.Menu id="header-dropdown-menu4">
                                                <Dropdown.Item className="">

                                                    <div>
                                                        <Container fluid className="p-0">
                                                            <Link className="text-decoration-none text-dark">
                                                                <Row onClick={removesesiondata} id="dropdownitems"
                                                                     className="m-0 border-bottom text-center pb-1 pt-1">
                                                                    <Col>
                                                                        Logout
                                                                    </Col>
                                                                </Row>

                                                            </Link>
                                                        </Container>

                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </div>
                                    </Dropdown>

                                </div>
                                {/* <Avatar id="nav_icon" sx={{ bgcolor: "#e3e4e6", width: "30px", height: "30px" }} className="m-1 ms-auto d-md-none d-block">< BsPersonCircle className=" ms-1 mt-1" color="black" size={20} />
                                </Avatar> */}

                                <CAvatar shape="rounded-0" className=" d-md-none d-block "> <BiMenu
                                    color="rgb(119, 119, 119)" size={30} onClick={handleShow2}/></CAvatar>
                                {/* <!-- Icons --> */}
                                <HeaderIcons setGroupPerentstep={setGroupPerentstep} setNewstep={setNewstep}
                                             newRequestStep={newRequestStep} setNewRequestStep={setNewRequestStep}/>
                            </div>

                            <Offcanvas placement="end" className="headeroffcanvas" show={show2} onHide={handleClose2}>
                                <Offcanvas.Header closeButton className="me-auto">
                                    <Offcanvas.Title>

                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body className="bg-light">
                                    <HeaderIcons2 setGroupPerentstep={setGroupPerentstep} setNewstep={setNewstep}
                                                  newRequestStep={newRequestStep}
                                                  setNewRequestStep={setNewRequestStep}/>
                                </Offcanvas.Body>
                            </Offcanvas>
                            {/* <!-- Container wrapper --> */}
                        </nav>
                        {/* <!-- Navbar --> */}

                        <Outlet/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Header;