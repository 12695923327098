import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Component/HeaderComponent/Header';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import NewGroupHeader from './Component/HeaderComponent/NewGroupHeader';
import CaptureResidentialAddress from './Component/NewGroup/CaptureResidentialAddress';
import CaptureMailingAddress from './Component/NewGroup/CaptureMailingAddress';
import SearchForDuplicates from './Component/NewRequest/SearchForDuplicates';
import Complete from './Component/NewRequest/Complete';
import CaptureEmploymentDetails from './Component/NewGroup/CaptureEmploymentDetails';
import CaptureEmploymentAddress from './Component/NewGroup/CaptureEmploymentAddress';
import CaptureAccountDetails from './Component/NewGroup/CaptureAccountDetails';
import KycRequirements from './Component/NewGroup/KycRequirements';
import CaptureCleintConsent from './Component/NewGroup/CaptureCleintConsent';
import ConformIDVMethod from './Component/NewGroup/ConformIDVMethod';
import SubmitApplication from './Component/NewGroup/SubmitApplication';
import KycDocumentRequirement from './Component/NewGroup/KycDocumentRequirement';
import ValidateIDV from './Component/NewGroup/ValidateIDV';
import ComplateALM from './Component/NewGroup/ComplateALM';
import Assessments from './Component/NewGroup/Assessments';
import WorldCheckOne from './Component/NewGroup/WorldCheckOne';
import CompleteRiskAssessments from './Component/NewGroup/CompleteRiskAssessments';
import BusinessReview from './Component/NewGroup/BusinessReview';
import Documents from './Component/NewGroup/Documents';
import LoginForm from './Component/LoginForm';
import "./Component/globle.css";
import ResetPassword from './Component/ResetPassword';
import { LogTime } from './Api/UseFeitch';
import NewRequestMap from './Component/NewRequest/NewRequestMap';
import Formswapper from './Component/NewGroup/Formswapper';

function App() {
    const [newRequestStep, setNewRequestStep] = useState(0);

    const [newstep, setNewstep] = useState(0);
    const [groupPerentstep, setGroupPerentstep] = useState(0);
    const [menuBorder,setMenuBorder]=useState(1);

    const [showmenu, setShowmenu] = useState(0);
    const [data, setData] = useState({});
    const [adminData, setAdminData] = useState({
        cardCountry: "",
        nationalID: "",
        type: "GCCID"
    });
    const [allFormDtat, setAllFormDtat] = useState({
        capturerequestdetails: "",
        // capturerequestdetails:""
    });

    return (
        <>
            {/* <BrowserRouter basename="/test-server"> */}
            <BrowserRouter basename="/">
                <Routes>
                    <Route path="/" element={<LoginForm />} />
                    {/* <Route path="/" element={<ResetPassword />} /> */}
                    <Route path="/panel1" element={<Header
                        setGroupPerentstep={setGroupPerentstep}
                        setNewstep={setNewstep}
                        newRequestStep={newRequestStep}
                        setNewRequestStep={setNewRequestStep}
                        setAdminData={setAdminData} adminData={adminData}
                    />}>
                        <Route index element={<NewRequestMap
                            data={data} setData={setData} newRequestStep={newRequestStep}
                            setNewRequestStep={setNewRequestStep}
                            setAdminData={setAdminData}
                            adminData={adminData}
                        />} />
                        {/*
            <Route index element={<EnterEntityDetail data={data} setData={setData} newRequestStep={newRequestStep} setNewRequestStep={setNewRequestStep} />} /> */}
                        <Route path="/panel1/search-for-duplecates"
                            element={<SearchForDuplicates data={data} setData={setData}
                                newRequestStep={newRequestStep}
                                setNewRequestStep={setNewRequestStep}
                                setAdminData={setAdminData}
                                adminData={adminData}
                            />} />
                        <Route path="/panel1/complete"
                            element={<Complete data={data} setData={setData} newRequestStep={newRequestStep}
                                setNewRequestStep={setNewRequestStep}
                                setAdminData={setAdminData}
                                adminData={adminData}
                            />} />
                    </Route>
                </Routes>
                <Routes>
                    <Route path="/panel2" element={<NewGroupHeader setMenuBorder={setMenuBorder} menuBorder={menuBorder} newRequestStep={newRequestStep}
                        setNewRequestStep={setNewRequestStep}
                        showmenu={showmenu} groupPerentstep={groupPerentstep}
                        setGroupPerentstep={setGroupPerentstep}
                        newstep={newstep} setNewstep={setNewstep} />}>
                        {/* <Route index element={<CaptureRequestDetails setShowTable1={setShowTable1} showmenu={showmenu} setShowmenu={setShowmenu} newstep={newstep} setNewstep={setNewstep} />} /> */}
                        <Route index element={<Formswapper setMenuBorder={setMenuBorder} menuBorder={menuBorder} data={data} setData={setData} 
                           />} />

                        {/* <Route index element={<Mymap data={data} setData={setData} showmenu={showmenu}
                            setShowmenu={setShowmenu} newstep={newstep} setNewstep={setNewstep}
                            groupPerentstep={groupPerentstep}
                            setGroupPerentstep={setGroupPerentstep} />} /> */}

                        {/* <Route path="/panel2/2"
                                element={<CaseDetails data={data} setData={setData} groupPerentstep={groupPerentstep}
                                setGroupPerentstep={setGroupPerentstep} newstep={newstep}
                                setNewstep={setNewstep} setShowmenu={setShowmenu} />} /> */}

                        <Route path="/panel2/capture-residential-address"
                            element={<CaptureResidentialAddress setAllFormDtat={setAllFormDtat}
                                allFormDtat={allFormDtat} setShowmenu={setShowmenu}
                                newstep={newstep} setNewstep={setNewstep} />} />
                        <Route path="/panel2/capture-mailing-address"
                            element={<CaptureMailingAddress setShowmenu={setShowmenu} newstep={newstep}
                                setNewstep={setNewstep} />} />
                        <Route path="/panel2/capture-employment-details"
                            element={<CaptureEmploymentDetails setShowmenu={setShowmenu} newstep={newstep}
                                setNewstep={setNewstep} />} />
                        <Route path="/panel2/capture-employment-address"
                            element={<CaptureEmploymentAddress setShowmenu={setShowmenu} newstep={newstep}
                                setNewstep={setNewstep} />} />
                        <Route path="/panel2/capture-account-details"
                            element={<CaptureAccountDetails groupPerentstep={groupPerentstep}
                                setGroupPerentstep={setGroupPerentstep} newstep={newstep}
                                setNewstep={setNewstep} />} />
                        <Route path="/panel2/kyc-requirements"
                            element={<KycRequirements groupPerentstep={groupPerentstep}
                                setGroupPerentstep={setGroupPerentstep} />} />
                        <Route path="/panel2/capture-cleint-consent"
                            element={<CaptureCleintConsent groupPerentstep={groupPerentstep}
                                setGroupPerentstep={setGroupPerentstep} />} />
                        <Route path="/panel2/confirm-idv-method" element={<ConformIDVMethod />} />
                        <Route path="/panel2/submit-application"
                            element={<SubmitApplication groupPerentstep={groupPerentstep}
                                setGroupPerentstep={setGroupPerentstep} />} />
                        <Route path="/panel2/kyc-document-requirement"
                            element={<KycDocumentRequirement setShowmenu={setShowmenu} />} />
                        <Route path="/panel2/validate-id&v" element={<ValidateIDV />} />
                        <Route path="/panel2/complate-alm"
                            element={<ComplateALM setShowmenu={setShowmenu} groupPerentstep={groupPerentstep}
                                setGroupPerentstep={setGroupPerentstep} />} />
                        <Route path="/panel2/assessments" element={<Assessments data={data} setData={setData} />} />
                        <Route path="/panel2/world-check-one" element={<WorldCheckOne data={data} setData={setData} />} />
                        <Route path="/panel2/complete-risk-assessments"
                            element={<CompleteRiskAssessments setShowmenu={setShowmenu}
                                groupPerentstep={groupPerentstep}
                                setGroupPerentstep={setGroupPerentstep} />} />
                        <Route path="/panel2/business-review" element={<BusinessReview groupPerentstep={groupPerentstep}
                            setGroupPerentstep={setGroupPerentstep} />} />
                        <Route path="/panel2/documents" element={<Documents data={data} setData={setData} />} />

                    </Route>
                </Routes>
            </BrowserRouter>
        </>

    );
}

export default App;
