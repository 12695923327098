import React, { useEffect, useState } from "react";
import NewGroupSidebar from "../Sidebar/NewGroupSidebar";
import { Col, Container, Dropdown, Form, InputGroup, Offcanvas, Row } from "react-bootstrap";
import { Avatar } from "@mui/material";
import { BiMenu, BiSearch } from "react-icons/bi";
import HeaderIcons from "./HeaderIcons";
import { Link, Outlet } from "react-router-dom";
import { CAvatar } from "@coreui/react";
import logo from "../images/Fwd_/bank.jpg"
import HeaderIcons2 from "./HeaderIcons2";
import { BsPersonCircle } from "react-icons/bs";
// import "../globle.css"
const NewGroupHeader = ({ setMenuBorder, menuBorder, newRequestStep, setNewRequestStep, setGroupPerentstep, newstep, groupPerentstep, showmenu, setNewstep }) => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [showlogo, setShowlogo] = useState(true);

    const removesesiondata = () => {
        sessionStorage.removeItem("tokenSessionStorage");
        // setNewstep(0);
        // setGroupPerentstep(0);
    }
    return (
        <>
            <Container fluid >
                <Row>
                    <Col md={0} lg={2} className="" style={{ backgroundColor: "rgb(24, 22, 22)" }} >
                        <Row className="d-sm-none d-none d-md-none d-lg-block vh-100">
                            <Col className="p-0" >
                                <NewGroupSidebar setMenuBorder={setMenuBorder} menuBorder={menuBorder} showmenu={showmenu} groupPerentstep={groupPerentstep} newstep={newstep} />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} lg={10} className="border-start p-0">
                        <nav className="headertop border-bottom navbar navbar-expand-md navbar-dark bg-light ">
                            {/* <!-- Container wrapper --> */}
                            <div className="container-fluid d-flex" >
                                {/* <!-- Navbar brand --> */}
                                <div className="d-flex" >
                                    <CAvatar shape="rounded-0" className="d-md-block d-sm-block d-lg-none " > <BiMenu color="rgb(119, 119, 119)" size={30} onClick={handleShow} /></CAvatar>
                                    <Offcanvas className="headeroffcanvas" show={show} onHide={handleClose}>
                                        <Offcanvas.Header style={{ borderBottom: "3px solid blue", backgroundColor: "var(--primary)", height: "50px" }} closeButton className={'   '} >
                                            <Offcanvas.Title>
                                                <div >
                                                    <img className="img-fluid w-50 ps-2  pb-2 pt-2 " src={logo} alt="kyc-logo" />
                                                </div>
                                            </Offcanvas.Title>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body className="bg-dark mx-0">
                                            <NewGroupSidebar setMenuBorder={setMenuBorder} menuBorder={menuBorder} showlogo={showlogo} groupPerentstep={groupPerentstep} newstep={newstep} showmenu={showmenu} />
                                        </Offcanvas.Body>
                                    </Offcanvas>
                                    <div className="d-lg-none d-block ">
                                        <img className="img-fluid w-25" src={logo} alt="logooooo" />
                                    </div>
                                </div>
                                <div className="ms-auto d-md-none d-block">
                                    <Dropdown >
                                        <Avatar id="nav_icon" sx={{ bgcolor: "#e3e4e6", width: "30px", height: "30px" }} className="m-1">
                                            <Dropdown.Toggle
                                                variant="secondery" style={{ color: "white", marginRight: "32px" }} >
                                                <BsPersonCircle size={20} color="black" id="fabell" style={{ position: "relative", left: "16px" }} />
                                            </Dropdown.Toggle>
                                        </Avatar>
                                        <div className="" >
                                            <Dropdown.Menu id="header-dropdown-menu4" >
                                                <Dropdown.Item className="" >

                                                    <div >
                                                        <Container fluid className="p-0" >
                                                            <Link className="text-decoration-none text-dark"  >
                                                                <Row onClick={removesesiondata} className="dropdownitems m-0 border-bottom text-center pb-1 pt-1">
                                                                    <Col>
                                                                        Logout
                                                                    </Col>
                                                                </Row>

                                                            </Link>
                                                        </Container>

                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </div>
                                    </Dropdown>
                                </div>
                                {/* <Avatar id="nav_icon" sx={{ bgcolor: "#e3e4e6", width: "30px", height: "30px" }} className="m-1 ms-auto d-md-none d-block">< BsPersonCircle className=" ms-1 mt-1" color="black"  size={20} /></Avatar> */}

                                <CAvatar shape="rounded-0" className=" d-md-none d-block " > <BiMenu color="rgb(119, 119, 119)" size={30} onClick={handleShow2} /></CAvatar>
                                {/* <!-- Icons --> */}
                                <HeaderIcons setGroupPerentstep={setGroupPerentstep} setNewstep={setNewstep} newRequestStep={newRequestStep} setNewRequestStep={setNewRequestStep} />


                            </div>

                            <Offcanvas placement="end" className="headeroffcanvas" show={show2} onHide={handleClose2}>
                                <Offcanvas.Header closeButton  >
                                    <Offcanvas.Title>

                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body className="bg-light">
                                    <HeaderIcons2 setGroupPerentstep={setGroupPerentstep} setNewstep={setNewstep} setNewRequestStep={setNewRequestStep} />
                                </Offcanvas.Body>
                            </Offcanvas>
                            {/* <!-- Container wrapper --> */}
                        </nav>
                        {/* <!-- Navbar --> */}

                        <Outlet />


                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default NewGroupHeader;