import { Alert, Autocomplete, Box, Snackbar, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall, LogTime } from "../../Api/UseFeitch";
// import "../globle.css"

const Assessments = () => {

    const links = <Link className="text-decoration-none text-dark Fontsize13" style={{ cursor: "pointer" }} to="/panel2/world-check-one">Edit</Link>;
    const navigate = useNavigate();

    const [message, setMessage] = useState();
    const [btnNameSubmit, setBtnNameSubmit] = useState("SAVE AND COMPLETE");

    const [assessments, setAssessments] = useState([])
    const [solution_status, setSolution_status] = useState([])
    const [ped_status, setPed_status] = useState([])
    const [advance_media_status, setAdvance_media_status] = useState([])
    const [law_enforcements_status, setLaw_enforcements_status] = useState([])
    const [regulatory_enforcements_status, setRegulatory_enforcements_status] = useState([])
    const [linked, setLinked] = useState([])
    const [other_status, setOther_status] = useState([])
    const [aml_risk, setAml_risk] = useState([])
    const [bodyData, setBodyData] = useState({
        assessmentNotes: ""
    });
    const [mandatorydata, setMandatory] = useState({

    })



    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value })
    }

    const handleselect1 = (event, value) => {
        setAssessments(value);
    }

    const handleselect2 = (event, solution_status) => {
        setSolution_status(solution_status);
    }
    const handleselect3 = (event, ped_status) => {
        setPed_status(ped_status);
    }

    const handleselect4 = (event, advance_media_status) => {
        setAdvance_media_status(advance_media_status);
    }
    const handleselect5 = (event, law_enforcements_status) => {
        setLaw_enforcements_status(law_enforcements_status);
    }

    const handleselect6 = (event, regulatory_enforcements_status) => {
        setRegulatory_enforcements_status(regulatory_enforcements_status);
    }
    const handleselect7 = (event, linked) => {
        setLinked(linked);
    }

    const handleselect8 = (event, other_status) => {
        setOther_status(other_status);
    }
    const handleselect9 = (event, value) => {
        setAml_risk(value);
    }

    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)

    }
    function alertFunc() {
        navigate("/panel2/complate-alm");

    }
    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);

    let logintime = LogTime();

    // console.log("logintime", logintime);
    useEffect(() => {
        if (logintime == false) {
            navigate("/");
            sessionStorage.removeItem("tokenSessionStorage");
        }
    }, []);

    async function handleclick(e) {

        e.preventDefault();
        setBtnNameSubmit( <Spinner animation="border" variant="danger" style={{height:"22px",width:"22px"}}/>);

        let resultt = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "NonIGA",
                    nonIGA: {
                        "passport": "31348675222",
                        "gender": "M",
                        "DOB": "10/10/2020",
                        "nationality": "PAK",
                        ...bodyData, assessments: assessments, overallSolutionStatus: solution_status, overallPedStatus: ped_status, overallAdvanceMediaStatus: advance_media_status, overallLawEnforcementsStatus: law_enforcements_status, overallRegulatoryEnforcementsStatus: regulatory_enforcements_status, overallStateOwned: linked, overallOtherStatus: other_status, overallAMLRisk: aml_risk
                    }
                },
                consentMode: ""
            }
        })
        // console.log("resultt-----------", resultt)
        if (resultt.messageStatus == "OK") {
            setOpen2(true);
            setMessage(resultt.errorDescription)
            timeout = setTimeout(alertFunc, 1000);
            setBtnNameSubmit("SAVE AND COMPLETE");

        } else {
            setOpen(true);
            setMessage(resultt.errorDescription);
            setBtnNameSubmit("SAVE AND COMPLETE");

        }

    }

    const sempleoption = [
        { lable: 'Option 1', year: 1994 },
        { lable: 'Option 2', year: 1972 },
        { lable: 'Option 3', year: 1974 },
        { lable: 'Option 4', year: 2008 }
    ];
    const taskth = [
        {
            id: "1",
            thname: "Task ID "
        },
        {
            id: "2",
            thname: "Task Name"
        },
        {
            id: "3",
            thname: "Screening Id"
        },
        {
            id: "4",
            thname: "Screening Provider"
        },
        {
            id: "5",
            thname: "Assigned Team"
        },
        {
            id: "6",
            thname: "Assigned User"
        },
        {
            id: "7",
            thname: " Task Duration"
        },
        {
            id: "8",
            thname: "Status"
        },
        {
            id: "9",
            thname: "Last Updated By"
        },
        {
            id: "10",
            thname: " Ready To Complete"
        },
    ]

    const tasktd = [
        {
            id: "1",
            thname: "4417 "
        },
        {
            id: "2",
            thname: "Match Aprover World Check One Screening ped"
        },
        {
            id: "3",
            thname: "World Check One Screening"
        },
        {
            id: "4",
            thname: "Compliance"
        },
        {
            id: "5",
            thname: "3 min"
        },
        {
            id: "6",
            thname: "Compliance"
        },
        {
            id: "7",
            thname: " 3 min"
        },
        {
            id: "8",
            thname: "Status"
        },
        {
            id: "9",
            thname: "Faisal Ali"
        },
        {
            id: "10",
            thname: " Ready To Complete"
        },
    ]


    const thdata = [
        {
            id: "1",
            thname: "ID "
        },
        {
            id: "2",
            thname: "Type"
        },
        {
            id: "3",
            thname: "Matches"
        },
        {
            id: "4",
            thname: "Confirmed"
        },
        {
            id: "5",
            thname: "Catogery"
        },
        {
            id: "6",
            thname: "Risk"
        },
        {
            id: "7",
            thname: " Status"
        },
        {
            id: "8",
            thname: "Created On"
        },
        {
            id: "9",
            thname: "Last Updated By"
        },
        {
            id: "10",
            thname: " Updated On"
        },

        ,
        {
            id: "11",
            thname: "Action"
        },
    ]

    const tddata = [
        {
            id: "1",
            tdname: "986"
        },
        {
            id: "2",
            tdname: <p className="text-danger">New request</p>
        },
        {
            id: "3",
            tdname: "New request"
        },
        {
            id: "4",
            tdname: "Portal user"
        },
        {
            id: "5",
            tdname: "Ahmad al mdani"
        },
        {
            id: "6",
            tdname: "02/17/2020"
        },
        {
            id: "7",
            tdname: "02/17/2020"
        },
        {
            id: "8",
            tdname: "in progress"
        },
        {
            id: "8",
            tdname: "02/17/2020"
        },
        {
            id: "9",
            tdname: "System users"
        },

        {
            id: "10",
            tdname: <Tooltip style={{ cursor: "pointer" }} title={links} arrow><BsThreeDots />  </Tooltip>
        },

    ]

    const selectstyle = {
        backgroundColor: 'white',
        borderRadius: '0px'
    };








    const fieldsLists = [
        {
            title: "Overall Solution Status",
            value: <Box >
                <Autocomplete
                    name="overallSolutionStatus"
                    onChange={handleselect2}
                    style={selectstyle}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small" {...params} placeholder="Select" />
                    )}
                />
            </Box>,
        }, {
            title: "Overall Ped Status ",
            value: <Box >
                <Autocomplete
                    name="overallPedStatus"
                    onChange={handleselect3}
                    style={selectstyle}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small" {...params} placeholder="Select" />
                    )}
                />
            </Box>
            ,
        }, {
            title: "Overall Advance Media Status",
            value: <Box >
                <Autocomplete
                    name="overallAdvanceMediaStatus"
                    style={selectstyle}
                    onChange={handleselect4}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small" {...params} placeholder="Select" />
                    )}
                />
            </Box>
            ,
        }
        , {
            title: "Overall Law Enforcements Status ",
            value: <Box >
                <Autocomplete
                    name="overallLawEnforcementsStatus"
                    style={selectstyle}
                    onChange={handleselect5}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small" {...params} placeholder="Select" />
                    )}
                />
            </Box>
            ,
        }, {
            title: "Overall Regulatory Enforcements Status",
            value: <Box >
                <Autocomplete
                    name="overallRegulatoryEnforcementsStatus"
                    style={selectstyle}
                    onChange={handleselect6}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small" {...params} placeholder="Select" />
                    )}
                />
            </Box>
            ,
        }, {
            title: "Overall State Owned/Linked",
            value: <Box >
                <Autocomplete
                    name="overallStateOwned"
                    onChange={handleselect7}
                    style={selectstyle}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small" {...params} placeholder="Select" />
                    )}
                />
            </Box>
            ,
        }, {
            title: "Overall Other Status",
            value: <Box >
                <Autocomplete
                    name="overallOtherStatus"
                    onChange={handleselect8}
                    style={selectstyle}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small" {...params} placeholder="Select" />
                    )}
                />
            </Box>
            ,
        }, {
            title: "Overall AML Risk",
            value: <Box >
                <Autocomplete
                    name="overallAMLRisk"
                    onChange={handleselect9}
                    style={selectstyle}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small" {...params} placeholder="Select" />
                    )}
                />
            </Box>
            ,
        },
        {
            title: "Assessment Date",
            value: " 02/17/2023"
        },
        {
            title: "Assessment Notes",
            value: <Box >
                <Form.Control style={{ borderRadius: "0px" }} name="assessmentNotes" value={bodyData.assessmentNotes} as="textarea" onChange={handlechange} rows={3} />
            </Box>
        }



    ];








    return (
        <>
            <form onSubmit={handleclick}>
                <Container fluid id="scroleble_main_content">

                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>



                    <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open2} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} className="bg-success text-light" severity="success" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>
                    <Row className="h3 fw-light mt-4"><Col>Assessments-</Col> </Row>



                    <Row className=" ">
                        <Col md={4} lg={5} xl={6} className=""></Col>
                        <Col md={8} lg={7} xl={6} >
                            <Container>
                                <Row>
                                    <Col xs={12} sm={4} md={3} className="fw-bold">Assessments</Col>
                                    <Col xs={12} sm={8} md={9} className="">
                                        <Box  >
                                            <Autocomplete
                                                name="assessments"
                                                onChange={handleselect1}
                                                style={selectstyle}
                                                size="small"
                                                multiple
                                                id="tags-outlined"
                                                options={sempleoption}
                                                getOptionLabel={(option) => option.lable}
                                                filterSelectedOptions
                                                renderInput={(params) => (
                                                    <TextField size="small" {...params} placeholder="Select" />
                                                )}
                                            />
                                        </Box>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>


                    </Row>





                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Entity Under Assessments
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>


                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>









                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Active Screening
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>

                                <Row className="mt-2">
                                    <Col>
                                        <Table responsive className="table table-striped mt-2">
                                            <thead>
                                                <tr>
                                                    {
                                                        thdata.map((th) => {
                                                            return (
                                                                <>
                                                                    <th className="Fontsize13">{th.thname}</th>

                                                                </>
                                                            )
                                                        })
                                                    }

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    {
                                                        tddata.map((td) => {
                                                            return (
                                                                <>
                                                                    <td>{td.tdname}</td>

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>




                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Assessments Desicion
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>
                                {
                                    fieldsLists.map((field) =>
                                        <Row className="mt-3">
                                            <Col sm={3} xs={12} className="text-md-end text-start text-danger">{field?.title} </Col>
                                            <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                                {field?.value}
                                            </Col>
                                            <Col xs={0} sm={2} xl={3}></Col>
                                        </Row>
                                    )
                                }

                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>





                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        creening Tasks
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>


                                <Row className="mt-2">
                                    <Col>
                                        <Table responsive className="table table-striped mt-2">
                                            <thead>
                                                <tr>
                                                    {
                                                        taskth.map((th) => {
                                                            return (
                                                                <>
                                                                    <th className="Fontsize13">{th.thname}</th>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {
                                                        tasktd.map((th) => {
                                                            return (
                                                                <>
                                                                    <td className="Fontsize13">{th.thname}</td>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    {
                                                        tasktd.map((th) => {
                                                            return (
                                                                <>
                                                                    <td className="Fontsize13">{th.thname}</td>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </tr>


                                            </tbody>
                                        </Table>

                                    </Col>
                                </Row>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>


                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Documents
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>


                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        Comments
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>


                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                    <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <Row className="border-bottom border-dark">
                                <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                    <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                        History
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Row>
                            <AccordionPanel pb={4}>


                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>


                    <Row className=" mt-5  mb-5">
                        <Col md={4} lg={5} xl={6} xs={0} className=""></Col>
                        <Col md={8} lg={7} xl={6} xs={12} className=" ">
                            <Container>
                                <Row>
                                    <Col md={1}></Col>
                                    <Col md={5} sm={6} className=" text-end">
                                        <Button className=" ps-4 pe-4 btn-secondary buttonstyle">SAVE FOR LATER</Button>
                                    </Col>
                                    <Col md={6} sm={6} className=" text-end mt-sm-0 mt-2"><Button
                                        type="submit"
                                        className="ps-4 pe-4 btn-secondary buttonstyle ">

                                        {btnNameSubmit   }

                                    </Button></Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>

                </Container>
            </form>

        </>
    )
}

export default Assessments;