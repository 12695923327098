import axios from "axios";
import React from "react";
import CryptoJS from "crypto-js";
import {useNavigate} from "react-router-dom";


const secretPass = "XkhZG4fW2t2W";
//live URL
 const baseUrl="https://user.wathiq.bh/";
  // const nodeUrl="http://193.188.107.191:8880/get_signature";
 const nodeUrl="https://rafidian-node.aimerse.com/get_signature";
 
//Testing URL
//const baseUrl = "https://testekycapi.wathiq.bh/api/";
// const nodeUrl="http://193.188.107.191:8880/get_signature_test";
//const nodeUrl="https://rafidian-node.aimerse.com/get_signature_test";

// const baseUrl = "https://demo.aimerse.com/kyc-test-v1/public/api/";
// const baseUrl2 = "http://127.0.0.1:8081/";


const header = {
    "token": "",
    "X-Signature": "",
    'Access-Control-Allow-Origin': "*",
    Accept: "application/json",
    "Content-Type": "application/json"
}
const headerUpload = {
    // "token": "",
	'Access-Control-Allow-Origin': "*",
    "Content-Type": "multipart/form-data",
    // "Content-Type":"application/x-www-form-urlencoded",
}


const loginApiCall = async (url, InputData) => {
   // console.log("InputData------------", InputData);
    const res = await axios.post(baseUrl + "" + url, InputData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
			'Access-Control-Allow-Origin': "*",
        }
    }).then((r) => r.data).catch((e) => {
        // console.log(e)
        // console.log(e.response.data);
        if (e?.response?.data) {
            return e.response.data;
        } else {
            return {
                "messageStatus": "ERROR",
                "cipherMessageId": "",
                "errorDescription": e.message + ". Please try after sometime.",
                "errorCode": 201,
                "timestamp": new Date(),
            }
        }

    })
    // console.log(res);
    return res;
};

const Apicall = async (url, InputData) => {
    const headerToken = sessionStorage.getItem("tokenSessionStorage");
    const getSignatureSigned = await getSignature(InputData);
   // console.log("ApiInput.......................", InputData);
    const res = await axios.post(baseUrl + "" + url, InputData, {
        headers: {
            ...header,
            token: headerToken,
            "X-Signature": getSignatureSigned
        }
    })
        .then((r) => r.data).catch((e) => {
            // console.log(e)
            // console.log(e.response.data);
            // e.response.status = 403
            if (e?.response?.data?.errorDescription === "Token has expired or invalid token passed") {
                // window.location.href = "/";
                sessionStorage.removeItem("tokenSessionStorage");
            }
            if (e?.response?.data) {
                return e.response.data;
            } else {
                return {
                    "messageStatus": "ERROR",
                    "cipherMessageId": "",
                    "errorDescription": e.message + ". Please try after sometime.",
                    "errorCode": 201,
                    "timestamp": new Date(),
                }
            }

        })
   // console.log("sfas",res);
    return res;
};

const ApicallUpload = async (url, InputData) => {
    // console.log("InputDatafile------------", InputData);
    const headerToken = sessionStorage.getItem("tokenSessionStorage");
    const {data} = await axios.post(baseUrl + "" + url, InputData, {
        headers: {
            ...headerUpload,
            token: headerToken
        },
    })
    // console.log(data)
    return data;
};

const LogTime = () => {
    let sessionStringTime = sessionStorage.getItem("timeSessionStorage");
    let sessionDateTime = new Date(sessionStringTime);
    let isSession = true;
    let currentTime = new Date();
    let currentTime2 = new Date("2023-10-15 01:00:00");
    let diff = (currentTime.getTime() - sessionDateTime.getTime());
    let sec = Math.floor(diff / (1000));
    let min = Math.floor(diff / (1000 * 60));
    let hours = Math.floor(diff / (1000 * 60 * 60));

    if (hours >= 24) {
        isSession = false;
    }
    // console.log("Session Time------", sessionDateTime);
    // console.log("currentTime------", currentTime);
    // console.log("Hours------", hours);
    // console.log("Minute------", min);
    // console.log("Seconds------", sec);
    return isSession;
}

const getSignature = async (InputData) => {

    const res = await axios.post(nodeUrl, InputData, {
        headers: {
            // Accept: "application/json",
            "Content-Type": "application/json",
			'Access-Control-Allow-Origin': "*"
        }
    }).then((r) => r.data).catch((e) => {
        // console.log(e)
         // console.log(e.response);
        if (e?.response?.data) {
            return e.response.data;
        } else {
            return "";
        }
    })
    // console.log("Signature-", res);
    return res;
}

// const navigate = useNavigate();
const sessionLogout = async () => {
    sessionStorage.removeItem('tokenSessionStorage');
    sessionStorage.removeItem('timeSessionStorage');
    sessionStorage.clear();
    // navigate("/");
    return sessionStorage.getItem('tokenSessionStorage') || null;
}
const getCountryCodeByGccId = async (gccId) => {
    // console.log(gccId)
    const idLength = gccId?.length || 0;
    let cardCountry = "";
    if (idLength === 8) {
        cardCountry = "OMN"
    } else if (idLength === 9) {
        cardCountry = "BHR"
    } else if (idLength === 12) {
        cardCountry = "KSA"
    } else if (idLength === 11) {
        cardCountry = "Qatar"
    } else if (idLength === 12) {
        cardCountry = "Kuwait"
    } else if (idLength === 15) {
        cardCountry = "UAI"
    } else {
        cardCountry = null;
    }
    return cardCountry || null;
}

export {loginApiCall, Apicall, ApicallUpload, LogTime, getSignature, sessionLogout, getCountryCodeByGccId};