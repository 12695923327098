import { Autocomplete, Avatar, Box, InputAdornment, TextField, Snackbar, Tooltip } from "@mui/material";
import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Dropdown, InputGroup } from "react-bootstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Apicall, LogTime, getCountryCodeByGccId, sessionLogout } from "../../Api/UseFeitch";

import CaptureResidentialAddress from "./CaptureResidentialAddress";
import CaptureMailingAddress from "./CaptureMailingAddress";
import CaptureEmploymentDetails from "./CaptureEmploymentDetails";
import CaptureEmploymentAddress from "./CaptureEmploymentAddress";
import CaptureAccountDetails from "./CaptureAccountDetails";

import KycRequirements from "./KycRequirements";

import CaptureRequestDetails2 from "./CaptureRequestDetails2";





const Formswapper = ({ setMenuBorder, data, setData }) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [caseIdParam, setCaseIdParam] = useState(searchParams.get('caseid') || '');
    const [btnNameSubmit, setBtnNameSubmit] = useState(<>SAVE AND COMPLETE</>);
    // // console.log("caseIdParam", caseIdParam);

    const [identityType, setIdentityType] = useState('GCC');
    const [gccId, setGccId] = useState(searchParams.get("gccid") || "");
    const [cardCountry, setCardCountry] = useState("BHR");
    // // console.log(searchParams.get("gccid"));
    const [documentContent, setDocumentContent] = useState({});
    const [documentContentdata2, setDocumentContentdata2] = useState({});
    const [documentContentdata3, setDocumentContentdata3] = useState({});
    const [bodyRequestDetails, setBodyRequestDetails] = useState({});
    const [bodyResidentialAddress, setBodyResidentialAddress] = useState({});
    const [bodyMailingAddress, setBodyMailingAddress] = useState({});
    const [bodyEmploymentDetails, setBodyEmploymentDetails] = useState({});
    const [bodyEmploymentAddress, setBodyEmploymentAddress] = useState({});
    const [bodyAccountDetail, setBoAccountDetail] = useState({});
    const [mainData, setMainData] = useState({});
    const [openError, setOpenError] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [documentData, setDocumentData] = useState()

    const errorMessage = (message) => {
        setMessage(message || "An error encounter");
        setOpenError(true);
        setBtnNameSubmit("Save & Complete");
        // setLoading(false);
    }
    const successMessage = (message) => {
        setMessage(message || "Ok Proceed");
        setOpenSuccess(true);
    }

    const [message, setMessage] = useState();
    let timeout;
    const handleClose = () => {
        setOpenError(false)
        setOpenSuccess(false)
    };

    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
            sessionLogout().then(r => r);
        }
        let loginTime = LogTime();
        if (loginTime === false) {
            navigate("/");
            sessionLogout().then(r => r);
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);

    async function fetchMyAPI(gccIdTemp) {
        let cardCountryTemp = await getCountryCodeByGccId(gccIdTemp);
        if (!cardCountryTemp) {
            errorMessage("GccId/nationalId is not valid");
            return;
        }
        setCardCountry(cardCountryTemp);
        setGccId(gccIdTemp);
        let getdata = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "GCC",
                    GCC: {
                        "cardCountry": cardCountryTemp || "BHR",
                        "nationalID": gccIdTemp || "",
                    }
                },
            },
        })
        setMainData(getdata);
    }

    useEffect(() => {
        if (searchParams.get("gccid")) fetchMyAPI(searchParams.get("gccid"))
        if (searchParams.get("gccId")) fetchMyAPI(searchParams.get("gccId"))
    }, [searchParams.get("gccid"), searchParams.get("gccId")])

    async function handlesubmit(e) {
        e.preventDefault();
        setBtnNameSubmit(<Spinner animation="border" className="btn-pointer-color" style={{ height: "22px", width: "22px" }} />);
        const FieldInsideId = {
            "GCC": {
                "cardCountry": cardCountry || "BHR",
                "nationalID": gccId || "",
            }
        };



        let documents = [];

        if (documentContent && Object.keys(documentContent).length > 0) {
            documents.push(documentContent);
        }
        if (documentContentdata2 && Object.keys(documentContentdata2).length > 0) {
            documents.push(documentContentdata2);
        }
        if (documentContentdata3 && Object.keys(documentContentdata3).length > 0) {
            documents.push(documentContentdata3);
        }

        // let Additionaldata = Additionaldata;
        // if (bodyRequestDetails?.otherPhoneNo) {
        //     Additionaldata.otherPhoneNo = bodyRequestDetails?.otherPhoneNo;
        // }
        // if (bodyRequestDetails?.middleName4En) {
        //     Additionaldata.middleName4En = bodyRequestDetails?.middleName4En;
        // }
        // if (bodyRequestDetails?.USCitizen) {
        //     Additionaldata.USCitizen = bodyRequestDetails?.USCitizen;
        // }
        //
        // if (bodyRequestDetails?.PEPId) {
        //     Additionaldata.PEPId = bodyRequestDetails?.PEPId;
        // }
        // if (bodyRequestDetails?.FATCADeclarationId) {
        //     Additionaldata.FATCADeclarationId = bodyRequestDetails?.FATCADeclarationId;
        // }
        // if (bodyRequestDetails?.CRSDeclarationId) {
        //     Additionaldata.CRSDeclarationId = bodyRequestDetails?.CRSDeclarationId;
        // }
        // if (bodyRequestDetails?.USTaxResident) {
        //     Additionaldata.USTaxResident = bodyRequestDetails?.USTaxResident;
        // }
        // if (bodyRequestDetails?.bornInUS) {
        //     Additionaldata.bornInUS = bodyRequestDetails?.bornInUS;
        // }
        // if (bodyEmploymentDetails?.workPermitValid) {
        //     Additionaldata.workPermitValid = bodyEmploymentDetails?.workPermitValid;
        // }
        // if (bodyEmploymentDetails?.salary) {
        //     Additionaldata.salary = bodyEmploymentDetails?.salary;
        // }
        // if (bodyEmploymentDetails?.sourceOfFunds) {
        //     Additionaldata.sourceOfFunds = bodyEmploymentDetails?.sourceOfFunds;
        // }
        // if (bodyEmploymentDetails?.natureOfBusinessActivity) {
        //     Additionaldata.natureOfBusinessActivity = bodyEmploymentDetails?.natureOfBusinessActivity;
        // }
        // if (bodyAccountDetail?.typeOfAccount) {
        //     Additionaldata.typeOfAccount = bodyAccountDetail?.typeOfAccount;
        // }
        // if (bodyAccountDetail?.natureAndLevelOfBusinessActivity) {
        //     Additionaldata.natureAndLevelOfBusinessActivity = bodyAccountDetail?.natureAndLevelOfBusinessActivity;
        // }
        // if (bodyAccountDetail?.reasonForOpeningAccount) {
        //     Additionaldata.reasonForOpeningAccount = bodyAccountDetail?.reasonForOpeningAccount;
        // }
        // if (bodyAccountDetail?.monthlyTurnOver) {
        //     Additionaldata.monthlyTurnOver = bodyAccountDetail?.monthlyTurnOver;
        // }

        // let overridden = overridden;
        // if (bodyEmploymentDetails?.occupation) {
        //     overridden.occupation = bodyEmploymentDetails?.occupation;
        // }
        // if (bodyEmploymentDetails?.employmentStatus) {
        //     overridden.employmentStatus = bodyEmploymentDetails?.employmentStatus;
        // }
        // if (bodyRequestDetails?.email) {
        //     overridden.email = bodyRequestDetails?.email;
        // }
        // if (bodyRequestDetails?.mobile) {
        //     overridden.mobile = bodyRequestDetails?.mobile;
        // }
        // if (bodyRequestDetails?.maritalStatus) {
        //     overridden.maritalStatus = bodyRequestDetails?.maritalStatus;
        // }
        //
        // if (bodyRequestDetails?.phoneNo) {
        //     overridden.phoneNo = bodyRequestDetails?.phoneNo;
        // }

        let residentialAdd = {
            "addressId": "1",
            "addressCode": "RESIDENTIAL",
            "addressType": "VILLA",
        }

        if (bodyResidentialAddress?.buildingType) {
            residentialAdd.buildingType = bodyResidentialAddress?.buildingType;
            residentialAdd.addressType = bodyResidentialAddress?.buildingType;
        }
        if (bodyResidentialAddress?.buildingNumber) {
            residentialAdd.buildingNumber = bodyResidentialAddress?.buildingNumber;
        }
        if (bodyResidentialAddress?.flatNumber) {
            residentialAdd.flatNumber = bodyResidentialAddress?.flatNumber;
        }
        if (bodyResidentialAddress?.buildingAlpha) {
            residentialAdd.buildingAlpha = bodyResidentialAddress?.buildingAlpha;
        }
        if (bodyResidentialAddress?.roadNumber) {
            residentialAdd.roadNumber = bodyResidentialAddress?.roadNumber;
        }
        if (bodyResidentialAddress?.blockNumber) {
            residentialAdd.blockNumber = bodyResidentialAddress?.blockNumber;
        }
        if (bodyResidentialAddress?.postalCode) {
            residentialAdd.postalCode = bodyResidentialAddress?.postalCode;
        }
        if (bodyResidentialAddress?.country) {
            residentialAdd.country = bodyResidentialAddress?.country;
        }

        let mailingAdd = {
            "addressId": "2",
            "addressCode": "MAILING",
            "addressType": "BUILDING",
        }
        if (bodyMailingAddress?.buildingType) {
            mailingAdd.buildingType = bodyMailingAddress?.buildingType;
            mailingAdd.addressType = bodyMailingAddress?.buildingType;
        }
        if (bodyMailingAddress?.buildingNumber) {
            mailingAdd.buildingNumber = bodyMailingAddress?.buildingNumber;
        }
        if (bodyMailingAddress?.flatNumber) {
            mailingAdd.flatNumber = bodyMailingAddress?.flatNumber;
        }
        if (bodyMailingAddress?.buildingAlpha) {
            mailingAdd.buildingAlpha = bodyMailingAddress?.buildingAlpha;
        }
        if (bodyMailingAddress?.roadNumber) {
            mailingAdd.roadNumber = bodyMailingAddress?.roadNumber;
        }
        if (bodyMailingAddress?.blockNumber) {
            mailingAdd.blockNumber = bodyMailingAddress?.blockNumber;
        }
        if (bodyMailingAddress?.postalCode) {
            mailingAdd.postalCode = bodyMailingAddress?.postalCode;
        }
        if (bodyMailingAddress?.country) {
            mailingAdd.country = bodyMailingAddress?.country;
        }


        let employmentAdd = {
            "addressId": "3",
            "addressCode": "EMPLOYMENT",
            "addressType": "VILLA",
        }
        if (bodyEmploymentAddress?.buildingType) {
            employmentAdd.buildingType = bodyEmploymentAddress?.buildingType;
            employmentAdd.addressType = bodyEmploymentAddress?.buildingType;
        }
        if (bodyEmploymentAddress?.buildingNumber) {
            employmentAdd.buildingNumber = bodyEmploymentAddress?.buildingNumber;
        }
        if (bodyEmploymentAddress?.flatNumber) {
            employmentAdd.flatNumber = bodyEmploymentAddress?.flatNumber;
        }
        if (bodyEmploymentAddress?.buildingAlpha) {
            employmentAdd.buildingAlpha = bodyEmploymentAddress?.buildingAlpha;
        }
        if (bodyEmploymentAddress?.roadNumber) {
            employmentAdd.roadNumber = bodyEmploymentAddress?.roadNumber;
        }
        if (bodyEmploymentAddress?.blockNumber) {
            employmentAdd.blockNumber = bodyEmploymentAddress?.blockNumber;
        }
        if (bodyEmploymentAddress?.postalCode) {
            employmentAdd.postalCode = bodyEmploymentAddress?.postalCode;
        }
        if (bodyEmploymentAddress?.country) {
            employmentAdd.country = bodyEmploymentAddress?.country;
        }


        let Addresses = [];
        if (residentialAdd && Object.keys(residentialAdd).length > 0) {
            Addresses.push(residentialAdd);
        }
        if (mailingAdd && Object.keys(mailingAdd).length > 0) {
            Addresses.push(mailingAdd);
        }
        if (employmentAdd && Object.keys(employmentAdd).length > 0) {
            Addresses.push(employmentAdd);
        }


        const inputDataBody = {
            "Id": {
                "identityType": identityType || "GCC",
                ...FieldInsideId
            },
            "caseId": caseIdParam,
            "nonIGAFeilds": {
                // "primary": {
                //     // "firstNameAr": "خالد",
                //     // "middleName1Ar": "خالد",
                //     // "middleName2Ar": "خالد",
                //     // "middleName3Ar": "خالد",
                //     // "middleName4Ar": "",
                //     // "lastNameAr": "خالد",
                //     // "firstNameEn": "KHALID",
                //     // "middleName1En": "KHALID",
                //     // "middleName2En": "KHALID",
                //     // "middleName3En": "KHALID",
                //     "middleName4En": bodyRequestDetails?.middleName4En || "",
                //     // "lastNameEn": "KHALID",
                //     // "gender": "M",
                //     // "dateOfBirth": "10/10/2020",
                //     // "telephoneNumber": "36000000",
                //     "nationality": bodyRequestDetails?.GDPRCountryOfResidence || "",
                //     // "placeOfBirth": "MUHARRAQ",
                //     // "nameOfEmployer": "INFORMATION & eGOVERNMENT UTHORITY",
                //     // "passportNumber": "A000111",
                //     // "residentPermitNo": ""
                // },

                "additional": {
                    // ...Additionaldata,
                    "otherPhoneNo": bodyRequestDetails?.otherPhoneNo || "",
                    "PEPId": bodyRequestDetails?.PEPId || "N",
                    "FATCADeclarationId": bodyRequestDetails?.FATCADeclarationId || "N",
                    "CRSDeclarationId": bodyRequestDetails?.CRSDeclarationId || "N",
                    "workPermitValid": bodyEmploymentDetails?.workPermitValid || "N",
                    "natureOfSelfEmployment": "1",
                    "salary": bodyEmploymentDetails?.salary || "",
                    "natureOfBusinessActivity": bodyEmploymentDetails.natureOfBusinessActivity || "natureBusiness",
                    "sourceOfFunds": bodyEmploymentDetails.sourceOfFunds || "Salary",
                    "sourceOfWealth": "Other income",
                     "actOrEstNetWorth": 10,
                    "typeOfAccount": bodyAccountDetail?.typeOfAccount || "Current",
                    "natureAndLevelOfBusinessActivity": bodyAccountDetail?.natureAndLevelOfBusinessActivity || "activity",
                    "reasonForOpeningAccount": bodyAccountDetail.reasonForOpeningAccount || "open account",
                    "monthlyTurnOver": bodyAccountDetail?.monthlyTurnOver || "",
                    "USCitizen": bodyRequestDetails?.USCitizen || "N",
                    "USTaxResident": bodyRequestDetails?.USTaxResident || "N",
                    "bornInUS": bodyRequestDetails?.bornInUS || "N",
                },
                "overridden": {
                    // ...overridden,
                    "email": bodyRequestDetails?.email || "",
                    "mobile": bodyRequestDetails?.mobile || "",
                    "maritalStatus": bodyRequestDetails?.maritalStatus || "NEVER MARRIED",
                    "phoneNo": bodyRequestDetails?.phoneNo || "",
                    "employmentStatus": bodyEmploymentDetails?.employmentStatus || "",
                    "residentPermitExpiry": "",
                    "occupation": bodyEmploymentDetails.occupation || "",
                    "addresses": [...Addresses]
                }
            },

            "consentDocuments": {
                "documents": [
                    {
                        "name": documentContent?.name || "",
                        "hash": documentContent?.hash || "",
                    }
                ]
            },
            "documents": [...documents],
            "riskCode": "MEDIUM",
            "remarks": "MVP Profile",
            "dueDiligenceLevel": "COMMON"
        };

        let resultt = await Apicall("API/EKYC/publishSignedCase", {
            "body": inputDataBody
        })
        // console.log("resulttt-----------", resultt)
        if (resultt.messageStatus === "OK") {
            setOpenSuccess(true);
            setMessage(resultt.errorDescription)
            timeout = setTimeout(alertFunc, 3000);
        } else {
            setOpenError(true);
            setMessage(resultt.errorDescription);
            setBtnNameSubmit("SAVE AND COMPLETE");
        }

    }

    async function handlesubmit2(e) {
        e.preventDefault();
        if (!documentContent?.hash) {
            errorMessage("Document Required");
            return;
        }

        setBtnNameSubmit(<Spinner animation="border" className="btn-pointer-color"
                                  style={{height: "22px", width: "22px"}}/>);
        const FieldInsideId = {
            "GCC": {
                "cardCountry": cardCountry || "BHR",
                "nationalID": gccId || "",
            }
        };

        let documents = [];

        if (documentContent && Object.keys(documentContent).length > 0) {
            documents.push(documentContent);
        }
        if (documentContentdata2 && Object.keys(documentContentdata2).length > 0) {
            documents.push(documentContentdata2);
        }
        if (documentContentdata3 && Object.keys(documentContentdata3).length > 0) {
            documents.push(documentContentdata3);
        }


        const inputDataBody = {
            "Id": {
                "identityType": identityType || "GCC",
                ...FieldInsideId
            },
            "caseId": caseIdParam || "",
            "nonIGAFeilds": {
                "primary": {
                    // "firstNameAr": "خالد",
                    // "middleName1Ar": "خالد",
                    // "middleName2Ar": "خالد",
                    // "middleName3Ar": "خالد",
                    // "middleName4Ar": "",
                    // "lastNameAr": "خالد",
                    // "firstNameEn": "KHALID",
                    // "middleName1En": "KHALID",
                    // "middleName2En": "KHALID",
                    // "middleName3En": "KHALID",
                    "middleName4En": bodyRequestDetails?.middleName4En || "",
                    // "lastNameEn": "KHALID",
                    // "gender": "M",
                    // "dateOfBirth": "10/10/2020",
                    // "telephoneNumber": "36000000",
                    "nationality": bodyRequestDetails?.GDPRCountryOfResidence || "",
                    // "placeOfBirth": "MUHARRAQ",
                    // "nameOfEmployer": "INFORMATION & eGOVERNMENT UTHORITY",
                    // "passportNumber": "A000111",
                    // "residentPermitNo": ""
                },
                "additional": {
                    "otherPhoneNo": bodyRequestDetails?.otherPhoneNo || "",
                    "PEPId": bodyRequestDetails?.PEPId || "N",
                    "FATCADeclarationId": bodyRequestDetails?.FATCADeclarationId || "",
                    "CRSDeclarationId": bodyRequestDetails?.CRSDeclarationId || "",
                    "workPermitValid": bodyEmploymentDetails?.workPermitValid || "",
                    "natureOfSelfEmployment": "1",
                    "salary": bodyEmploymentDetails?.salary || "",
                    "natureOfBusinessActivity": bodyEmploymentDetails.natureOfBusinessActivity || "natureBusiness",
                    "sourceOfFunds": bodyEmploymentDetails.sourceOfFunds || "Salary",
                    "sourceOfWealth": "Other income",
                    "actOrEstNetWorth": 10,
                    "typeOfAccount": bodyAccountDetail?.typeOfAccount || "Current",
                    "natureAndLevelOfBusinessActivity": bodyAccountDetail?.natureAndLevelOfBusinessActivity || "activity",
                    "reasonForOpeningAccount": bodyAccountDetail.reasonForOpeningAccount || "open account",
                    "monthlyTurnOver": bodyAccountDetail?.monthlyTurnOver || "",
                    "USCitizen": bodyRequestDetails?.USCitizen || "",
                    "USTaxResident": bodyRequestDetails?.USTaxResident || "",
                    "bornInUS": bodyRequestDetails?.bornInUS || "",
                },
                "overridden": {
                    "email": bodyRequestDetails?.email || "",
                    "mobile": bodyRequestDetails?.mobile || "",
                    "maritalStatus": bodyRequestDetails?.maritalStatus || "NEVER MARRIED",
                    "phoneNo": bodyRequestDetails?.phoneNo || "",
                    "employmentStatus": bodyEmploymentDetails?.employmentStatus || "",
                    "residentPermitExpiry": "",
                    "occupation": bodyEmploymentDetails.occupation || "",
                    "addresses": [
                        {
                            "addressId": "1",
                            "addressCode": "RESIDENTIAL",
                            "addressType": bodyResidentialAddress?.buildingNumber || "",
                            "flatNumber": bodyResidentialAddress?.flatNumber || "",
                            "buildingType": bodyResidentialAddress?.buildingType || "",
                            "buildingNumber": bodyResidentialAddress?.buildingNumber || "",
                            // "buildingName": "",
                            "buildingAlpha": bodyResidentialAddress?.buildingAlpha || "",
                            "roadNumber": bodyResidentialAddress?.roadNumber || "",
                            "blockNumber": bodyResidentialAddress?.blockNumber || "",
                            "postalCode": bodyResidentialAddress?.postalCode || "",
                            "country": bodyResidentialAddress?.country || "BHR",
                        },
                        {
                            "addressId": "2",
                            "addressCode": "MAILING",
                            "addressType": bodyMailingAddress?.buildingType || "",
                            "flatNumber": bodyMailingAddress?.flatNumber || "",
                            "buildingType": bodyMailingAddress?.buildingType || "",
                            "buildingNumber": bodyMailingAddress?.buildingNumber || "",
                            "buildingName": "test",
                            "buildingAlpha": bodyMailingAddress?.buildingAlpha || "",
                            "roadNumber": bodyMailingAddress?.roadNumber || "",
                            "blockNumber": bodyMailingAddress?.blockNumber || "",
                            "postalCode": bodyMailingAddress?.postalCode || "",
                            "country": bodyMailingAddress?.country || "",
                        },
                        {
                            "addressId": "3",
                            "addressCode": "EMPLOYMENT",
                            "addressType": bodyEmploymentAddress?.buildingType || "",
                            "flatNumber": bodyEmploymentAddress?.flatNumber || "",
                            "buildingType": "",
                            "buildingNumber": bodyEmploymentAddress?.buildingNumber || "",
                            "buildingName": "test",
                            "buildingAlpha": bodyEmploymentAddress?.buildingAlpha || "",
                            "roadNumber": bodyEmploymentAddress?.roadNumber || "",
                            "blockNumber": bodyEmploymentAddress?.blockNumber || "",
                            "postalCode": bodyEmploymentAddress?.postalCode || "",
                            "country": bodyEmploymentAddress?.country || "",
                        }
                    ]
                }
            },

            "consentDocuments": {
                "documents": [
                    {
                        "name": documentContent?.name || "",
                        "hash": documentContent?.hash || "",
                    }
                ]
            },
            "documents": [...documents],
            "riskCode": "MEDIUM",
            "remarks": "MVP Profile",
            "dueDiligenceLevel": "COMMON"
        };

        let resultt = await Apicall("API/EKYC/publishSignedCase", {
            "body": inputDataBody
        })
        //  console.log("resulttt-----------", resultt)
        if (resultt.messageStatus === "OK") {
            setOpenSuccess(true);
            setMessage(resultt.errorDescription)
            timeout = setTimeout(alertFunc, 3000);
        } else {
            setOpenError(true);
            setMessage(resultt.errorDescription);
            setBtnNameSubmit("SAVE AND COMPLETE");
        }

    }


    function alertFunc() {
        setBtnNameSubmit("SAVE AND COMPLETE");
        setOpenSuccess(true)
        navigate("/panel1");
    }
    return (
        <>
            <Container fluid id="scroleble_main_content">
                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openError} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>

                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-success text-light" severity="success" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>

                <form onSubmit={handlesubmit}>

                    <div className="accordion" id="accordionExample">

                        {/* Capture Request Details page */}

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button onClick={(() => { setMenuBorder(1) })} className="accordion-button p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#CaptureRequestDetails"
                                    aria-expanded="true" aria-controls="CaptureRequestDetails">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Capture Request Details
                                    </Box>
                                </button>
                            </h2>
                            <div id="CaptureRequestDetails" className="accordion-collapse collapse show"
                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    <CaptureRequestDetails2 bodyRequestDetails={bodyRequestDetails} setBodyRequestDetails={setBodyRequestDetails} data={mainData} />

                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">

                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button onClick={(() => { setMenuBorder(2) })} data-bs-toggle="collapse" data-bs-target="#ResidentialAddress"
                                                aria-expanded="false" aria-controls="ResidentialAddress"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </div>

                        {/* Capture Residential Address page */}

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button onClick={(() => { setMenuBorder(2) })} className="accordion-button collapsed p-1     accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#ResidentialAddress"
                                    aria-expanded="false" aria-controls="ResidentialAddress">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Capture Residential Address
                                    </Box>
                                </button>
                            </h2>
                            <div id="ResidentialAddress" className="accordion-collapse collapse"
                                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    <CaptureResidentialAddress setBodyResidentialAddress={setBodyResidentialAddress} data={mainData} setData={setData} />
                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button onClick={(() => { setMenuBorder(1) })} data-bs-toggle="collapse" data-bs-target="#CaptureRequestDetails"
                                                aria-expanded="true" aria-controls="CaptureRequestDetails"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button onClick={(() => { setMenuBorder(3) })} data-bs-toggle="collapse" data-bs-target="#MailingAddress"
                                                aria-expanded="false" aria-controls="MailingAddress"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        {/* Capture Mailing Address page */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button onClick={(() => { setMenuBorder(3) })} className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#MailingAddress" aria-expanded="false"
                                    aria-controls="MailingAddress">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Capture Mailing Address
                                    </Box>
                                </button>
                            </h2>
                            <div id="MailingAddress" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    <CaptureMailingAddress setBodyMailingAddress={setBodyMailingAddress} data={data} setData={setData} />
                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button onClick={(() => { setMenuBorder(2) })} data-bs-toggle="collapse" data-bs-target="#ResidentialAddress"
                                                aria-expanded="false" aria-controls="ResidentialAddress"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button onClick={(() => { setMenuBorder(4) })} data-bs-toggle="collapse" data-bs-target="#EmploymentDetails"
                                                aria-expanded="false" aria-controls="EmploymentDetails"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        {/* Capture Employment Details page */}

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading4">
                                <button onClick={(() => { setMenuBorder(4) })} className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#EmploymentDetails"
                                    aria-expanded="false" aria-controls="EmploymentDetails">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Capture Employment Details
                                    </Box>
                                </button>
                            </h2>
                            <div id="EmploymentDetails" className="accordion-collapse collapse" aria-labelledby="heading4"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <CaptureEmploymentDetails setBodyEmploymentDetails={setBodyEmploymentDetails} data={data} setData={setData} />
                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button onClick={(() => { setMenuBorder(3) })} data-bs-toggle="collapse" data-bs-target="#MailingAddress"
                                                aria-expanded="false" aria-controls="MailingAddress"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button onClick={(() => { setMenuBorder(5) })} data-bs-toggle="collapse" data-bs-target="#EmploymentAddress"
                                                aria-expanded="false" aria-controls="EmploymentAddress"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        {/* Capture Employment Address page */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading5">
                                <button onClick={(() => { setMenuBorder(5) })} className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#EmploymentAddress"
                                    aria-expanded="false" aria-controls="EmploymentAddress">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Capture Employment Address
                                    </Box>
                                </button>
                            </h2>
                            <div id="EmploymentAddress" className="accordion-collapse collapse" aria-labelledby="heading5"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    <CaptureEmploymentAddress setBodyEmploymentAddress={setBodyEmploymentAddress} />
                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button onClick={(() => { setMenuBorder(4) })} data-bs-toggle="collapse" data-bs-target="#EmploymentDetails"
                                                aria-expanded="false" aria-controls="EmploymentDetails"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button onClick={(() => { setMenuBorder(6) })} data-bs-toggle="collapse" data-bs-target="#AccountDetails"
                                                aria-expanded="false" aria-controls="AccountDetails"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        {/* Capture Account Details page */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading6">
                                <button onClick={(() => { setMenuBorder(6) })} className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#AccountDetails" aria-expanded="false"
                                    aria-controls="AccountDetails">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Capture Account Details
                                    </Box>
                                </button>
                            </h2>
                            <div id="AccountDetails" className="accordion-collapse collapse" aria-labelledby="heading6"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">


                                    <CaptureAccountDetails setBoAccountDetail={setBoAccountDetail} />
                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button onClick={(() => { setMenuBorder(5) })} data-bs-toggle="collapse" data-bs-target="#EmploymentAddress"
                                                aria-expanded="false" aria-controls="EmploymentAddress"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button onClick={(() => { setMenuBorder(7) })} data-bs-toggle="collapse" data-bs-target="#KYCRequirements"
                                                aria-expanded="false" aria-controls="KYCRequirements"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        {/* KYC Requirements page */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading7">
                                <button onClick={(() => { setMenuBorder(7) })} className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#KYCRequirements"
                                    aria-expanded="false" aria-controls="KYCRequirements">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        KYC Requirements
                                    </Box>
                                </button>
                            </h2>
                            <div id="KYCRequirements" className="accordion-collapse collapse" aria-labelledby="heading7"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <KycRequirements setDocumentContentdata3={setDocumentContentdata3} setDocumentContentdata2={setDocumentContentdata2} documentContent={documentContent} setDocumentContent={setDocumentContent} data={data} setData={setData} />
                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button onClick={(() => { setMenuBorder(6) })} data-bs-toggle="collapse" data-bs-target="#AccountDetails"
                                                aria-expanded="false" aria-controls="AccountDetails"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button type="submit"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                {btnNameSubmit}
                                            </Button>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </div>

                        {/* Review Data And Capture Cleint Consent */}

                        {/* <div className="accordion-item">
                            <h2 className="accordion-header" id="heading8">
                                <button className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#CleintConsent" aria-expanded="false"
                                    aria-controls="CleintConsent">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Review Data And Capture Cleint Consent
                                    </Box>
                                </button>
                            </h2>
                            <div id="CleintConsent" className="accordion-collapse collapse" aria-labelledby="heading8"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">


                                 <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Customer Details
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>
                         {
                             CleintConsentfieldsLists1.map((field) =>
                                 <Row className="mt-3">
                                     <Col sm={3} xs={12}
                                         className="text-md-end text-start">{field?.title} </Col>
                                     <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                         {field?.value}
                                     </Col>
                                     <Col xs={0} sm={2} xl={3}></Col>
                                 </Row>
                             )
                         }
                     </AccordionPanel>
                 </AccordionItem>
                                  </Accordion>

                                  <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Employment Details
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>

                         {
                             CleintConsentfieldsLists2.map((field) =>
                                 <Row className="mt-3">
                                     <Col sm={3} xs={12}
                                         className="text-md-end text-start">{field?.title} </Col>
                                     <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                         {field?.value}
                                     </Col>
                                     <Col xs={0} sm={2} xl={3}></Col>
                                 </Row>
                             )
                         }
                     </AccordionPanel>
                 </AccordionItem>
                                  </Accordion>

                                  <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Account Details
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>
                         {
                             CleintConsentfieldsLists3.map((field) =>
                                 <Row className="mt-3">
                                     <Col sm={3} xs={12}
                                         className="text-md-end text-start">{field?.title} </Col>
                                     <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                         {field?.value}
                                     </Col>
                                     <Col xs={0} sm={2} xl={3}></Col>
                                 </Row>
                             )
                         }


                     </AccordionPanel>
                 </AccordionItem>
                                  </Accordion>

                                  <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Client Consent
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>
                         {
                             CleintConsentfieldsLists4.map((field) =>
                                 <Row className="mt-3">
                                     <Col sm={3} xs={12}
                                         className="text-md-end text-start">{field?.title} </Col>
                                     <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                         {field?.value}
                                     </Col>
                                     <Col xs={0} sm={2} xl={3}></Col>
                                 </Row>
                             )
                         }

                     </AccordionPanel>
                 </AccordionItem>
                                  </Accordion>

                                  <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Addresses
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>


                         <Row className="mt-4">
                             <Col>
                                 <Table className="table table-striped" responsive>
                                     <thead>
                                         <tr>
                                             {
                                                 CleintConsentthdata.map((thdata) => {
                                                     return (
                                                         <>
                                                             <th scope="col">{thdata.thname}</th>
                                                         </>
                                                     )
                                                 })
                                             }

                                         </tr>
                                     </thead>
                                     <tbody>
                                         <tr>
                                             {
                                                 CleintConsenttddata1.map((tddata) => {
                                                     return (
                                                         <>
                                                             <td scope="col">{tddata.thname}</td>
                                                         </>
                                                     )
                                                 })
                                             }

                                         </tr>
                                         <tr>
                                             {
                                                 CleintConsenttddata2.map((tddata) => {
                                                     return (
                                                         <>
                                                             <td scope="col">{tddata.thname}</td>
                                                         </>
                                                     )
                                                 })
                                             }

                                         </tr>
                                         <tr>
                                             {
                                                 CleintConsenttddata1.map((tddata) => {
                                                     return (
                                                         <>
                                                             <td scope="col">{tddata.thname}</td>
                                                         </>
                                                     )
                                                 })
                                             }

                                         </tr>

                                     </tbody>
                                 </Table>
                             </Col>
                         </Row>
                     </AccordionPanel>
                 </AccordionItem>
                                  </Accordion>
                                   
                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button data-bs-toggle="collapse" data-bs-target="#KYCRequirements"
                                                aria-expanded="false" aria-controls="KYCRequirements"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button data-bs-toggle="collapse" data-bs-target="#ConfirmIDandVMethod"
                                                aria-expanded="false" aria-controls="ConfirmIDandVMethod"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div> */}

                        {/* Confirm ID&V Method page */}


                        {/* <div className="accordion-item">
                            <h2 className="accordion-header" id="heading9">
                                <button className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#ConfirmIDandVMethod"
                                    aria-expanded="false" aria-controls="ConfirmIDandVMethod">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Confirm ID&V Method
                                    </Box>
                                </button>
                            </h2>
                            <div id="ConfirmIDandVMethod" className="accordion-collapse collapse" aria-labelledby="heading9"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Confirm ID&V Method
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>

                         {
                             fieldsListsConfirmIDandv.map((field) =>
                                 <Row className="mt-3">
                                     <Col sm={3} xs={12}
                                         className="text-md-end text-start">{field?.title} </Col>
                                     <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                         {field?.value}
                                     </Col>
                                     <Col xs={0} sm={2} xl={3}></Col>
                                 </Row>
                             )
                         }

                     </AccordionPanel>
                 </AccordionItem>
                                     </Accordion>
                                   
                                 

                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button data-bs-toggle="collapse" data-bs-target="#CleintConsent"
                                                aria-expanded="false" aria-controls="CleintConsent"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button data-bs-toggle="collapse" data-bs-target="#SubmitApplication"
                                                aria-expanded="false" aria-controls="SubmitApplication"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>


                                </div>
                            </div>
                        </div> */}

                        {/* Submit Application page */}

                        {/* <div className="accordion-item">
                            <h2 className="accordion-header" id="heading10">
                                <button className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#SubmitApplication"
                                    aria-expanded="false" aria-controls="SubmitApplication">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Submit Application
                                    </Box>
                                </button>
                            </h2>
                            <div id="SubmitApplication" className="accordion-collapse collapse" aria-labelledby="heading10"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <Row className="mt-3">
                 <Col sm={4} xs={12} className="text-md-end text-start text-danger">Portal
                     text </Col>
                 <Col sm={6} xl={5} xs={12} className="fw-bold ">
                     Your KYC Application has now been accepted and is being processed. The bank
                     will contact you when
                     processing is completed.
                 </Col>
                 <Col xs={0} sm={2} xl={3}></Col>
                                     </Row>
                               
                                   
                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button data-bs-toggle="collapse" data-bs-target="#ConfirmIDandVMethod"
                                                aria-expanded="false" aria-controls="ConfirmIDandVMethod"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button data-bs-toggle="collapse" data-bs-target="#KYCDocumentsRequirements"
                                                aria-expanded="false" aria-controls="KYCDocumentsRequirements"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div> */}


                        {/* KYC Documents Requirements page*/}

                        {/* <div className="accordion-item">
                            <h2 className="accordion-header" id="heading11">
                                <button className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#KYCDocumentsRequirements"
                                    aria-expanded="false" aria-controls="KYCDocumentsRequirements">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        KYC Documents Requirements
                                    </Box>
                                </button>
                            </h2>
                            <div id="KYCDocumentsRequirements" className="accordion-collapse collapse"
                                aria-labelledby="heading11" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <Row className="mt-4">
                 <Col xs={12} sm={4} className="text-sm-end  fw-bold pt-1"> Bulk Action </Col>
                 <Col xs={6} sm={4} className="fw-bold ">
                     <Box>
                         <Autocomplete
                             style={selectstyle}
                             name="bulkAction"
                             onChange={bulkActionDocumentsRequirements}
                             size="small"
                             multiple
                             id="tags-outlined"
                             options={sempleoption}
                             getOptionLabel={(option) => option.title}
                             filterSelectedOptions
                             renderInput={(params) => (
                                 <TextField size="small" {...params} placeholder="Select" />
                             )}
                         />
                     </Box>

                 </Col>
                 <Col sm={4} xs={6}>
                     <Button
                         className="ps-5  pe-5 bg-light text-dark border-dark">Submit</Button>
                 </Col>
                                      </Row>
                                      <Row className="ms-md-2 ms-0  me-md-1 me-0 mt-4">
                 <InputGroup>
                     <InputGroup.Text id="basic-addon"><BiSearch size={25}
                         color="red" /></InputGroup.Text>
                     <Form.Control
                         placeholder="Username"
                         aria-label="Username"
                         aria-describedby="basic-addon1"
                     />
                     <InputGroup.Text id="basic-addon1"><FaSort size={20} color="red" />sort by
                         due <BsArrowDownShort size={25} /></InputGroup.Text>
                 </InputGroup>
                                      </Row>
                                      <Row className=" mt-2 ms-md-4 ms-0 me-md-3 me-0" style={{ backgroundColor: 'white' }}>
                 <Col sm={1} xs={2}
                     className="p-0 m-0 d-flex align-items-center  justify-content-center"
                     onClick={iconclick}>
                     {showToggleIconDocumentsRequirements ?
                         <BsChevronDown size={20} onClick={handleToggleIcon}
                             style={{ cursor: 'pointer' }} /> :
                         < BsChevronRight size={20} onClick={handleToggleIcon}
                             style={{ cursor: 'pointer' }} />}

                 </Col>
                 <Col xs={8} sm={10} className="p-0">
                     <Container className="p-0 m-0" fluid>
                         <Row className="">
                             <TableContainer component={Paper}
                                 sx={{ boxShadow: 'none', backgroundColor: 'white' }}>
                                 <Table size="small" border aria-label="a dense table">
                                     <TableHead>
                                         <TableRow
                                             sx={{ '&:last-child td, &:last-child th': { padding: 0 } }}>
                                             {
                                                 tabledata1DocumentsRequirements.map((tabledata) => {
                                                     return (
                                                         <>
                                                             <TableCell
                                                                 sx={{ padding: 0 }}>{tabledata.td}</TableCell>

                                                         </>
                                                     )
                                                 })
                                             }
                                         </TableRow>
                                     </TableHead>
                                     <TableBody>

                                         <TableRow
                                             sx={{ '&:last-child td, &:last-child th': { padding: 0 } }}>
                                             {
                                                 tabledata2DocumentsRequirements.map((tabledata) => {
                                                     return (
                                                         <>
                                                             <TableCell
                                                                 sx={{ padding: 0 }}>{tabledata.td}</TableCell>


                                                         </>
                                                     )
                                                 })
                                             }
                                         </TableRow>

                                     </TableBody>
                                 </Table>
                             </TableContainer>


                         </Row>


                     </Container>

                 </Col>
                 <Col xs={2} sm={1}
                     className="d-flex align-items-center  justify-content-center ">
                     <Dropdown>
                         <Avatar sx={{ bgcolor: "white", width: "30px", height: "30px" }}>
                             <Dropdown.Toggle
                                 variant="secondery"
                                 style={{ color: "white", marginRight: "32px" }}>
                                 <BsThreeDots size={20} color="black" id="fabell"
                                     style={{ position: "relative", left: "16px" }} />
                             </Dropdown.Toggle>
                         </Avatar>
                         <div className="">
                             <Dropdown.Menu className="kyc-dropdown">
                                 <Dropdown.Item className="">

                                     <Container className="">
                                         <Row className={"text-center pt-1 pb-2"}>
                                             <Col>
                                                 <Link className=" text-dark "
                                                     to="/panel2/documents">
                                                     Attach Document <br />Request Via Email <br />View
                                                     Request Details
                                                 </Link>
                                             </Col>
                                         </Row>
                                     </Container>
                                 </Dropdown.Item>
                             </Dropdown.Menu>
                         </div>
                     </Dropdown>


                 </Col>
                                      </Row>
                                      <Row className=" bg-light ms-md-4 ms-0 me-md-3 me-0">
                 <Collapse in={isOpen} animateOpacity>
                     <Box>
                         This document and ancillary supporting information is the propriety of
                         The
                         BENEFIT Company of Bahrain and should not be copied, used or related to
                         any
                         third party without the written permission of The BENEFIT Company or the
                         Central
                         Bank of Bahrain1. Specified the fields for mandatory, nullable,
                         conditional and
                         optional in each API: getCurrentKYCData,
                         recordConsentAndFetchSourceData, screenCase, resolveCase,
                         publishSignedCase, Upload API, Login API
                         2. Removing the statement “It will require a separate call for
                         fetching the token using the login API from the document server” as
                         there is one consolidate login API.
                         3. Changed Upload API file field to accept base64 format.
                         4. Timestamp is now specified to the standard UTC Format.
                         5. Specified the fields for mandatory, nullable, conditional and
                         optional for the Documents.
                         12th August 2020 0.7 1. Sample request and response of the APIs are
                         corrected to display
                         the description instead of the code.
                         2. BAH code is corrected to BHR. Refer to 6.13 GCC Country Codes
                         Lookup.
                         3. monthlyTurnOver, documentSource, documentDirection,
                         documentType fields are corrected to String type.
                         4. residentPermitExpiry field is corrected from long to DateTime
                         type.
                         5. Specified method to be used for Upload API
                         6. Specified file size and supported file extensions for Upload API
                         7. Removed policies field from the Upload API request

                         This document and ancillary supporting information is the propriety of
                         The
                         BENEFIT Company of Bahrain and should not be copied, used or related to
                         any
                         third party without the written permission of The BENEFIT Company or the
                         Central
                         Bank of Bahrain1. Specified the fields for mandatory, nullable,
                         conditional and
                         optional in each API: getCurrentKYCData,
                         recordConsentAndFetchSourceData, screenCase, resolveCase,
                         publishSignedCase, Upload API, Login API
                         2. Removing the statement “It will require a separate call for
                         fetching the token using the login API from the document server” as
                         there is one consolidate login API.
                         3. Changed Upload API file field to accept base64 format.
                         4. Timestamp is now specified to the standard UTC Format.
                         5. Specified the fields for mandatory, nullable, conditional and
                         optional for the Documents.
                         12th August 2020 0.7 1. Sample request and response of the APIs are
                         corrected to display
                         the description instead of the code.
                         2. BAH code is corrected to BHR. Refer to 6.13 GCC Country Codes
                         Lookup.
                         3. monthlyTurnOver, documentSource, documentDirection,
                         documentType fields are corrected to String type.
                         4. residentPermitExpiry field is corrected from long to DateTime
                         type.
                         5. Specified method to be used for Upload API
                         6. Specified file size and supported file extensions for Upload API
                         7. Removed policies field from the Upload API request
                     </Box>
                 </Collapse>
                                      </Row>
                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button data-bs-toggle="collapse" data-bs-target="#SubmitApplication"
                                                aria-expanded="false" aria-controls="SubmitApplication"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button data-bs-toggle="collapse" data-bs-target="#ValidateIDandV"
                                                aria-expanded="false" aria-controls="ValidateIDandV"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </div> */}

                        {/* Validate ID&V page */}

                        {/* <div className="accordion-item">
                            <h2 className="accordion-header" id="heading12">
                                <button className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#ValidateIDandV" aria-expanded="false"
                                    aria-controls="ValidateIDandV">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Validate ID&V
                                    </Box>
                                </button>
                            </h2>
                            <div id="ValidateIDandV" className="accordion-collapse collapse" aria-labelledby="heading12"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Manage Review
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>
                         {
                             fieldsListsValidateIDandv.map((field) =>
                                 <Row className="mt-3 ">
                                     <Col sm={3} xs={12}
                                         className="text-md-end text-start text-danger">{field?.title} </Col>
                                     <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                         {field?.value}
                                     </Col>
                                     <Col xs={0} sm={2} xl={3}></Col>
                                 </Row>
                             )
                         }

                     </AccordionPanel>
                 </AccordionItem>
                                        </Accordion>
                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button data-bs-toggle="collapse" data-bs-target="#KYCDocumentsRequirements"
                                                aria-expanded="false" aria-controls="KYCDocumentsRequirements"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button data-bs-toggle="collapse" data-bs-target="#CompleteALM"
                                                aria-expanded="false" aria-controls="CompleteALM"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>


                                </div>
                            </div>
                        </div> */}

                        {/* Complete ALM page */}

                        {/* <div className="accordion-item">
                            <h2 className="accordion-header" id="heading13">
                                <button className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#CompleteALM" aria-expanded="false"
                                    aria-controls="CompleteALM">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Complete ALM
                                    </Box>
                                </button>
                            </h2>
                            <div id="CompleteALM" className="accordion-collapse collapse" aria-labelledby="heading13"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Hierarchy
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>

                         <Row className="mt-2">
                             <Col>
                                 <Container className="border border-dark bg-light">
                                     <Row className="p-1">
                                         <Col sm={10} className="">
                                             <Container className=""
                                                 style={{ backgroundColor: "#d2d4d6" }}>
                                                 <Row className="d-flex mt-1 pt-2">
                                                     <Col md={3} sm={6} xs={12}
                                                         className="mt-lg-0 mt-2">
                                                         <Box>
                                                             <Autocomplete
                                                                 style={selectstyle}
                                                                 size="small"
                                                                 id="tags-outlined"
                                                                 name="country_of_residence"
                                                                 disableClearable={true}
                                                                 options={sempleoption}
                                                                 getOptionLabel={(option) => option.lable}
                                                                 filterSelectedOptions
                                                                 renderInput={(params) => (
                                                                     <TextField
                                                                         size="small" {...params}
                                                                         placeholder="Select" />
                                                                 )}
                                                             />
                                                         </Box>
                                                     </Col>
                                                     <Col md={3} sm={6} xs={12}
                                                         className="mt-lg-0 mt-2">
                                                         <Box>
                                                             <Autocomplete
                                                                 style={selectstyle}
                                                                 size="small"
                                                                 id="tags-outlined"
                                                                 name="country_of_residence"
                                                                 disableClearable={true}
                                                                 options={sempleoption}
                                                                 getOptionLabel={(option) => option.lable}
                                                                 filterSelectedOptions
                                                                 renderInput={(params) => (
                                                                     <TextField
                                                                         size="small" {...params}
                                                                         placeholder="Select" />
                                                                 )}
                                                             />
                                                         </Box>
                                                     </Col>
                                                     <Col md={3} sm={6} xs={12}
                                                         className="mt-lg-0 mt-2">
                                                         <Box>
                                                             <Autocomplete
                                                                 style={selectstyle}
                                                                 size="small"
                                                                 id="tags-outlined"
                                                                 name="country_of_residence"
                                                                 disableClearable={true}
                                                                 options={sempleoption}
                                                                 getOptionLabel={(option) => option.lable}
                                                                 filterSelectedOptions
                                                                 renderInput={(params) => (
                                                                     <TextField
                                                                         size="small" {...params}
                                                                         placeholder="Select" />
                                                                 )}
                                                             />
                                                         </Box>
                                                     </Col>
                                                     <Col md={3} sm={6} xs={12}
                                                         className="mt-lg-0 mt-2">
                                                         <Box>
                                                             <Autocomplete
                                                                 style={selectstyle}
                                                                 size="small"
                                                                 id="tags-outlined"
                                                                 name="country_of_residence"
                                                                 disableClearable={true}
                                                                 options={sempleoption}
                                                                 getOptionLabel={(option) => option.lable}
                                                                 filterSelectedOptions
                                                                 renderInput={(params) => (
                                                                     <TextField
                                                                         size="small" {...params}
                                                                         placeholder="Select" />
                                                                 )}
                                                             />
                                                         </Box>
                                                     </Col>
                                                 </Row>
                                                 <Row className="mt-2 mb-1 pb-2">
                                                     <Col className=""><Button
                                                         className="bg-light text-dark border-secondary me-2"
                                                         style={{ borderRadius: "1px" }}>REP</Button>
                                                         <Button
                                                             className="bg-light text-dark border-secondary"
                                                             style={{ borderRadius: "1px" }}>High
                                                             Risk</Button></Col>
                                                 </Row>
                                             </Container>
                                         </Col>
                                         <Col sm={2}
                                             className=" d-flex align-items-center text-center">
                                             <Container className=" ">
                                                 <Row className=" ">
                                                     <Col xs={3} sm={12} md={6} lg={3}><BiMenu
                                                         size={20} /></Col>
                                                     <Col xs={3} sm={12} md={6} lg={3}><BiMenu
                                                         size={20} /></Col>
                                                     <Col xs={3} sm={12} md={6} lg={3}><BiMenu
                                                         size={20} /></Col>
                                                     <Col xs={3} sm={12} md={6} lg={3}><BiMenu
                                                         size={20} /></Col>
                                                 </Row>
                                             </Container>
                                         </Col>

                                     </Row>
                                     <Row className="border-top border-dark"
                                         style={{ height: "100vh", overflowY: "scroll" }}>
                                         <Col style={{
                                             display: "flex",
                                             alignItems: "center",
                                             justifyContent: "center"
                                         }}><BsFillHexagonFill color="green" /></Col>
                                     </Row>
                                 </Container>
                             </Col>
                         </Row>
                     </AccordionPanel>
                 </AccordionItem>
                                      </Accordion>

                                      <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Assessments
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>

                         <Row className="mt-2">
                             <Col className=" ">
                                 <Table responsive
                                     className="table table-striped border  border-secondary">
                                     <thead className="">
                                         <tr>
                                             {
                                                 thdataCompleteALM.map((th) => {
                                                     return (
                                                         <>
                                                             <th className="Fontsize13">{th.thname}</th>

                                                         </>
                                                     )
                                                 })
                                             }

                                         </tr>
                                     </thead>
                                     <tbody>

                                         <tr>
                                             {
                                                 tddataCompleteALM.map((td) => {
                                                     return (
                                                         <>
                                                             <td>{td.tdname}</td>

                                                         </>
                                                     )
                                                 })
                                             }
                                         </tr>

                                     </tbody>
                                 </Table>

                             </Col>
                         </Row>

                     </AccordionPanel>
                 </AccordionItem>
                                      </Accordion>

                                      <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Documents
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>


                     </AccordionPanel>
                 </AccordionItem>
                                      </Accordion>

                                      <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Comments
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>


                     </AccordionPanel>
                 </AccordionItem>
                                      </Accordion>

                                      <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Confirm Matches
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>

                         {
                             fieldsLists1CompleteALM.map((field) =>
                                 <Row className="mt-3">
                                     <Col sm={3} xs={12}
                                         className="text-md-end text-start text-danger">{field?.title} </Col>
                                     <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                         {field?.value}
                                     </Col>
                                     <Col xs={0} sm={2} xl={3}></Col>
                                 </Row>
                             )
                         }
                     </AccordionPanel>
                 </AccordionItem>
                                      </Accordion>

                                      <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Assessments Disicion
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>
                         {
                             fieldsLists2CompleteALM.map((field) =>
                                 <Row className="mt-3">
                                     <Col sm={3} xs={12}
                                         className="text-md-end text-start text-danger">{field?.title} </Col>
                                     <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                         {field?.value}
                                     </Col>
                                     <Col xs={0} sm={2} xl={3}></Col>
                                 </Row>
                             )
                         }

                     </AccordionPanel>
                 </AccordionItem>
                                      </Accordion>
                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button data-bs-toggle="collapse" data-bs-target="#ValidateIDandV"
                                                aria-expanded="false" aria-controls="ValidateIDandV"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button data-bs-toggle="collapse" data-bs-target="#RiskAssessments"
                                                aria-expanded="false" aria-controls="RiskAssessments"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>


                                </div>
                            </div>
                        </div> */}

                        {/* Complete Risk Assessments page */}

                        {/*        <div className="accordion-item">
                            <h2 className="accordion-header" id="heading14">
                                <button className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#RiskAssessments"
                                    aria-expanded="false" aria-controls="RiskAssessments">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Complete Risk Assessments
                                    </Box>
                                </button>
                            </h2>
                            <div id="RiskAssessments" className="accordion-collapse collapse" aria-labelledby="heading14"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Legal Entity Details
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>


                     </AccordionPanel>
                 </AccordionItem>
                                      </Accordion>
                                      <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Country Risk
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>
                         <Row className="mt-3">
                             <Col xs={12} sm={3} className="text-sm-end ">Nationality: </Col>
                             <Col xs={12} sm={9} className="fw-bold ">
                                 {data?.nonIGAFields?.primary?.nationality || "-"}
                             </Col>
                         </Row>
                         <Row className="mt-3">
                             <Col xs={12} sm={3} className="text-sm-end text-danger">Risk
                                 Category:
                             </Col>
                             <Col xs={6} sm={2} className="fw-bold ">
                                 Low
                             </Col>
                             <Col xs={6} sm={7}><BsPencil onClick={clickEdit}
                                 style={{ cursor: "pointer" }} size={20} /></Col>
                         </Row>
                         <Row className="mt-3">
                             <Col xs={12} sm={3} className="text-sm-end text-danger">Residential
                                 Address: </Col>
                             <Col xs={12} sm={9} className="fw-bold ">
                                 Bahrain
                             </Col>
                         </Row>
                         <Row className="mt-3">
                             <Col xs={12} sm={3} className="text-sm-end text-danger">Risk
                                 Category:
                             </Col>
                             <Col xs={6} sm={2} className="fw-bold ">
                                 Low
                             </Col>
                             <Col xs={6} sm={7}><BsPencil onClick={clickEdit}
                                 style={{ cursor: "pointer" }} size={20} /></Col>
                         </Row>



                         <Row className="mt-3">
                             <Col sm={3} xs={12}
                                 className="text-sm-end text-start text-danger">Notes</Col>
                             <Col sm={7} xl={5} xs={12} className="fw-bold ">

                                 <Form.Control style={{ borderRadius: "0px" }}
                                     onChange={handlechangeRiskAssessments}
                                     name="notes1"
                                     value={bodyDataRiskAssessments.notes1}
                                     as="textarea" rows={3} />

                             </Col>
                             <Col xs={0} sm={2} xl={3}></Col>
                         </Row>
                     </AccordionPanel>
                 </AccordionItem>
                                      </Accordion>
                                      <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Entity Risk
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>
                         <Row className="mt-3">
                             <Col xs={12} sm={3} className="text-sm-end text-danger">Legal Entity
                                 Type: </Col>
                             <Col xs={12} sm={9} className="fw-bold ">
                                 Individual
                             </Col>
                         </Row>
                         <Row className="mt-3">
                             <Col xs={12} sm={3} className="text-sm-end text-danger"> Risk
                                 Category:
                             </Col>
                             <Col xs={6} sm={2} className="fw-bold ">
                                 Low
                             </Col>
                             <Col xs={6} sm={7}><BsPencil onClick={clickEdit}
                                 style={{ cursor: "pointer" }} size={20} /></Col>
                         </Row>

                         <Row className="mt-3">
                             <Col xs={12} sm={3} className="text-sm-end text-danger">ALM
                                 Assessments Risk: </Col>
                             <Col xs={12} sm={9} className="fw-bold ">
                                 Low
                             </Col>
                         </Row>
                         <Row className="mt-3">
                             <Col xs={12} sm={3} className="text-sm-end text-danger"> Risk
                                 Category:
                             </Col>
                             <Col xs={6} sm={2} className="fw-bold ">
                                 Low
                             </Col>
                             <Col xs={6} sm={7}><BsPencil onClick={clickEdit}
                                 style={{ cursor: "pointer" }} size={20} /></Col>
                         </Row>


                         <Row className="mt-3">
                             <Col xs={12} sm={3} className="text-sm-end text-danger">Length Of
                                 Relationship: </Col>
                             <Col xs={12} sm={9} className="fw-bold ">
                                 0 Years 0 Month 0 Day
                             </Col>
                         </Row>
                         <Row className="mt-3">
                             <Col xs={12} sm={3} className="text-sm-end text-danger"> Risk
                                 Category:
                             </Col>
                             <Col xs={6} sm={2} className="fw-bold ">
                                 High
                             </Col>
                             <Col xs={6} sm={7}><BsPencil onClick={clickEdit}
                                 style={{ cursor: "pointer" }} size={20} /></Col>
                         </Row>

                         <Row className="mt-3">
                             <Col sm={3} xs={12}
                                 className="text-sm-end text-start text-danger">PEPs/Sanctions: </Col>
                             <Col sm={9} xs={12} className="fw-bold ">
                                 No.
                             </Col>
                         </Row>
                         <Row className="mt-3">
                             <Col sm={3} xs={12}
                                 className="text-sm-end text-start text-danger"> Risk Category:
                             </Col>
                             <Col sm={9} xs={12} className=" ">
                                 -
                             </Col>
                         </Row>

                         <Row className="mt-3">
                             <Col sm={3} xs={12}
                                 className="text-sm-end text-start text-danger">Notes</Col>
                             <Col sm={7} xl={5} xs={12} className="fw-bold ">

                                 <Form.Control style={{ borderRadius: "0px" }}
                                     onChange={handlechangeRiskAssessments}
                                     name="notes2"
                                     value={bodyDataRiskAssessments.notes2}
                                     as="textarea" rows={3} />

                             </Col>
                             <Col xs={0} sm={2} xl={3}></Col>
                         </Row>

                     </AccordionPanel>
                 </AccordionItem>
                                      </Accordion>
                                      <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Country Risk
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>
                         <Row className="mt-3">
                             <Col sm={3} xs={12}
                                 className="text-sm-end text-start text-danger"> Risk Category:
                             </Col>
                             <Col sm={9} xs={12} className=" ">
                                 -
                             </Col>
                         </Row>


                         <Row className="mt-3">
                             <Col sm={3} xs={12}
                                 className="text-sm-end text-start text-danger">Notes</Col>
                             <Col sm={7} xl={5} xs={12} className="fw-bold ">

                                 <Form.Control style={{ borderRadius: "0px" }}
                                     onChange={handlechangeRiskAssessments}
                                     name="notes3"
                                     value={bodyDataRiskAssessments.notes3}
                                     as="textarea" rows={3} />

                             </Col>
                             <Col xs={0} sm={2} xl={3}></Col>
                         </Row>

                     </AccordionPanel>
                 </AccordionItem>
                                      </Accordion>

                                    <Row className="h5 mt-5 mb-5">
                                        <Col className="">
                                            <Button data-bs-toggle="collapse" data-bs-target="#CompleteALM" aria-expanded="false"
                                                aria-controls="CompleteALM"
                                                className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="me-3 text-end">
                                            <Button data-bs-toggle="collapse" data-bs-target="#BusinessReview"
                                                aria-expanded="false" aria-controls="BusinessReview"
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>


                                </div>
                            </div>
                        </div> */}

                        {/* Business Review And Sign-Off page */}

                        {/* <div className="accordion-item">
                            <h2 className="accordion-header" id="heading15">
                                <button className="accordion-button collapsed p-1 accordion-header-design" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#BusinessReview" aria-expanded="false"
                                    aria-controls="BusinessReview">
                                    <Box className="h5" as="div" flex='1' textAlign='left'>
                                        Business Review And Sign-Off
                                    </Box>
                                </button>
                            </h2>
                            <div id="BusinessReview" className="accordion-collapse collapse" aria-labelledby="heading15"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Manager Review
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>
                         {
                             fieldsListsBusinessReview.map((field) =>
                                 <Row className="mt-3">
                                     <Col sm={3} xs={12}
                                         className="text-md-end text-start">{field?.title} </Col>
                                     <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                         {field?.value}
                                     </Col>
                                     <Col xs={0} sm={2} xl={3}></Col>
                                 </Row>
                             )
                         }


                         <Row className="h5 mt-5 mb-5">
                             <Col className="">
                                 <Button data-bs-toggle="collapse"
                                     data-bs-target="#RiskAssessments" aria-expanded="false"
                                     aria-controls="RiskAssessments"
                                     className="ms-3 ps-4 pe-4 btn-secondary buttonstyle">
                                     Previous
                                 </Button>
                             </Col>
                             <Col className="me-3 text-end">
                                 <Button onClick={handlesubmit}
                                     type="submit"
                                     className="ps-4 pe-4 btn-secondary buttonstyle">
                                     {btnNameSubmit}
                                 </Button>
                             </Col>
                         </Row>


                     </AccordionPanel>
                 </AccordionItem>
                                       </Accordion>
                                       <Accordion className="mt-5 mb-" defaultIndex={[0]} allowMultiple>
                 <AccordionItem>
                     <Row className="border-bottom border-dark">
                         <AccordionButton
                             style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                             <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                 Tasks
                             </Box>
                             <AccordionIcon />
                         </AccordionButton>
                     </Row>
                     <AccordionPanel pb={4}>

                         <Table responsive className="mt-2 table table-striped">
                             <thead>
                                 <tr>
                                     {
                                         thdataBusinessReview.map((th) => {
                                             return (
                                                 <>
                                                     <th className="Fontsize13">{th.thname}</th>

                                                 </>
                                             )
                                         })
                                     }

                                 </tr>
                             </thead>
                             <tbody>

                                 <tr>
                                     {
                                         tddataBusinessReview.map((td) => {
                                             return (
                                                 <>
                                                     <td className="Fontsize13">{td.tdname}</td>

                                                 </>
                                             )
                                         })
                                     }
                                 </tr>

                             </tbody>
                         </Table>

                     </AccordionPanel>
                 </AccordionItem>
                                       </Accordion>

                                </div>
                            </div>
                        </div> */}



                    </div>




                </form>
            </Container>

        </>
    )
}
export default Formswapper