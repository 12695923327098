import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Alert, Autocomplete, Box, CircularProgress, Snackbar, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Apicall, getCountryCodeByGccId, LogTime, sessionLogout } from "../../Api/UseFeitch";
import { useState } from "react";
import { withStyles } from '@material-ui/styles';
import { inputStyle } from "../helpingFunction/helpingInputData";


const EnterEntityDetail = ({ adminData, setAdminData, data, setData, newRequestStep, setNewRequestStep }) => {
    let timeout;
    const [btnNameSubmit, setBtnNameSubmit] = useState("Search");
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [view_by_sources, setView_by_sources] = useState([])
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [loading, setLoading] = useState(false);

    const [bodyData, setBodyData] = useState({
        firstNameEn: "",
        lastNameEn: "",
        gccId: "",
        viewBySources: ""
    });
    const errorMessage = (message) => {
        setMessage(message || "An error encounter");
        setOpen(true);
        setBtnNameSubmit("Search");
        setLoading(false);
    }
    const successMessage = (message) => {
        setMessage(message || "Ok Proceed");
        setOpen2(true);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value });
    }

    const handleselect = (event, view_by_sources) => {
        setBodyData({ ...bodyData, viewBySources: view_by_sources.value });
        // console.log("bodyData.viewBySources", bodyData.viewBySources)
    }

    const handleClose = () => {
        setOpen(false);
        setOpen2(false);
    }

    function alertFunc() {
        setLoading(false);
        setBtnNameSubmit("Search");
        navigate("/panel1/search-for-duplecates" + "?token=1&step=1");
    }

    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
        let loginTime = LogTime();
        if (loginTime === false) {
            navigate("/");
            sessionLogout();
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);
    async function handleclick(e) {
        e.preventDefault();
        setLoading(true);
        let cardCountry = await getCountryCodeByGccId(bodyData.gccId);
        if (!cardCountry) {
            errorMessage("GccId/nationalId is not valid");
            return;
        }
        // console.log(cardCountry);
        setBtnNameSubmit(<Spinner animation="border" className="btn-pointer-color" style={{ height: "22px", width: "22px" }} />);
        let resultt = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "GCC",
                    GCC: {
                        cardCountry: cardCountry,
                        nationalID: bodyData.gccId,
                        ...bodyData, viewBySources: view_by_sources
                    }
                },
            },

        })
        // console.log("resultt-----------", resultt)
        if (resultt?.messageStatus === "OK") {
            setData(resultt);
            setAdminData({
                cardCountry: cardCountry,
                nationalID: bodyData.gccId,
                type: "GCC"
            })
            successMessage(resultt.errorDescription);
            timeout = setTimeout(alertFunc, 2000);
            // setBtnNameSubmit("Search");
        } else {
            errorMessage(resultt?.errorDescription || "Encounter");
            // setBtnNameSubmit( <Spinner animation="border" variant="danger" />)
            // setBtnNameSubmit("Search");
        }
    }

    const Viewbysources = [
        { lable: 'Salary', id: 1, value: "Salary" },
        { lable: 'Inheritance', id: 2, value: "Inheritance" },
        { lable: 'Business Ownership', id: 3, value: "Business Ownership" },
        { lable: 'Investments', id: 4, value: "Investments" },
        { lable: 'Others', id: 5, value: "Others" },
    ];

    const selectstyle = {
        backgroundColor: 'white',
        borderRadius: '0px'
    };

    // console.log("dataaaaaaaaaaaaaa", data)

    const CssTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: 'var(--primary)',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: 'yellow',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'dark',
                },
                '&:hover fieldset': {
                    //   borderColor: 'dark',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'var(--primary)',
                },
                '&.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {
                    // backgroundColor:'var(--feild-bg-color)'
                }
            },
        },
    })(TextField);


    const fieldsLists = [
        {
            title: "First Name ",
            value: <Box >
                {/* <Form.Control
                    onChange={handleChange}
                    name="firstNameEn"
                    value={bodyData.firstNameEn}
                    className="borderGray"
                    type="text"
                    aria-describedby="passwordHelpBlock"
                /> */}

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    onChange={handleChange}
                    name="firstNameEn"
                    value={bodyData.firstNameEn}
                    type="text" />
            </Box>,
        },
        {
            title: "Last Name",
            value: <Box>
                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    onChange={handleChange}
                    name="lastNameEn"
                    value={bodyData.lastNameEn}
                    type="text" />


            </Box>,
        },
        {
            title: "GCC ID  ",
            value: <Box>
                {/* <Form.Control
                    onChange={handleChange}
                    name="gccId"
                    value={bodyData.gccId}
                    type="text"
                /> */}

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="GCC ID"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    onChange={handleChange}
                    name="gccId"
                    value={bodyData.gccId}
                    type="text" />

            </Box>,
        },
        {
            title: "View by sources",
            value: <Box>

                <Autocomplete
                    onChange={handleselect}
                    name="viewBySources"
                    //    value={selectdata.view_by_sources}
                    size="small"
                    id="tags-outlined"
                    options={Viewbysources}
                    disableClearable={true}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success"  {...params} label="View by sources" />
                    )}
                />
            </Box>,
        },


    ];


    return (
        <>

            <Container fluid id="scroleble_main_content">


                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>


                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open2} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-success text-light" severity="success"
                        sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>

                <Row className=" border-bottom border-dark"><Col className="h3 fw-light mt-4 ms-4">Enter Entity
                    Details</Col> </Row>


                {
                    fieldsLists.map((field) =>
                        <Row className="mt-3">
                            <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                            <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                {field?.value}
                            </Col>
                            <Col xs={0} sm={2} xl={3}></Col>
                        </Row>
                    )
                }


                <Row className="mt-5">
                    <Col className="text-end me-5">
                        <Button onClick={handleclick} className="ps-4 pe-4  btn-secondary buttonstyle">
                            {btnNameSubmit}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default EnterEntityDetail;