import {Avatar} from "@mui/material";
import React, {useContext, useState} from "react";
import {Col, Container, Dropdown, DropdownButton, Row} from "react-bootstrap";
import {
    BiBell,
    BiChat,
    BiLockAlt,
    BiLogOut,
    BiLogOutCircle,
    BiMenu,
    BiSearch,
    BiSpreadsheet,
    BiGroup
} from "react-icons/bi";
import {BsPlusCircle, BsThreeDots, BsPersonCircle} from "react-icons/bs";
import {Link} from "react-router-dom";
// import "../globle.css"

const HeaderIcons2 = ({setNewRequestStep, setNewstep, setGroupPerentstep}) => {
    const [dropMenu, setDropMenu] = useState(1);
    const handlechange = (val) => {
        setDropMenu(val);
    }

    const removesesiondata = () => {
        sessionStorage.removeItem("tokenSessionStorage");
        // setNewRequestStep(0);
        // setGroupPerentstep(0);
        // setNewstep(0);
    }
    return (
        <>
            <div className=" ms-3 mb-2 d-flex">version : KYC (1.0.2.3)</div>
            <ul className=" navbar-nav d-flex flex-row me-1  ps-2">
                {/* dropdown 1 */}

                {/* <Avatar sx={{ bgcolor: "#f0f2f5", width: "30px", height: "30px" }} className="m-1 p-1 "><BiSearch id="bisearch" color="#000000" size={20} /></Avatar> */}
                {/* dropdown 1*/}
                {/* <li className="nav-item me-md-3 me-0 ">
                    <Dropdown style={{ width: "10%" }}>
                        <Avatar id="nav_icon" sx={{ bgcolor: "#e3e4e6", width: "30px", height: "30px" }} className="m-1">
                            <Dropdown.Toggle
                                variant="secondery" style={{ color: "white", marginRight: "32px" }} >
                                <BiSearch size={20} color="black" id="fabell" style={{ position: "relative", left: "16px" }} />
                            </Dropdown.Toggle>
                        </Avatar>
                        <div className="" >
                            <Dropdown.Menu id="header-dropdown-menu1" >
                                <Dropdown.Item >
                                    <Container className="" >
                                        <Row className={"dropdownitems border-bottom text-center ps-0"}>
                                            <Col  className="pb-2 pt-1 ">
                                                <Link
                                                    onClick={() => handlechange(1)}
                                                    className="text-decoration-none text-dark ">
                                                    Legal Entity
                                                </Link>
                                            </Col>
                                        </Row>

                                        <Row className={"dropdownitems border-bottom text-center ps-0"}>
                                            <Col  className="pb-2 pt-1 ">
                                                <Link
                                                    onClick={() => handlechange(1)}
                                                    className="text-decoration-none text-dark ">
                                                    Case
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Row className={"dropdownitems border-bottom text-center ps-0"}>
                                            <Col  className="pb-2 pt-1 ">
                                                <Link
                                                    onClick={() => handlechange(1)}
                                                    className="text-decoration-none text-dark ">
                                                    Document
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Row className="dropdownitems text-center">
                                            <Col  className="pb-2 pt-1 ">
                                                <Link
                                                    onClick={() => handlechange(2)}
                                                    className="text-decoration-none text-dark"
                                                >Product</Link>
                                            </Col>
                                        </Row>
                                    </Container>

                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </div>
                    </Dropdown>
                </li>*/}

                {/* dropdown 2 */}

                <li className="nav-item me-md-3 me-0 ">
                    <Dropdown style={{width: "10%"}}>
                        <Avatar id="nav_icon" sx={{bgcolor: "#e3e4e6", width: "30px", height: "30px"}} className="m-1">
                            <Dropdown.Toggle
                                variant="secondery" style={{color: "white", marginRight: "32px"}}>
                                <BsPlusCircle size={20} color="black" id="fabell"
                                              style={{position: "relative", left: "16px"}}/>
                            </Dropdown.Toggle>
                        </Avatar>
                        <div className="">
                            <Dropdown.Menu id="header-dropdown-menu2">
                                <Dropdown.Item className="">
                                    <Container className="">
                                        <Row className={"dropdownitems border-bottom text-center pt-1  pb-2 "}>
                                            <Col>
                                                <Link
                                                    onClick={() => handlechange(1)}
                                                    className="text-decoration-none text-dark"
                                                    to="/">New Request</Link>
                                            </Col>
                                        </Row>
                                        {/* <Row className={"dropdownitems text-center pt-1 pb-2"}>
                                            <Col>
                                                <Link
                                                    onClick={() => handlechange(2)}
                                                    className="text-decoration-none text-dark"
                                                    to="/panel2">New Group</Link>
                                            </Col>
                                        </Row>*/}
                                    </Container>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </div>
                    </Dropdown>

                </li>
                {/* dropdown 3 */}

                <li className="nav-item me-md-3 me-0 ">
                    {/* <Avatar id="nav_icon" sx={{ bgcolor: "#e3e4e6", width: "30px", height: "30px" }} className="m-1">< BsPersonCircle color="black" size={20} /></Avatar> */}


                    <Dropdown>
                        <Avatar id="nav_icon" sx={{bgcolor: "#e3e4e6", width: "30px", height: "30px"}} className="m-1">
                            <Dropdown.Toggle
                                variant="secondery" style={{color: "white", marginRight: "32px"}}>
                                <BsPersonCircle size={20} color="black" id="fabell"
                                                style={{position: "relative", left: "16px"}}/>
                            </Dropdown.Toggle>
                        </Avatar>
                        <div className="">
                            <Dropdown.Menu id="header-dropdown-menu4">
                                <Dropdown.Item className="">
                                    <div>
                                        <Container fluid className="p-0">
                                            <Link className="text-decoration-none text-dark">
                                                <Row onClick={removesesiondata}
                                                     className="dropdownitems m-0 border-bottom text-center pb-1 pt-1">
                                                    <Col>
                                                        Logout
                                                    </Col>
                                                </Row>
                                            </Link>
                                        </Container>

                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </div>
                    </Dropdown>


                </li>
                {/* dropdown 4 */}

                {/* <li className="nav-item me-md-3 me-0 ">

                    <Dropdown>
                        <Avatar id="nav_icon" sx={{bgcolor: "#e3e4e6", width: "30px", height: "30px"}} className="m-1">
                            <Dropdown.Toggle
                                variant="secondery" style={{color: "white", marginRight: "32px"}}>
                                <BsThreeDots size={20} color="black" id="fabell"
                                             style={{position: "relative", left: "16px"}}/>
                            </Dropdown.Toggle>
                        </Avatar>
                        <div className="">
                            <Dropdown.Menu id="header-dropdown-menu3">
                                <Dropdown.Item className="">

                                    <div id="headerdropdown">
                                        <Container fluid className="pb-0">
                                            <Row className="dropdownitems border-bottom text-center pb-1 pt-1">
                                                <Col>
                                                    <Link
                                                        className="text-decoration-none text-dark"
                                                    >Report</Link>
                                                </Col>
                                            </Row>


                                        </Container>

                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </div>
                    </Dropdown>


                </li>*/}
            </ul>
        </>
    )
}
export default HeaderIcons2;