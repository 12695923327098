import { Alert, Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, InputGroup, Row } from "react-bootstrap";
import { Apicall, loginApiCall } from "../Api/UseFeitch";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import logo from "./images/Fwd_/bank.jpg"
import { sha512 } from "js-sha512";

const LoginForm = () => {
    let tokenSessionStorage = "";
    let timeSessionStorage = "";

    useEffect(() => {
        tokenSessionStorage = sessionStorage.getItem("tokenSessionStorage");
        timeSessionStorage = sessionStorage.getItem("timeSessionStorage");
    }, [])
    let timeout;
    const navigate = useNavigate();
    const [passwordType, setPasswordType] = useState("password");
    const [message, setMessage] = useState("");
    const [bodyData, setBodyData] = useState({ userId: "", password: "" });
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [btnName, setBtnName] = useState("Login");
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setBodyData({ ...bodyData, [e.target.name]: e.target.value });
    }

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const errorMessage = (message) => {
        setMessage(message || "An error encounter");
        setOpen2(true);
        setLoading(false);
        setBtnName("Login");
    }
    const successMessage = (message) => {
        setMessage(message || "Ok Proceed");
        setOpen(true);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        setBtnName("Loading.....");

        if (!bodyData.userId) {
            errorMessage("Account Username is required to continue");
        } else if (!bodyData.password) {
            errorMessage("Account password is required to continue");
        } else {
            // console.log("password before", bodyData.password);
            const pwd = await sha512(bodyData.password);
            // console.log("password after", pwd);
            let response = await loginApiCall("login", {
                userId: bodyData.userId,
                password: pwd,
                language: "EN"
            });
            // console.log("Login Response", response);
            if (response?.token) {
                sessionStorage.setItem("tokenSessionStorage", response.token);
                sessionStorage.setItem("timeSessionStorage", new Date());
                // console.log("token--------", sessionStorage.getItem("tokenSessionStorage"));
                // console.log("timeSessionStorage--------", sessionStorage.getItem("timeSessionStorage"));
                successMessage(response?.errorDescription || "Login successfully completed")
                timeout = setTimeout(alertFunc, 2000);
            } else {
                errorMessage(response?.errorDescription || "Invalid credentials. Please try again.");
            }
        }
    }

    function alertFunc() {
        navigate("/panel1");
        setLoading(false);
        setBtnName("Login");
    }

    return (
        <>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open2} autoHideDuration={6000}
                onClose={handleClose}>
                <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000}
                onClose={handleClose}>
                <Alert onClose={handleClose} className="bg-success text-light" severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Container fluid>

                <Row className="" style={{ marginTop: "13vh", marginBottom: "13vh" }}>
                    <Col sm={2} md={3} lg={3} xl={4}></Col>
                    <Col sm={8} md={6} lg={6} xl={4}>
                        <form onSubmit={handleSubmit}>
                            <Card>
                                <Card.Body>
                                    <Container className="">
                                        <Row xs={{ cols: 1 }}>
                                            <Col className="h5 text-center mt-2"> <Image className="w-xs-50 w-75" src={logo} /></Col>
                                            <Col className="h4 text-center mt-2">Welcome to eKYC</Col>
                                            <Col className="h6 text-center">Please submit following details to login
                                                your account.</Col>
                                        </Row>

                                        <Row xs={{ cols: 1 }} className="ms-1 me-1 mt-3">
                                            {/* <Col className="fw-bold">Account Username<span
                                                className="ps-1 text-danger fw-bold">*</span></Col>
                                            <Col className=" ">
                                                <Form.Control autoComplete={"false"} className="borderGray " type="text"
                                                    name="userId" onChange={handleChange}
                                                    value={bodyData?.username} />
                                            </Col> */}
                                            <TextField
                                                fullWidth
                                                className="input-field"
                                                color="success"
                                                size="small"
                                                label="Account Username"
                                                variant="outlined"
                                                onChange={handleChange}
                                                name="userId"
                                                value={bodyData?.userId}
                                                type="text" />
                                        </Row>
                                        <Row xs={{ cols: 1 }} className="ms-1 me-1 mt-3 ">
                                            {/* <Col className="fw-bold">Password<span
                                                className="ps-1 text-danger fw-bold">*</span></Col>
                                            <Col className=" ">
                                                <InputGroup>
                                                    <Form.Control className="borderGray" type={passwordType}
                                                        onChange={handleChange} name="password" />
                                                    <InputGroup.Text className="basic-addon1"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={togglePassword}>{passwordType === 'password' ?
                                                            <BsFillEyeSlashFill /> : <BsFillEyeFill />}</InputGroup.Text>
                                                </InputGroup>
                                            </Col> */}

                                            <FormControl size="small" variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type={passwordType}
                                                    onChange={handleChange}
                                                    name="password"
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={togglePassword}
                                                                edge="end"
                                                            >
                                                                {passwordType === 'password' ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>
                                        </Row>


                                        <Row className="mt-3 ">
                                            <Col className={'d-flex'}>
                                                <Form.Check type="checkbox" name="check" id="checkboxRememberMe"
                                                    className=" pe-2" />
                                                <Form.Label htmlFor={"checkboxRememberMe"}>Remember me</Form.Label>
                                            </Col>
                                            {/* <Col xs={5} style={{ cursor: "pointer" }} className=" text-primary text-center">
                                            Reset Password
                                        </Col> */}
                                        </Row>

                                        <Row className="mt-5 mb-4">
                                            <Col className=" ">
                                                <Button style={{ borderRadius: "5px", backgroundColor: 'var(--primary)' }} disabled={loading}
                                                    className="ps-2  me-2 w-100 btn border-0 "
                                                    type={"submit"}
                                                    /*onClick={handleSubmit}*/>{btnName}</Button>
                                            </Col>
                                        </Row>
                                        {/* 
                                        <Row>

                                            <FormControl size="small" variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type={passwordType}
                                                    onChange={handleChange}
                                                    name="password"
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={togglePassword}
                                                                edge="end"
                                                            >
                                                                {passwordType === 'password' ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>



                                            <TextField
                                                fullWidth
                                                className="input-field"
                                                color="success"
                                                size="small"
                                                label="First Name"
                                                variant="outlined"
                                                onChange={handleChange}
                                                name="userId"
                                                // value={bodyData?.userId}
                                                type="text" />
                                        </Row> */}


                                        {/* <Row className="mt-5 mb-4 ps-1 pe-1 ">
                                <Box sx={{ width: 420 }}>
                                <Button className="ps-2  me-2 w-100 btn  btn-secondary buttonstyle"  onClick={handleSubmit}> Login</Button>
                                    </Box>
                                </Row> */}
                                        {/* <Row className="mt-5 mb-4 ps-1 pe-1">
                                    <Col className=" ms-2 me-2 btn  btn-secondary buttonstyle"  onClick={handleSubmit}>
                                        Login
                                    </Col>
                                </Row> */}
                                    </Container>
                                </Card.Body>
                            </Card>
                        </form>
                    </Col>
                    <Col sm={2} md={3} lg={3} xl={4}></Col>

                </Row>
            </Container>
        </>
    )
}
export default LoginForm;