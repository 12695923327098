import {Avatar} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Dropdown, DropdownButton, Row} from "react-bootstrap";
import {
    BiBell,
    BiChat,
    BiLockAlt,
    BiLogOut,
    BiLogOutCircle,
    BiMenu,
    BiSearch,
    BiSpreadsheet,
    BiGroup
} from "react-icons/bi";
import {BsPlusCircle, BsThreeDots, BsPersonCircle} from "react-icons/bs";
import {Link, useNavigate} from "react-router-dom";
// import "../globle.css"


const HeaderIcons = ({setNewRequestStep, newRequestStep, setNewstep, setGroupPerentstep}) => {
    const navigate = useNavigate();

    const handleclick1 = () => {
        setNewRequestStep(0);
        navigate("/panel1");
    }
    const handleclick2 = () => {
        // setNewRequestStep(0);
        navigate("/panel2");
    }


    const removesesiondata = () => {
        sessionStorage.removeItem("tokenSessionStorage");
        // setNewRequestStep(0);
        // setNewstep(0);
        // setGroupPerentstep(0);
    }


    return (
        <>

            <div className=" d-md-flex d-none ms-auto border-end pe-2 mt-3">
                <pre style={{fontFamily: "arial"}}>version : KYC (1.0.2.3)</pre>
            </div>
            <div className="d-md-block d-none">
                <ul className="  navbar-nav d-flex flex-row me-1  ps-2">

                    {/* <Avatar sx={{ bgcolor: "#f0f2f5", width: "30px", height: "30px" }} className="m-1 p-1 "><BiSearch id="bisearch" color="#000000" size={20} /></Avatar> */}
                    {/* dropdown 1*/}

                    {/* <li className="nav-item me-sm-3 me-0 ">

                        <Dropdown style={{ width: "10%" }}>
                            <Avatar sx={{ bgcolor: "#e3e4e6", width: "30px", height: "30px" }} className="m-1">
                                <Dropdown.Toggle
                                    variant="secondery" style={{ color: "white", marginRight: "32px" }} >
                                    <BiSearch size={20} color="black" id="fabell" style={{ position: "relative", left: "17px" }} />
                                </Dropdown.Toggle>
                            </Avatar>
                            <div className="" >
                                <Dropdown.Menu id="header-dropdown-menu1" >
                                    <Dropdown.Item >
                                        <Container className="p-0" >
                                            <Link className="text-decoration-none text-dark ">
                                                <Row className={"dropdownitems border-bottom text-center p-0 m-0"}>
                                                    <Col className="pb-2 pt-1 ps-0 me-0">
                                                        Legal Entity
                                                    </Col>
                                                </Row>
                                            </Link>
                                            <Link className="text-decoration-none text-dark ">
                                                <Row className={"dropdownitems border-bottom text-center p-0 m-0"}>
                                                    <Col className="pb-2 pt-1 ps-0 pe-0">
                                                        Case

                                                    </Col>
                                                </Row>
                                            </Link>
                                            <Link className="text-decoration-none text-dark ">

                                                <Row className={"dropdownitems border-bottom text-center p-0 m-0"}>
                                                    <Col className="pb-2 pt-1 ps-0 pe-0">
                                                        Document
                                                    </Col>
                                                </Row>
                                            </Link>
                                            <Link className="text-decoration-none text-dark"
                                            >
                                                <Row className="dropdownitems text-center m-0">
                                                    <Col className="pb-2 pt-1 ps-0 pe-0">
                                                        Product
                                                    </Col>
                                                </Row>
                                            </Link>
                                        </Container>

                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>
                    </li>*/}

                    {/* dropdown 2 */}

                    <li className="nav-item me-sm-3 me-0 ">
                        <Dropdown style={{width: "10%"}}>
                            <Avatar id="nav_icon" sx={{bgcolor: "#e3e4e6", width: "30px", height: "30px"}}
                                    className="m-1">
                                <Dropdown.Toggle
                                    variant="secondery" style={{color: "white", marginRight: "32px"}}>
                                    <BsPlusCircle size={20} color="black" id="fabell"
                                                  style={{position: "relative", left: "16px"}}/>
                                </Dropdown.Toggle>
                            </Avatar>
                            <div className="">
                                <Dropdown.Menu id="header-dropdown-menu2">
                                    <Dropdown.Item className="">
                                        <Container className="p-0">
                                            <Row onClick={handleclick1}
                                                 className=" dropdownitems border-bottom text-center  p-0 m-0">
                                                <Col className="pb-2 pt-1 ps-0 me-0">
                                                    New Request
                                                </Col>
                                            </Row>
                                            {/*<Row onClick={handleclick2} className="dropdownitems text-center p-0 m-0">
                                                <Col className="pb-2 pt-1 ps-0 me-0">
                                                    New Group
                                                </Col>
                                            </Row>*/}
                                        </Container>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>

                    </li>

                    {/* <li className="nav-item me-sm-3 me-0 ">
                        <Avatar id="nav_icon" sx={{ bgcolor: "#e3e4e6", width: "30px", height: "30px" }} className="m-1">< BsPersonCircle color="black" size={20} /></Avatar>
                    </li> */}


                    <li className="nav-item me-sm-3 me-0 ">
                        {/* <Avatar id="nav_icon" sx={{ bgcolor: "#e3e4e6", width: "30px", height: "30px" }} className="m-1">< BsPersonCircle color="black" size={20} /></Avatar> */}


                        <Dropdown>
                            <Avatar id="nav_icon" sx={{bgcolor: "#e3e4e6", width: "30px", height: "30px"}}
                                    className="m-1">
                                <Dropdown.Toggle
                                    variant="secondery" style={{color: "white", marginRight: "32px"}}>
                                    <BsPersonCircle size={20} color="black" id="fabell"
                                                    style={{position: "relative", left: "16px"}}/>
                                </Dropdown.Toggle>
                            </Avatar>
                            <div className="">
                                <Dropdown.Menu id="header-dropdown-menu4">
                                    <Dropdown.Item className="">

                                        <div>
                                            <Container fluid className="p-0">
                                                <Link className="text-decoration-none text-dark">
                                                    <Row onClick={removesesiondata}
                                                         className="dropdownitems m-0 border-bottom text-center pb-1 pt-1">
                                                        <Col>
                                                            Logout
                                                        </Col>
                                                    </Row>

                                                </Link>
                                            </Container>

                                        </div>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>
                    </li>
                    {/* <li className="nav-item me-sm-3 me-0 ">

                        <Dropdown >
                            <Avatar id="nav_icon" sx={{ bgcolor: "#e3e4e6", width: "30px", height: "30px" }} className="m-1">
                                <Dropdown.Toggle
                                    variant="secondery" style={{ color: "white", marginRight: "32px" }} >
                                    <BsThreeDots size={20} color="black" id="fabell" style={{ position: "relative", left: "17px" }} />
                                </Dropdown.Toggle>
                            </Avatar>
                            <div className="" >
                                <Dropdown.Menu id="header-dropdown-menu3" >
                                    <Dropdown.Item className="" >

                                        <div id="headerdropdown">
                                            <Container fluid className="p-0" >
                                                <Link className="text-decoration-none text-dark"  >
                                                    <Row className="dropdownitems m-0 border-bottom text-center pb-1 pt-1">
                                                        <Col>
                                                            Report
                                                        </Col>
                                                    </Row>

                                                </Link>
                                            </Container>

                                        </div>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>


                    </li>*/}
                </ul>
            </div>
        </>
    )
}
export default HeaderIcons;