const checkArray = (array, key) => {
    const isFound = array.find(element => {
        if (element === key) {
            return element;
        } else if (element?.lable === key) {
            return element;
        }else if (element?.value === key) {
            return element;
        }
        return false;
    });
    return isFound;
}

const getMaritalStatusInput = (key = null) => {
    // LESS THAN 12 YEARS OLD,NEVER MARRIED,MARRIED,DIVORCED,WIDOWED
    // console.error(key);
    const data = [
        { lable: 'LESS THAN 12 YEARS OLD', value: 'LESS THAN 12 YEARS OLD', id: 1 },
        { lable: 'NEVER MARRIED', value: 'NEVER MARRIED', id: 2 },
        { lable: 'MARRIED', value: 'MARRIED', id: 3 },
        { lable: 'DIVORCED', value: 'DIVORCED', id: 4 },
        { lable: 'WIDOWED', value: 'WIDOWED', id: 5 }
    ]
    // if (key) {
    //     // console.error(checkArray(data, key));
    //     return checkArray(data, key)||"";
    // } else {
    //     return data;
    // }
    return data;
}
const getCountryInput = () => {
    // country
    return [
        { lable: 'BAHRAIN', id: 1, value: "BHR" },
        { lable: 'SAUDI ARABIA', id: 2, value: "SAU" },
        { lable: 'UAE', id: 3, value: "UAE" },
        { lable: 'KUWAIT', id: 4, value: "KWT" },
        { lable: 'OMAN', id: 5, value: "OMN" },
        { lable: 'QATAR', id: 6, value: "QAT" },
    ]
}

const getBooleanInput = () => {
    // YES/NO
    return [
        { lable: 'Yes', id: 1, value: 'Y' },
        { lable: 'No', id: 2, value: 'N' },
    ]
}
const getAddressBuildingTypeInput = () => {
    // YES/NO
    return [
        { lable: 'VILLA', value: 'VILLA', id: 1 },
        { lable: 'BUILDING', value: 'BUILDING', id: 2 },
        { lable: 'OFFICE', value: 'OFFICE', id: 3 },
        { lable: 'HOUSE', value: 'HOUSE', id: 4 },
        { lable: 'Rental House', value: 'Rental House', id: 5 },
        { lable: 'Other', value: 'Other', id: 6 },
    ]
}
const getSalaryInput = () => {
    // < BD 300,BD 300 – BD 1000,BD 1001 – BD 2500,BD 2501 – BD 5000,> BD 5000
    return [
        { lable: '< BD 300', value: '< BD 300', id: 1 },
        { lable: 'BD 300 – BD 1000', value: 'BD 300 – BD 1000', id: 2 },
        { lable: 'BD 1001 – BD 2500', value: 'BD 1001 – BD 2500', id: 3 },
        { lable: 'BD 2501 – BD 5000', value: 'BD 2501 – BD 5000', id: 4 },
        { lable: '> BD 5000', value: '> BD 5000', id: 5 }
    ]
}
const getDocumentTypesInput = () => {
    // < BD 300,BD 300 – BD 1000,BD 1001 – BD 2500,BD 2501 – BD 5000,> BD 5000
    return [
        { label: 'FATCA & CRS Document', value: 'FATCA & CRS Document', id: 1 },
        { label: 'Signed eKYC Customer Consent', value: 'Signed eKYC Customer Consent', id: 2 },
        { label: 'Birth Certificate', value: 'Birth Certificate', id: 3 },
        { label: 'Driving License', value: 'Driving License', id: 4 },
        { label: 'Passport', value: 'Passport', id: 5 },
        { label: 'Bank Statement', value: 'Bank Statement', id: 6 },
        { label: 'Customer License', value: 'Customer License', id: 7 },
        { label: 'Electricity bill', value: 'Electricity bill', id: 8 },
        { label: 'House lease agreement', value: 'House lease agreement', id: 9 },
        { label: 'Record of any home visit by bank official', value: 'Record of any home visit by bank official', id: 10 },
        { label: 'Telephone bill', value: 'Telephone bill', id: 11 },
        { label: 'Employers letters', value: 'Employers letters', id: 12 },
        { label: 'CPR/SMART CARD', value: 'CPR/SMART CARD', id: 13 },
        { label: 'Customer Signature', value: 'Customer Signature', id: 14 },
        { label: 'Customer Photo', value: 'Customer Photo', id: 15 },
    ]
}

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px var(--body-bg-color) inset" };

export { getMaritalStatusInput, getCountryInput, getBooleanInput, getAddressBuildingTypeInput, getSalaryInput, getDocumentTypesInput, inputStyle };