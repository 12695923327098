import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { BiChat, BiPlayCircle, BiStar } from "react-icons/bi";
import { Link } from "react-router-dom";
import logo from "../images/Fwd_/bank.jpg"
import { Avatar, Box, Paper, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
// import "../globle.css"




const NewRequestSidebar = ({ newRequestStep, showlogo }) => {



  return (
    <>
      <Container fluid className="" >
        <Row className={showlogo ? 'd-none' : 'd-none d-lg-block'} style={{ backgroundColor: "var(--primary)", height: "50px" }}>
          <div >
            <img className="img-fluid w-50 ps-2 pb-2 pt-2" src={logo} alt="kyc-logo" />

          </div>
        </Row>

        <Row className="" >

          <Row className="" >
            <div className="text-light fw-bold ms-2" style={{ borderBottom: "2px solid black" }}>New Request </div>
            <Box sx={{ maxWidth: 400 }}>
              <Stepper activeStep={newRequestStep} orientation="vertical">

                <Step >
                  <StepLabel
                  >
                    <div className="text-light" style={{ fontSize: "12px" }}>Enter Entity Details</div>
                  </StepLabel>
                  <StepContent>
                    <Typography>

                    </Typography>
                    <Box >
                      <div>


                      </div>
                    </Box>
                  </StepContent>
                </Step>
                <Step >
                  <StepLabel

                  >
                    <div className="text-light" style={{ fontSize: "12px" }}>Search For Duplicates</div>                                                                </StepLabel>
                  <StepContent>
                    {/* <Typography>step.description</Typography> */}
                    <Box >
                      <div>


                      </div>
                    </Box>
                  </StepContent>
                </Step>

                <Step >
                  <StepLabel>
                    <div className="text-light" style={{ fontSize: "12px" }}>Complete</div>
                  </StepLabel>
                  <StepContent>
                    {/* <Typography>step.description</Typography> */}
                    <Box >
                      <div>

                      </div>
                    </Box>
                  </StepContent>
                </Step>

              </Stepper>
            </Box>
          </Row>

        </Row>
      </Container>

    </>
  )
}
export default NewRequestSidebar;