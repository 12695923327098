import { Alert, Autocomplete, Box, CircularProgress, Snackbar, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall, LogTime } from "../../Api/UseFeitch";
import { select } from "@nextui-org/react";
// import "../globle.css"

const ConformIDVMethod = ({ data, setData, setShowmenu }) => {
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [confirm_idv_method, setConfirm_idv_method] = useState([])
    const [btnNameSubmit, setBtnNameSubmit] = useState("SAVE AND COMPLETE");

    const [bodyData, setBodyData] = useState({
        device_type: "android",
        ajtoken: "AJABCDEFGH",
        username: "8888888888",
        device_token: "",

    });
    const [mandatorydata, setMandatory] = useState({

    })



    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value })
        setMandatory({ ...mandatorydata, [name]: value })
        // console.log("-------------", mandatorydata.pde_duration)

    }
    const handleselect = (event, confirm_idv_method) => {
        setConfirm_idv_method(confirm_idv_method);
    }


    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)

    }
    function alertFunc() {
        navigate("/panel2/2" + "?token=1&step=0&substep=1");
        setShowmenu(1)

    }

    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);

    let logintime = LogTime();

    // console.log("logintime", logintime);
    useEffect(() => {
        if (logintime == false) {
            navigate("/");
            sessionStorage.removeItem("tokenSessionStorage");
        }
    }, []);



    async function handleclick(e) {

        e.preventDefault();
        setBtnNameSubmit(<Spinner animation="border" variant="danger" style={{ height: "22px", width: "22px" }} />);

        let resultt = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "NonIGA",
                    nonIGA: {
                        "passport": "31348675222",
                        "gender": "M",
                        "DOB": "10/10/2020",
                        "nationality": "PAK",
                        ...bodyData, confirmIdAndvMethod: confirm_idv_method
                    }
                },
                consentMode: ""
            }
        })

        // console.log("resultt-----------", resultt)
        if (resultt.messageStatus == "OK") {
            setData(resultt);
            setOpen2(true);
            setMessage(resultt.errorDescription)
            timeout = setTimeout(alertFunc, 2000);
            setBtnNameSubmit("SAVE AND COMPLETE");

        } else {
            setOpen(true);
            setMessage(resultt.errorDescription)
            setBtnNameSubmit("SAVE AND COMPLETE");

        }


        // navigate("/panel2/2" + "?token=1&step=0&substep=1");
        // setShowmenu(1)
    }





    const sempleoption = [
        { lable: 'Option 1', year: 1994 },
        { lable: 'Option 2', year: 1972 },
        { lable: 'Option 3', year: 1974 },
        { lable: 'Option 4', year: 2008 }
    ];
    const selectstyle = {
        backgroundColor: 'white',
        borderRadius: '0px'
    };


    const fieldsLists = [
        {
            title: <p className="fw-bold text-danger">Please Select The Method In Which You to <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box >
                <Autocomplete
                    name="confirmIdAndvMethod"
                    onChange={handleselect}
                    style={selectstyle}
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={sempleoption}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField size="small" required {...params} placeholder="Select" />
                    )}
                />
            </Box>,
        }, {
            title: <p className="fw-bold">Undertake Identification And Verification</p>,
            value: ""
            ,
        }


    ];



    return (
        <>
            <Container fluid id="scroleble_main_content">



                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>



                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open2} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-success text-light" severity="success" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>




                <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <Row className="border-bottom border-dark">
                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                <Box className="h3 mt-4" as="div" flex='1' textAlign='left'>
                                    Confirm ID&V Method
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </Row>
                        <AccordionPanel pb={4}>
                            <Accordion className="mt-3" defaultIndex={[0]} allowMultiple>
                                <AccordionItem>
                                    <Row className="border-bottom border-dark">
                                        <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                            <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                                Confirm ID&V Method
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </Row>
                                    <AccordionPanel pb={4}>

                                        {
                                            fieldsLists.map((field) =>
                                                <Row className="mt-3">
                                                    <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                                    <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                                        {field?.value}
                                                    </Col>
                                                    <Col xs={0} sm={2} xl={3}></Col>
                                                </Row>
                                            )
                                        }

                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                            <Row className=" mt-5  mb-5">
                                <Col md={4} lg={5} xl={6} xs={0} className=""></Col>
                                <Col md={8} lg={7} xl={6} xs={12} className=" ">
                                    <Container>
                                        <Row>
                                            <Col md={1}></Col>
                                            <Col md={5} sm={6} className=" text-end">
                                                <Button className="  ps-4 pe-4 btn-secondary buttonstyle ">SAVE FOR LATER</Button>
                                            </Col>
                                            <Col md={6} sm={6} className=" text-end mt-sm-0 mt-2"><Button
                                                onClick={handleclick}
                                                className="ps-4 pe-4 btn-secondary buttonstyle">
                                                {btnNameSubmit}
                                            </Button></Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>










            </Container>

        </>
    )
}
export default ConformIDVMethod;