import React from "react";
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap"
import { Alert, Autocomplete, Box, Snackbar, TextField } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall, ApicallUpload, LogTime, getCountryCodeByGccId, sessionLogout } from "../../Api/UseFeitch";
import { useState } from "react";
import { useEffect } from "react";
import img from "../images/mainlogo.png"
import { getDocumentTypesInput } from "../helpingFunction/helpingInputData";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { color } from "framer-motion";



const Documents = ({ errortype, filelesscheck, nextDocument, setNextDocument, setShow, show, setIconchange, iconchange, setFilelessHideDocument, filelessHideDocument, setNextIcon, nextIcon, setData, documentContent, setDocumentContent }) => {
    const [message, setMessage] = useState();
    const [btnNameSubmit, setBtnNameSubmit] = useState("SAVE AND NEXT");
    const [fileresponsedata, setFileresponsedata] = useState({});

    const navigate = useNavigate();
    const [document_sourse, setDocument_sourse] = useState("")
    const [document_direction, setDocument_direction] = useState("")
    const [document_category, setDocument_category] = useState("")
    const [document_type, setDocument_type] = useState("")
    const [cardCountry, setCardCountry] = useState("BHR");
    const [searchParams] = useSearchParams();
    const [gccId, setGccId] = useState(searchParams.get("gccid") || "");
    const [getCurrentData, setGetCurrentData] = useState({});


    async function fetchMyAPI(gccIdTemp) {
        let cardCountryTemp = await getCountryCodeByGccId(gccIdTemp);

        // console.error(cardCountryTemp);
        // console.error(gccIdTemp);
        if (!cardCountryTemp) {
            // errorMessage("GccId/nationalId is not valid");
            return;
        }
        setCardCountry(cardCountryTemp);
        setGccId(gccIdTemp);
        let getdata = await Apicall("API/EKYC/getCurrentKYCData", {
            body: {
                Id: {
                    identityType: "GCC",
                    GCC: {
                        "cardCountry": cardCountryTemp || "BHR",
                        "nationalID": gccIdTemp || "720033225",
                    }
                },
            },
        })
        setGetCurrentData(getdata);
    }

    useEffect((e) => {
        if (searchParams.get("gccid")) fetchMyAPI(searchParams.get("gccid"))
        if (searchParams.get("gccId")) fetchMyAPI(searchParams.get("gccId"))
    }, [searchParams.get("gccid"), searchParams.get("gccId")])

    const [bodyData, setBodyData] = useState({
        documentSource: getCurrentData?.document?.documentSource || "",
        documentDirection: getCurrentData?.document?.documentDirection || "",
        fileLessDocument: getCurrentData?.document?.fileLessDocument || "",
        documentName: getCurrentData?.document?.documentName || "",
        documentCategory: getCurrentData?.document?.documentCategory || "",
        documentType: getCurrentData?.document?.documentType || "",
        current: getCurrentData?.document?.current || "",
        legible: getCurrentData?.document?.legible || "",
        signedSndInFull: getCurrentData?.document?.signedSndInFull || "",
        annotatedOtherThenEnglish: getCurrentData?.document?.annotatedOtherThenEnglish || "",
        effectiveDate: getCurrentData?.document?.effectiveDate || "",
        expirationDate: getCurrentData?.document?.expirationDate || "",
        documentIdentificationNumber: getCurrentData?.document?.documentIdentificationNumber || "",
        issuerOfDocument: getCurrentData?.document?.issuerOfDocument || "",
        dateOfIssue: getCurrentData?.document?.dateOfIssue || "",
        placeOfIssue: getCurrentData?.document?.placeOfIssue || "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value })
    }
    const Documentsourse = (event, document_sourse) => {

        setBodyData({ ...bodyData, documentSource: document_sourse.label });
    }
    const handleChangeDocumentDirection = (event, document_direction) => {
        setBodyData({ ...bodyData, documentDirection: document_direction.label });

    }
    const handleChangeDocumentCategory = (event, document_category) => {
        setBodyData({ ...bodyData, documentCategory: document_category.label });

    }

    const handleChangeDocumentType = (event, document_type) => {
        setBodyData({ ...bodyData, documentType: document_type.label });

    }

    const Current = (e) => {
        let isChecked = e.target.checked;
        // do whatever you want with isChecked value
        if (isChecked) {
            setBodyData({ ...bodyData, current: "Y" });
        } else {
            setBodyData({ ...bodyData, current: "" });
        }
    }

    const Signed = (e) => {
        let isChecked = e.target.checked;
        // do whatever you want with isChecked value
        if (isChecked) {
            setBodyData({ ...bodyData, signedSndInFull: "Y" });
        } else {
            setBodyData({ ...bodyData, signedSndInFull: "" });
        }
    }
    const Legible = (e) => {
        let isChecked = e.target.checked;
        // do whatever you want with isChecked value
        if (isChecked) {
            setBodyData({ ...bodyData, legible: "Y" });
        } else {
            setBodyData({ ...bodyData, legible: "" });
        }
    }
    const AnnotatenIfaLanguale = (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            setBodyData({ ...bodyData, annotatedOtherThenEnglish: "Y" });
        } else {
            setBodyData({ ...bodyData, annotatedOtherThenEnglish: "" });
        }
    }
    const [fileInput, setFileInput] = useState();
    const handleChangeFile = async (e) => {
        setFileInput(e.target.files[0]);
        // console.log("imagggggg", e.target.files[0]);
        // let formData = new FormData();
        // // form.file = e.target.files[0];
        // formData.append("file", e.target.files[0])
        // // form.ploicies = bodyData?.policy;
        // const filedata = await ApicallUpload("API/core/upload?type=FILE", formData).then((response) => {
        //     // console.log("filedata-------------------->", response);
        //     setFileresponsedata(response.data)
        //     return response.data;
        // }).catch((e) => {
        //     return e.response.data;
        // });
        // // console.log(filedata);
    }

    const FileLessDocument = (e) => {
        setFilelessHideDocument(!filelessHideDocument);

        let isChecked = e.target.checked;
        setFileInput('');
        if (isChecked) {
            setBodyData({ ...bodyData, fileLessDocument: "Y" });

        } else {
            setBodyData({ ...bodyData, fileLessDocument: "" });
        }
    }
    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)
    }

    function alertFunc() {
        setIconchange(!iconchange);
        setNextDocument(!nextDocument);
        setNextIcon(!nextIcon);
        setShow(!show);
    }




    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
        let loginTime = LogTime();
        if (loginTime === false) {
            navigate("/");
            sessionLogout();
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);

    async function handleSubmitFile(e) {
        e.preventDefault();
        setBtnNameSubmit(<Spinner animation="border" className="btn-pointer-color" style={{ height: "22px", width: "22px" }} />);
        let formData = new FormData();

        // if (bodyData.fileLessDocument == 'Y') {
        //     formData.append("file", '');
        // } else {
        //     formData.append("file", fileInput);

        // }

        formData.append("file", fileInput);


        const filedata = await ApicallUpload("API/core/upload?type=FILE", formData).then((response) => {
            // console.log("filedataaaa-------------------->", response);
            // setFileresponsedata(response)
            return response;
        }).catch((e) => {
            return e.response.data;
        });

        // console.log("return result----", filedata);

        if (filedata?.messageStatus === "OK") {

            setOpen2(true);
            setMessage(filedata?.errorDescription || "File uploaded");
            timeout = setTimeout(alertFunc, 1000);
            setBtnNameSubmit("SAVE AND COMPLETE");
        } else {
            if (errortype) {
                setOpen(true);
                setIconchange(true);
                setNextDocument(false);
                setNextIcon(false);
                setShow(true);
            } else {
                setIconchange(!iconchange);
                setNextDocument(!nextDocument);
                setNextIcon(!nextIcon);
                setShow(!show);
            }

            setMessage(filedata?.errorDescription || "Some error encounter during file upload. Please try again");
            setBtnNameSubmit("SAVE AND COMPLETE");
        }

        // const document = {
        //     "documentSource": document_sourse||"",
        //     "documentDirection": document_direction||"",
        //     "fileLessDocument": bodyData?.fileLessDocument||"",
        //     "documentName": bodyData?.documentName||"",
        //     "documentCategory": document_category||"",
        //     "documentType": document_type||"",
        //     "current": bodyData?.current||"",
        //     "signedSndInFull": bodyData?.signedSndInFull||"",
        //     "legible": bodyData?.legible||"",
        //     "annotatedOtherThenEnglish": bodyData?.annotatedOtherThenEnglish||"",
        //     "effectiveDate": bodyData?.effectiveDate||"",
        //     "expirationDate": bodyData?.expirationDate||"",
        //     "documentIdentificationNumber": bodyData?.documentIdentificationNumber||"",
        //     "issuerOfDocument": bodyData?.issuerOfDocument||"",
        //     "dateOfIssue": bodyData?.dateOfIssue||"",
        //     "placeOfIssue": bodyData?.placeOfIssue||"",
        //     "hash": filedata?.hash||"",
        //     "name": filedata?.name||"",
        //     "path": filedata?.path||"",
        //     "type": filedata?.type||""
        // };
        const documentTemp = {};
        if (bodyData?.documentSource) {
            documentTemp.documentSource = bodyData?.documentSource;
        }
        if (bodyData?.documentType) {
            documentTemp.documentType = bodyData?.documentType;
        }
        if (bodyData?.documentDirection) {
            documentTemp.documentDirection = bodyData?.documentDirection;
        }
        if (bodyData?.documentCategory) {
            documentTemp.documentCategory = bodyData?.documentCategory;
        }

        if (bodyData?.documentName) {
            documentTemp.documentName = bodyData?.documentName;
        }
        if (bodyData?.current) {
            documentTemp.current = bodyData?.current;
        }
        if (bodyData?.signedSndInFull) {
            documentTemp.signedSndInFull = bodyData?.signedSndInFull;
        }
        if (bodyData?.legible) {
            documentTemp.legible = bodyData?.legible;
        }
        if (bodyData?.annotatedOtherThenEnglish) {
            documentTemp.annotatedOtherThenEnglish = bodyData?.annotatedOtherThenEnglish;
        }
        if (bodyData?.effectiveDate) {
          //  documentTemp.effectiveDate = bodyData?.effectiveDate;
        }
        if (bodyData?.expirationDate) {
          //  documentTemp.expirationDate = bodyData?.expirationDate;
        }
        if (bodyData?.documentIdentificationNumber) {
            documentTemp.documentIdentificationNumber = bodyData?.documentIdentificationNumber;
        }
        if (bodyData?.issuerOfDocument) {
            documentTemp.issuerOfDocument = bodyData?.issuerOfDocument;
        }
        if (bodyData?.placeOfIssue) {
            documentTemp.placeOfIssue = bodyData?.placeOfIssue;
        }
        if (bodyData?.dateOfIssue) {
            //documentTemp.dateOfIssue = bodyData?.dateOfIssue;
        }
        if (filedata?.hash) {
            documentTemp.hash = filedata?.hash;
        }
        if (filedata?.name) {
            documentTemp.name = filedata?.name;
        }
        if (filedata?.path) {
            documentTemp.path = filedata?.path;
        }
        if (filedata?.type) {
            documentTemp.type = filedata?.type;
        }
        // setIconchange(!iconchange);
        // setNextDocument(!nextDocument);
        // setNextIcon(!nextIcon);

        if (Object.keys(documentTemp).length === 0) {
            // console.log('Object is empty');
            return;
        }
        if (bodyData?.fileLessDocument) {
            documentTemp.fileLessDocument = bodyData?.fileLessDocument;
        }
        setDocumentContent({ ...documentTemp });
    }


    const DocumentSourse = [
        { label: 'Email', id: 1 },
        { label: 'Existing Document', id: 2 },
        { label: 'Uploaded Document', id: 3 },
    ];
    const optionsDocumentDirection = [
        { label: 'Inbound', id: 1 },
        { label: 'Outbound', id: 2 },
    ];
    const optionsDocumentCategories = [
        { label: 'Client onboarding', id: 1 },
    ];
    const optionDocumentTypes = getDocumentTypesInput();



    const fieldsLists2 = [
        {
            title: "Document Sourse  ",
            value: <Box>
                <Autocomplete
                    name="documentSource"
                    onChange={Documentsourse}
                    size="small"
                    id="tags-documentSource"
                    disableClearable={true}
                    options={DocumentSourse}
                    getOptionLabel={(option) => option.label}
                    // defaultValue={bodyData?.documentSource}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Document Sourse" />
                    )}
                />
            </Box>,
        },
        {
            title: "Document Direction",
            value: <Box>
                <Autocomplete
                    onChange={handleChangeDocumentDirection}
                    name="documentDirection"
                    disableClearable={true}
                    size="small"
                    id="tags-documentDirection"
                    options={optionsDocumentDirection}
                    getOptionLabel={(option) => option.label}
                    // defaultValue={bodyData?.documentDirection}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Document Direction" />
                    )}
                />
            </Box>,
        },
        {
            title: <p className={filelesscheck ? 'd-none' : 'd-block'}>Fileless Documents</p>,
            value: <Box className={filelesscheck ? 'd-none' : 'd-block'}>
                <Form.Check type="checkbox" name="fileLessDocument" onChange={e => FileLessDocument(e)}
                    value={bodyData.fileLessDocument} id="checkbox" />
            </Box>,
        },
        {
            title: <Col className={filelessHideDocument ? 'd-none' : 'd-block'}>File Location</Col>,
            value: <Box>
                <input className={filelessHideDocument ? 'd-none' : 'd-block'} type="file" name="file" onChange={handleChangeFile} />
            </Box>,
        },
        {
            title: "Document Name ",
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Document Name"
                    variant="outlined"
                    onChange={handleChange}
                    name="documentName"
                    value={bodyData?.documentName}
                    type="text" />
            </Box>,
        }, {
            title: "Document category ",
            value: <Box>
                <Autocomplete
                    onChange={handleChangeDocumentCategory}
                    name="documentCategory"
                    disableClearable={true}
                    size="small"
                    id="tags-documentCategory"
                    options={optionsDocumentCategories}
                    getOptionLabel={(option) => option.label}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Document category" />
                    )}
                />
            </Box>,
        },
        {
            title: "Document Type ",
            value: <Box>
                <Autocomplete
                    onChange={handleChangeDocumentType}
                    name="documentType"
                    size="small"
                    disableClearable={true}
                    id="tags-documentCategory"
                    options={optionDocumentTypes}
                    getOptionLabel={(option) => option.label}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Document Type" />

                    )}
                />
            </Box>,
        }
    ];

    const fieldsLists3 = [
        {
            title: <p>Current <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <Form.Check type="checkbox" name="current" onChange={e => Current(e)}
                    id="checkbox" />
            </Box>,
        },
        {
            title: <p>Signed And In Full (If Applicable) <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <Form.Check type="checkbox" name="signedSndInFull" onChange={e => Signed(e)}
                    id="checkbox" />
            </Box>,
        },
        {
            title: <p>Legible <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <Form.Check type="checkbox" name="legible" onChange={e => Legible(e)}
                    id="checkbox" />
            </Box>,
        },
        {
            title: <p>Annotaten If a Languale Other Than English <span className=" pt-1 fw-bold text-danger">*</span>
            </p>,
            value: <Box>
                <Form.Check type="checkbox" name="annotatedOtherThenEnglish" onChange={e => AnnotatenIfaLanguale(e)}
                    id="checkbox" />
            </Box>,
        },
        {
            title: <p>Effective Date <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    // label="Document Name"
                    variant="outlined"

                    name="effectiveDate"
                    onChange={handleChange}
                    value={bodyData?.effectiveDate}
                    type="date" />
            </Box>,
        },
        {
            title: <p>Expiration Date <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    // label="Document Name"
                    variant="outlined"

                    name="expirationDate" onChange={handleChange}
                    value={bodyData?.expirationDate}
                    type="date" />
            </Box>,
        },
        {
            title: <p>Document Identification Number <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Document Identification Number"
                    variant="outlined"

                    name="documentIdentificationNumber"
                    onChange={handleChange}
                    value={bodyData?.documentIdentificationNumber}
                    type="text" />
            </Box>,
        },
        {
            title: <p>Issuer Of The Document <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>


                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Issuer Of The Document"
                    variant="outlined"

                    name="issuerOfDocument" onChange={handleChange}
                    value={bodyData?.issuerOfDocument}
                    type="text" />
            </Box>,
        },
        {
            title: <p>Date Of Issuance <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    variant="outlined"

                    name="dateOfIssue" onChange={handleChange}
                    value={bodyData?.dateOfIssue}
                    type="date" />
            </Box>,
        },
        {
            title: <p>Place Of Issuance <span className=" pt-1 fw-bold text-danger">*</span></p>,
            value: <Box>
                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Place Of Issuance"
                    variant="outlined"
                    name="placeOfIssue" onChange={handleChange}
                    value={bodyData?.placeOfIssue}
                    type="text" />
            </Box>,
        }
    ];

    return (
        <>
            <Container  >
                <Snackbar sx={{ width: '82%', marginTop: "25px" }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000}
                    onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-danger text-light" severity="error"
                        sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>

                <Snackbar sx={{ width: '82%', marginTop: "25px" }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open2} autoHideDuration={6000}
                    onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-success text-light" severity="success"
                        sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>

                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <Row className="border-bottom border-dark">
                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                    Add A Documents
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </Row>
                        <AccordionPanel pb={4}>

                            {
                                fieldsLists2.map((field) =>
                                    <Row className="mt-3">
                                        <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                            {field?.value}
                                        </Col>
                                        <Col xs={0} sm={2} xl={3}></Col>
                                    </Row>
                                )
                            }
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <Row className="border-bottom border-dark">
                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                    Document Criteria
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </Row>
                        <AccordionPanel pb={4}>
                            {
                                fieldsLists3.map((field) =>
                                    <Row className="mt-3">
                                        <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                            {field?.value}
                                        </Col>
                                        <Col xs={0} sm={2} xl={3}></Col>
                                    </Row>
                                )
                            }

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>


                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <Row className="border-bottom border-dark">
                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                    Jurisdiction
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </Row>
                        <AccordionPanel pb={4}>
                            <Row className="mt-3 ">
                                <Col md={4} className="text-end fw-bold">Jurisdiction</Col>
                                <Col sm={8} xs={6} className="fw-bold "> Rafidain Bank</Col>
                            </Row>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                <Row className="mt-5 pt-3">


                    <Col>
                        <Container fluid>
                            <Row>

                                <Col xl={4} lg={5} xs={10} className="mx-auto mt-lg-0 {/*mt-3*/} text-center ">
                                    {/*type="submit"*/}
                                    <Button className=" ms-md-5 ms-0 my-2 btn-secondary buttonstyle" onClick={handleSubmitFile}>
                                        {btnNameSubmit}
                                    </Button>
                                </Col>

                            </Row>
                        </Container>
                    </Col>
                </Row>

                {/* <Row>
                        <Col sm={2} className="bg-info">fdsfdfdf</Col>
                        <Col sm={2}></Col>
                        <Col sm={3} className="bg-warning"><Button className=" me-auto  btn-secondary buttonstyle">SAVE AND ADD ANOTHER</Button></Col>
                        <Col sm={3} className="bg-danger text-end"><Button className=" me-auto  btn-secondary buttonstyle">SAVE AND ADD ANOTHER</Button></Col>
                        <Col sm={2} className="bg-success text-center"><Button className="  btn-secondary buttonstyle"> CANCEL</Button></Col>
                    </Row> */}

            </Container>
            
        </>
    )
}
export default Documents;