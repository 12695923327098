import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Alert, Autocomplete, Box, Snackbar, TextField } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { Apicall, getCountryCodeByGccId, LogTime, sessionLogout } from "../../Api/UseFeitch";
import { inputStyle } from "../helpingFunction/helpingInputData";


const Complete = ({ adminData, setAdminData, data, setData, newRequestStep, setNewRequestStep }) => {
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [btnNameSubmit, setBtnNameSubmit] = useState("Create Entry");
    const [searchParams] = useSearchParams();
    const [subStepCourse, setSubStepCourse] = useState(searchParams.get('step') || 0)
    const [caseid, setCaseid] = useState("");
    const [gccid, setGccid] = useState("");
    const [countryCodeDefault, SetCountryCodeDefault] = useState({ lable: 'BAHRAIN', id: 1, value: "BHR" });

    const updateCountryCode = async () => {
        if (adminData?.cardCountry) {
            if (adminData.cardCountry === "BHR") {
                // console.log("BHR");
                await SetCountryCodeDefault({ lable: 'BAHRAIN', id: 1, value: "BHR" });
            } else if (adminData.cardCountry === "SAU") {
                // console.log("SAU");
                await SetCountryCodeDefault({ lable: 'SAUDI ARABIA', id: 2, value: "SAU" });
            } else if (adminData.cardCountry === "UAE") {
                // console.log("UAE");
                await SetCountryCodeDefault({ lable: 'UAE', id: 3, value: "UAE" });
            } else if (adminData.cardCountry === "KWT") {
                // console.log("KWT");
                await SetCountryCodeDefault({ lable: 'KUWAIT', id: 4, value: "KWT" });
            } else if (adminData.cardCountry === "OMN") {
                // console.log("OMN");
                await SetCountryCodeDefault({ lable: 'OMAN', id: 5, value: "OMN" });
            } else if (adminData.cardCountry === "QAT") {
                // console.log("QAT");
                await SetCountryCodeDefault({ lable: 'QATAR', id: 6, value: "QAT" });
            }
        }
        // console.log("adminData?.cardCountry", adminData?.cardCountry);
    }

    useEffect(() => {
        // console.log("subStepCourse.................", subStepCourse)
        setNewRequestStep(subStepCourse || 0);
        updateCountryCode();
    }, [])

    const [bodyData, setBodyData] = useState({
        password: "",
        gccId2: adminData?.nationalID || "",
        check: "",
        gccId: adminData?.nationalID || "",
        nationalID: adminData?.nationalID || "",
        cardCountry: adminData?.cardCountry || "",
    });
    const [mandatorydata, setMandatory] = useState({
        EkeyCredentialsCountry: "",
        GCCIDCountry: ""
    });
    const [ekeyCredentialsCountry, setEkeyCredentialsCountry] = useState(adminData?.cardCountry || "");
    const [gCCIDCountry, setGCCIDCountry] = useState(adminData?.cardCountry || "");

    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value })
        // console.log("-------------", bodyData)
    }
    const SelectCountry1 = (event, country1) => {
        setEkeyCredentialsCountry(country1.value);
    }
    const SelectCountry2 = (event, country2) => {
        setGCCIDCountry(country2.value);
    }

    let timeout;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpen2(false)
    }
    const errorMessage = (message) => {
        setMessage(message || "An error encounter");
        setOpen(true);
        setBtnNameSubmit("Create Entry");
        setLoading(false);
    }
    const successMessage = (message) => {
        setMessage(message || "Ok Proceed");
        setOpen2(true);
    }
    useEffect(() => {
        if (!sessionStorage.getItem("tokenSessionStorage")) {
            navigate("/");
        }
        let loginTime = LogTime();
        if (loginTime === false) {
            navigate("/");
            sessionLogout();
        }
    }, [sessionStorage.getItem("tokenSessionStorage")]);

            // "body": {
            //     "Id": {
            //         "identityType": "GCC",
            //         "GCC": {
            //             "cardCountry": "BHR",
            //             "nationalID": bodyData.gccId2,
            //             "nationality": mandatorydata.EkeyCredentialsCountry,
            //             ...bodyData,
            //             "GCCID": {
            //                 nationality: mandatorydata.GCCIDCountry,
            //             },
            //         }
            //     },
            //     "consentMode": "BRANCH",
            //     "caseId": "ddddd",
            //     "IDandV": {
            //         "documents": [
            //             {
            //                 // "hash": "23482938493284",
            //                 // "name": "signed001.pdf"
            //             }
            //         ]
            //     }
            // }l̥
    async function handleclick(e) {
        e.preventDefault();
        setLoading(true);
        let cardCountry = await getCountryCodeByGccId(bodyData.gccId);
        if (!cardCountry) {
            errorMessage("GccId/nationalId is not valid");
            return;
        }

        mandatorydata.EkeyCredentialsCountry = ekeyCredentialsCountry;
        mandatorydata.GCCIDCountry = gCCIDCountry;

        setBtnNameSubmit(<Spinner animation="border" className="btn-pointer-color" style={{ height: "22px", width: "22px" }} />);
  
        // successMessage("Ok Proceed")
        // timeout = setTimeout(() => alertFunc("7565674843", bodyData.gccId2), 1000);

        let resultt = await Apicall("API/EKYC/recordConsentAndFetchSourceData", {
            "body": {
                "Id": {
                    "identityType": adminData?.type || "GCC",
                    "GCC": {
                        "cardCountry": cardCountry,
                        "nationalID": bodyData.gccId
                    }
                },
                "consentMode": "BRANCH",
                "caseId": ""
            }
        });
        // console.log("resultt-----------", resultt)
        setData(resultt)
        if (resultt.messageStatus === "OK") {
            successMessage(resultt?.errorDescription)
            timeout = setTimeout(() => alertFunc(resultt?.caseId, bodyData.gccId2), 1000);
        } else {
            if (resultt?.caseId) {
                await setCaseid(resultt.caseId);
                successMessage(resultt?.errorDescription)
                timeout = setTimeout(() => alertFunc(resultt?.caseId, bodyData.gccId2), 1000);
            } else {
                errorMessage(resultt.errorDescription);
                return;
            }

        }
    }
    function alertFunc(caseId, gccId) {
        // // console.log("gccId",gccId);
        setLoading(false);
        setBtnNameSubmit("Create Entry");
        navigate("/panel2" + "?token=1&step=0&substep=0&caseid=" + caseId + "&gccid=" + gccId);
    }
    const Country1 = [
        { lable: 'BAHRAIN', id: 1, value: "BHR" },
        { lable: 'SAUDI ARABIA', id: 2, value: "SAU" },
        { lable: 'UAE', id: 3, value: "UAE" },
        { lable: 'KUWAIT', id: 4, value: "KWT" },
        { lable: 'OMAN', id: 5, value: "OMN" },
        { lable: 'QATAR', id: 6, value: "QAT" },
    ];
    const Country2 = [
        { lable: 'BAHRAIN', id: 1, value: "BHR" },
        { lable: 'SAUDI ARABIA', id: 2, value: "SAU" },
        { lable: 'UAE', id: 3, value: "UAE" },
        { lable: 'KUWAIT', id: 4, value: "KWT" },
        { lable: 'OMAN', id: 5, value: "OMN" },
        { lable: 'QATAR', id: 6, value: "QAT" },
    ];
    const fieldsLists1 = [
        {
            title: " ",
            value: " I consent for eKYC plateform to reach out to IGA on my behalf",
        },
        {
            title: "Country",
            value: <Box>
                <Autocomplete
                    onChange={SelectCountry1}
                    defaultValue={countryCodeDefault}
                    name="EkeyCredentialsCountry"
                    // value={bodyData.country}
                    size="small"
                    id="EkeyCredentialsCountry"
                    disableClearable={true}
                    options={Country1}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField color="success" {...params} label="Country" />
                    )}
                />
            </Box>,
        },
        {
            title: "GCC ID  ",
            value: <Box>

                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="GCC ID"
                    variant="outlined"
                    inputProps={{ style: inputStyle }} 
                    onChange={handlechange}
                    name="gccId"
                    value={bodyData.gccId}
                    type="text" />
            </Box>,
        },
        {
            title: "Password",
            value: <Box>


                <TextField
                    fullWidth
                    className="input-field text-success"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    onChange={handlechange}
                    name="password"
                    value={bodyData.password}
                    type="text" />
            </Box>,
        },
        {
            title: "",
            value: <Container className="p-0">
                <Row>
                    <Col sm={7} className=" fw-bold"> BENEFIT does not accept any responsibility of the
                        content in the linked sites nor endorse any products or
                        organizations in connection to the linked sites.
                        The eKEY Guidelines are accessible at Bahrain.bh </Col>
                    <Col sm={5}></Col>
                </Row>
            </Container>,
        }


    ];
    const fieldsLists2 = [

        {
            title: "Country",
            value: <Box>
                <Autocomplete
                    defaultValue={countryCodeDefault}
                    onChange={SelectCountry2}
                    name="GCCIDCountry"
                    // value={bodyData.country2}
                    size="small"
                    disableClearable={true}
                    id="GCCIDCountry"
                    options={Country2}
                    getOptionLabel={(option) => option.lable}
                    filterSelectedOptions
                    renderInput={(params, index) => (
                        <TextField color="success" {...params} label="Country" />
                    )}
                />
            </Box>,
        },
        {
            title: "GCC ID  ",
            value: <Box>
                <TextField
                    fullWidth
                    className="input-field"
                    size="small"
                    color="success"
                    id="outlined-basic"
                    label="GCC ID "
                    variant="outlined"
                    inputProps={{ style: inputStyle }} 
                    onChange={handlechange}
                    name="gccId2"
                    value={bodyData.gccId2}
                    type="text" />
            </Box>,
        },
        {
            title: "",
            value: <Form.Check type="checkbox" name="check" onChange={handlechange} value={bodyData.check}
                id="checkbox" />,
        },
        {
            title: "",
            value: <Container className="p-0">
                <Row className="p-0">
                    <Col sm={7} className=" fw-bold">
                        I have captured customer consent as a signed document
                        which will be uploaded into the system.</Col>
                    <Col sm={5}></Col>
                </Row>
            </Container>,
        }


    ];
    const fieldsLists3 = [

        {
            title: "First Name",
            value: data?.nonIGAFields?.primary?.firstNameEn || "-",
        },
        {
            title: "Last Name  ",
            value:data?.nonIGAFields?.primary?.lastNameEn || "-",
        },
        {
            title: "Legal Entity Credentials",
            value: "Individual",
        },
        {
            title: "Legal Entity Role",
            value: "Cleint/Counterparty",
        },
        {
            title: "Originating Bank",
            value: "Rfidain Bank",
        }


    ];

    return (
        <>
            <Container fluid id="scroleble_main_content">


                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-danger text-light" severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>


                <Snackbar sx={{ width: '82%', marginTop: "25px" }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open2} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} className="bg-success text-light" severity="success"
                        sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
                <Row><Col className="h3 fw-light mt-4 ms-4">Complete</Col> </Row>

                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <Row className="border-bottom border-dark">
                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                    Ekey Credentials
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </Row>
                        <AccordionPanel pb={4}>


                            {
                                fieldsLists1.map((field) =>
                                    <Row className="mt-3">
                                        <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                            {field?.value}
                                        </Col>
                                        <Col xs={0} sm={2} xl={3}></Col>
                                    </Row>
                                )
                            }

                        </AccordionPanel>
                    </AccordionItem>

                </Accordion>

                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <Row className="border-bottom border-dark">
                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                    GCC ID
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </Row>
                        <AccordionPanel pb={4}>

                            {
                                fieldsLists2.map((field) =>
                                    <Row className="mt-3">
                                        <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                            {field?.value}
                                        </Col>
                                        <Col xs={0} sm={2} xl={3}></Col>
                                    </Row>
                                )
                            }
                        </AccordionPanel>
                    </AccordionItem>

                </Accordion>


                <Accordion className="mt-5" defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <Row className="border-bottom border-dark">
                            <AccordionButton style={{ border: "0px", backgroundColor: "rgb(214, 215, 216)" }}>
                                <Box className="fw-bold" as="div" flex='1' textAlign='left'>
                                    Non Ekey Credentials
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </Row>
                        <AccordionPanel pb={4}>

                            {
                                fieldsLists3.map((field) =>
                                    <Row className="mt-3">
                                        <Col sm={3} xs={12} className="text-md-end text-start">{field?.title} </Col>
                                        <Col sm={7} xl={5} xs={12} className="fw-bold ">
                                            {field?.value}
                                        </Col>
                                        <Col xs={0} sm={2} xl={3}></Col>
                                    </Row>
                                )
                            }


                        </AccordionPanel>
                    </AccordionItem>

                </Accordion>

                <Row className="mt-5 mb-5">
                    <Col className="text-end me-5">
                        <Button

                            onClick={handleclick}

                            className="ps-4 pe-4  btn-secondary buttonstyle">
                            {btnNameSubmit}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Complete;